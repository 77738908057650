.materials-moving-table {
  width: 100%;
  height: 100%;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .table-container {
    flex: 1;
    padding: 0 8px;
    margin-top: 16px;

    .table {
      .header {
        background-color: #fff;
        font-family: 'MediumLLWeb-Bold';
        text-transform: uppercase;
        font-size: 10px;
        line-height: 16px;
        color: #000;
      }

      .MuiTableCell-root {
        border-bottom: none;
        padding: 8px 16px;

        .title {
          display: flex;
        }
      }

      .name-section {
        display: flex;
        align-items: center;
        gap: 8px;

        .left {
          display: flex;
          align-items: center;
        }

        .right {
          display: flex;
          flex-direction: column;

          .name {
            max-width: 260px;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px; /* 160% */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .time {
            color: rgba(0, 0, 0, 0.6);
            font-size: 13px;
            font-weight: 400;
            line-height: 16px; /* 123.077% */
          }
        }
      }

      .row {
        cursor: pointer;

        &:hover {
          background-color: #f4f7f8;
        }

        &.disabled {
          opacity: 0.5;
          cursor: no-drop;
        }
      }
    }
  }

  .pagination {
    padding: 24px;

    .select-rows-per-page {
      .MuiSelect-root {
        padding: 8px 44px 8px 12px;
        font-size: 15px;
        line-height: 24px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dbe1e3;
      }

      .MuiSelect-icon {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .MuiPaginationItem-page {
      font-family: 'MediumLLWeb-Bold';
      font-size: 13px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.6);
      min-width: 24px;
      width: 24px;
      height: 24px;
      padding: 4px;
      margin: 0 6px;
      background-color: #f4f7f8;
      border: none;

      &.Mui-selected {
        background-color: #000;
        color: #fff;
      }

      .MuiPaginationItem-icon {
        font-weight: 700;
      }

      &:hover {
        color: #000;
        &.Mui-selected {
          color: #fff;
        }
      }
    }

    // .info {
    //   font-size: 15px;
    //   line-height: 24px;
    //   font-weight: 400;
    //   color: rgba(0, 0, 0, 0.6);
    // }

    .MuiPaginationItem-ellipsis {
      color: rgba(0, 0, 0, 0.6);
      font-family: 'MediumLLWeb-Bold';
    }
  }

  .no-data-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
}

.moving-table-row-tooltip {
  max-width: 335px !important;
  // font-size: 13px !important;
  // line-height: 16px !important; /* 123.077% */
}

.marginBottom16 {
  margin-bottom: 16px;
}
