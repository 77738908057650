.folder-form-items {
  padding: 24px;
  flex: 1;

  &+.folder-form-control-button {
    background-color: #000;
    color: #fff;
    font-family: 'F37Judge-MediumCondensed';
    font-size: 30px;
    line-height: 30px;
    padding: 25px 0;
    box-shadow: none;
    border-radius: 0;

    &:hover {
      background-color: #3e3f42;
    }

    &.Mui-disabled {
      box-shadow: 0px -1px 0px #000;
      color: #fff;
      background-color: #000;
      opacity: 0.4;
    }
  }
}

.folder-form-control {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 24px;

  &-label {
    font-size: 12px;
    line-height: 16px;
    color: #000 !important;
  }

  &-input {
    .MuiOutlinedInput-root {
      background-color: #fff;
      color: #000;

      .MuiOutlinedInput-notchedOutline {
        // border-color: transparent;
      }
    }

    input {
      color: #000;
      border-radius: 4px;
      font-size: 15px;
      line-height: 20px;
      font-family: 'MediumLLWeb-Regular';
      font-weight: normal;
      padding: 8px 12px !important;
      height: 24px;
      background-color: #fff;
    }

    .MuiFormHelperText-root {
      color: #ff7f7f;
      font-size: 10px;
      line-height: 12px;
      margin: 4px 0 0 0;
    }
  }

  &-button {
    left: 0;
    right: 0;
    height: 40px;
    font-size: 15px;
    margin: auto;
    background-color: rgba(31, 218, 139, 1);
    font-family: 'MediumLLWeb-Bold';
    color: #17171a;
    border-radius: 100px;
    width: 412px;

    &.Mui-disabled {
      box-shadow: 0px -1px 0px #000;
      color: rgba(0, 0, 0, 0.4);
      background-color: rgba(236, 238, 239, 1);
    }

    &.Mui-disabled.is-processing {
      background-color: rgba(31, 218, 139, 1);
    }
  }
}

.material-form {
  .buttons {
    margin: auto;
    left: 24px;
    right: 24px;
    height: 88px;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    .large-save-button {
      left: 0;
      right: 0;
      height: 40px;
      font-size: 15px;
      background-color: rgba(31, 218, 139, 1);
      font-family: 'MediumLLWeb-Bold';
      color: #17171a;
      border-radius: 100px;
      width: 100%;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.4);
        background-color: rgba(236, 238, 239, 1);
      }

      &.Mui-disabled.is-processing {
        background-color: rgba(31, 218, 139, 1);
      }
    }
  }
}