@-moz-keyframes spin {
    0% {
        -moz-transform: rotate(0);
    }

    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.icon-spinner {
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
    color: rgb(0, 0, 0);
    display: inline-block;
}