@import '../../variables.scss';

.todo-list-wrapper {
  .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .todo-item-with-radio {
    display: flex;
    align-items: center;
    gap: 14px;

    .MuiIconButton-root {
      &.Mui-checked {
        outline: none;
        outline-offset: unset;
        box-shadow: none;
      }

      .makeStyles-icon-23 {
        width: 20px !important;
        height: 20px !important;
        border: 2px solid rgba(0, 0, 0, 0.6) !important;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .todo-item {
      width: 100%;
    }
  }

  .MuiAccordion-root:before {
    display: none;
  }

  .MuiAccordion-root {
    padding: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }

  .MuiAccordionSummary-root {
    padding: 0;

    .MuiIconButton-root {
      width: 24px;
      height: 24px;
    }

    .title {
      display: flex;
      align-items: center;
      gap: 8px;
      line-height: 24px;

      .number-of-todos {
        border-radius: 4px;
        height: 20px;
        width: 20px;
        background-color: #eceeef;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        font-family: $FMediumBold;
      }
    }

    .MuiAccordionSummary-expandIcon {
      order: -1;
      margin-right: 8px;
      color: #000;
    }

    .MuiAccordionSummary-content {
      align-items: center;
      display: flex;
      color: #000;
      font-weight: bold;
      margin: 16px 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 0px;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
}