@import '../../responsiveUtils.scss';

.cms-carousel {
  .carousel-preview-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .cms-card-preview-image {
    /* Start: Aspect Ratio */
    width: 100%;
    margin: 0 auto;
    position: relative;
    /* If you want text inside of it */

    > iframe {
      position: absolute;
    }

    &.ratio11 {
      padding-bottom: 100%;
      /* 1:1 Aspect Ratio */
    }

    &.ratio169 {
      padding-bottom: 56.25%;
      /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    }

    &.ratio32 {
      padding-bottom: 66.66%;
      /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
    }

    &.ratio23 {
      padding-bottom: 150%;
      /* 2:3 Aspect Ratio (divide 3 by 2 = 1.5)  */
    }

    /* End: Aspect Ratio */

    .cms-carousel-image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      /* Start: Aspect Ratio */
      position: absolute;
      /* End: Aspect Ratio */
    }

    &-overlay {
      // width: 100%;
      // height: 100%;
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .cms-carousel-preview-info {
    color: #fff;
    position: absolute;
    display: flex;
    flex-direction: column;
    max-width: 700px;
    gap: 24px;
    z-index: 3;
    width: 100%;

    &.topCenter {
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 80px;
    }

    &.topLeft {
      left: 0;
      margin-left: 80px;
      margin-top: 80px;
    }

    &.topRight {
      right: 0;
      margin-right: 80px;
      margin-top: 80px;
    }

    &.middleLeft {
      top: 50%;
      left: 80px;
      transform: translate(0, -50%);
    }

    &.middleCenter {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.middleRight {
      top: 50%;
      right: 80px;
      transform: translate(0, -50%);
    }

    &.bottomLeft {
      bottom: 50px;
      left: 80px;
      margin-bottom: 80px;
    }

    &.bottomCenter {
      bottom: 50px; // because padding: 50px 0 in .cms-carousel-preview
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 80px;
    }

    &.bottomRight {
      bottom: 50px;
      right: 80px;
      margin-bottom: 80px;
    }

    &.center {
      align-items: center;
    }

    &.left {
      align-items: flex-start;
    }

    &.right {
      align-items: flex-end;
    }

    &.justify {
      align-items: stretch;
    }

    .cms-carousel-preview-title {
      font-family: 'F37Judge-MediumCondensed';
      letter-spacing: 0.02em;
      white-space: pre-wrap;
      overflow-wrap: break-word;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      white-space: normal;
      max-width: 100%;

      &.large {
        font-size: 85px;
        line-height: 80px;
      }

      &.medium {
        font-size: 60px;
        line-height: 72px;
      }

      &.small {
        font-size: 28px;
        line-height: 32px;
      }

      &.center {
        text-align: center;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.justify {
        text-align: justify;
      }
    }

    .cms-carousel-preview-description {
      font-size: 15px;
      line-height: 20px;
      white-space: pre-wrap;
      width: 100%;
      overflow-wrap: break-word;
      p {
        margin: 0;
      }

      &.center {
        text-align: center;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.justify {
        text-align: justify;
      }
      ul,
      ol {
        display: flex;
        flex-direction: column;
        li {
          text-align: left;
        }
      }
      &.center ul,
      &.center ol {
        align-items: center;
      }
      &.right ul,
      &.right ol {
        align-items: flex-end;
      }
    }

    .cms-carousel-preview-buttons {
      display: flex;
      align-items: flex-start;
      gap: 16px;

      .btn-cms {
        text-transform: none;
        max-width: 400px;

        .MuiButton-label {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }

  .slick-arrow {
    z-index: 2;
    background-color: transparent;
    color: #dbe1e3;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out;

    .MuiSvgIcon-root {
      font-size: 30px;
    }

    &::before {
      content: none;
    }

    &:hover,
    &:focus {
      color: #fff;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  .slick-prev {
    left: 25px;
  }

  .slick-next {
    right: 25px;
  }

  .slick-dots {
    bottom: 44px;

    li {
      button:before {
        font-size: 14px;
        color: #dbe1e3;
        opacity: 1;
      }

      &.slick-active button:before {
        opacity: 1;
        color: #000;
      }
    }
  }

  &.mobile-mode {
    .cms-carousel-preview-info {
      max-width: 80%;
      &.topCenter {
        margin-top: 32px;
      }

      &.topLeft {
        margin-left: 32px;
        margin-top: 32px;
      }

      &.topRight {
        margin-right: 32px;
        margin-top: 32px;
      }

      &.middleLeft {
        left: 32px;
      }

      &.middleRight {
        right: 32px;
      }

      &.bottomLeft {
        bottom: 32px;
        left: 32px;
        margin-bottom: 32px;
      }

      &.bottomCenter {
        bottom: 32px; // because padding: 50px 0 in .cms-carousel-preview
        margin-bottom: 32px;
      }

      &.bottomRight {
        bottom: 32px;
        right: 32px;
        margin-bottom: 32px;
      }
    }
    .cms-carousel-preview-info {
      max-width: 80%;
    }
    .slick-arrow {
      width: 32px;
      height: 32px;
      z-index: 10;
    }

    .slick-prev {
      left: 12px;
    }

    .slick-next {
      right: 12px;
    }

    .slick-dots {
      bottom: 16px;

      li {
        margin: 0;
        width: 16px;
        height: 16px;

        button {
          width: 16px;
          height: 16px;

          &:before {
            font-size: 6px;
          }
        }
      }
    }
  }
}