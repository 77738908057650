.video-message {
  height: auto;
  width: 220px;
  margin-bottom: 8px;

  .video-message-content {
    border-radius: 4px;
    cursor: pointer;
    background-color: #eceeef;
  }

  .player {
    pointer-events: none;

    iframe {
      margin-bottom: -30px;
    }

    div[class*='Player'] {
      background-color: #f4f7f8;
      border-radius: 4px;
      padding: 0;
      height: 112px !important;
    }

    div[class*='VaamBadge'] {
      display: none;
    }

    div[class*='video'] {
      border-radius: 4px;
      height: 112px !important;
    }

    button[class*='vjs-big-play-button'] {
      width: 40px;
      height: 40px;

      .vjs-icon-placeholder:before {
        transform: scale(0.6);
      }
    }

    div[class*='BackgroundBox'] {
      min-height: 112px;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    padding: 12px;
    gap: 4px;
    word-break: break-word;
    text-align: left;


    .title {
      line-height: 16px;
      font-weight: bold;
    }

    .description {
      line-height: 16px;
    }
  }
}