.resource-form-dialog {
  .MuiDialog-paper {
    width: 50%;
    max-width: 450px;
    background-color: #fff;
    min-height: 410px;
    color: #000;
  }

  .close-button {
    top: 12px;
    right: 8px;

    .close-icon {
      color: #000;
    }
  }

  .content {
    padding: 0;

    .title {
      display: flex;
      padding: 24px;
      font-size: 15px;
      font-family: 'MediumLLWeb-Bold';
    }

    .form {
      padding: 0 24px;
    }

    .label {
      font-size: 12px;
      line-height: 16px;
    }

    .input {
      padding-top: 4px;
    }

    .MuiOutlinedInput-input {
      padding: 11px 14px;
    }

    .MuiOutlinedInput-root:hover {
      cursor: default;
      background-color: transparent;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #dbe1e3;
    }
  }

  .submit-button {
    width: 100%;
    position: absolute;
    bottom: 0;

    .Mui-disabled {
      background-color: #dbe1e3 !important;

      .MuiButton-label {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .MuiButton-containedPrimary {
      background-color: #000;
    }
  }
}
