@import '../variables.scss';

.spce-portal-header {
  background-color: #fff;
  z-index: 1;

  > header {
    box-shadow: none;
  }
  .MuiAppBar-colorDefault {
    background-color: $secondary-color-variant !important;
  }

  .icon-spce-logo {
    font-size: 35px;
    color: #010202;
  }

  .MuiToolbar-regular {
    height: 56px;
    min-height: 56px !important;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
  }

  .content {
    display: flex;
    flex: 1;
    // overflow: hidden;
  }
  .content-right {
    display: flex;
    justify-content: center;
    .user-avatar {
      background-color: #000000 !important;
      h6 {
        color: #ffffff !important;
      }
    }
  }
}
