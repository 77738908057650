.space-privacy {
  label {
    font-family: 'MediumLLWeb-Bold';
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .privacy-level {
    display: flex;
    align-items: center;
    gap: 8px;

    .space-privacy-title {
      padding-top: 2px;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
      color: rgba(0, 0, 0);
    }

    .information-icon {
      margin-top: 2px;
      margin-left: -4px;

      .icon-new-info {
        width: 16px;
        height: 16px;
        color: #000;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;

        span {
          font-size: 12px;
        }

        &:hover {
          color: #000000;
        }
        .path2:before {
          color: #000;
        }
      }
    }
  }
}