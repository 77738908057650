.resources-grid-view-container {
  padding: 0 24px;
  container-type: inline-size;

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 16px;
    padding: 16px 0;

    .resource-grid-item {
      background-color: #fff;

      &.draggable {
        cursor: move;
      }

      &.isDragging {
        opacity: 0;
      }
    }
  }

  @container(width < 500px) {
    .grid {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  }
}