.preview-details {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  div {
    word-break: break-all;
  }
  .preview-row {
    display: flex;
    width: 100%;
    min-height: 24px;
    align-items: baseline;
    .field-name {
      flex: 0 0 90px;
    }
  }
  .preview-column {
    gap: 4px;
    display: flex;
    flex-direction: column;
  }
  .divider-container {
    margin: 0;
  }
}