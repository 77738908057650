@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.cms-resource-widget {
  display: flex;
  flex-direction: column;

  .tabs {
    margin-bottom: 16px;
  }

  .control-box {
    margin: 0 24px 16px;

    .filter-box-wrapper {
      flex: 1;
    }
  }

  .material-item-left {
    .material-item-thumbnail {
      width: 72px;
      text-align: center;
    }

    .material-item-checkbox {
      .check-material-item {
        margin-left: 20px;
        margin-right: -10px;
      }
    }
  }

  .cms-widget-items {
    .material-list-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .material-list {
        .material-item {
          .MuiIconButton-colorSecondary.Mui-checked {
            color: #414141;
          }

          &:hover {
            .check-material-item {
              color: #414141;
            }
          }

          .material-item-right {
            .action-menu-buttons {
              .icon-convert {
                &::before {
                  color: $primary-color;
                }
              }
            }
          }
        }
      }
    }

    .directories-item {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.4);

      &:hover {
        background-color: #f4f7f8;
      }

      &.has-changes {
        background-color: #f0f5ff;
      }

      .title {
        color: #000;
      }

      .directories-item-right {
        .more-btn {
          color: #000;

          &.Mui-disabled {
            color: rgba(0, 0, 0, 0.4);
          }
        }
      }
    }

    .page-not-found {
      color: #000 !important;
    }

    // .resource-indicator {
    //   &.onedrive-gray {
    //     filter: grayscale(1) brightness(0.8);
    //   }
    //   &.icon-globe,
    //   &.icon-video-message {
    //     filter: invert(0.8);
    //   }
    // }
  }

  .access-level-form {
    flex: 1;

    .form-label {
      font-size: 13px;
    }
  }

  .accesslevel-control {
    margin-top: 24px;
  }

  .accesslevel-select {
    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
      border-color: #dbe1e3 !important;
    }

    .MuiOutlinedInput-root {
      background-color: #fff;
      color: #000;

      .MuiOutlinedInput-notchedOutline {
        border: 1px solid #dbe1e3;
      }
    }

    .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline,
    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #dbe1e3 !important;
    }

    .MuiSelect-outlined.MuiSelect-outlined {
      padding-top: 11px;
      padding-bottom: 11px;
      background-color: #fff !important;
      color: $primary-color !important;
      border: 1px solid #dbe1e3;
    }
  }

  .cms-widget-footer {
    padding: 0;
  }

  .uploader-area {
    .upload-container {
      .dnd-file-uploader-control {
        &-label {
          color: #000;
        }

        &-input {
          .MuiOutlinedInput-root {
            background-color: #fff !important;
            color: $primary-color !important;
            border: 1px solid #dbe1e3;

            &:hover {
              border: 1px solid #dbe1e3;
            }
          }

          .MuiInputAdornment-positionEnd {
            .MuiIconButton-root {
              padding: 8px;
            }
          }

          .edit-icon,
          .delete-icon {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }

      .upload-area {
        .or-divider {
          hr {
            border: 1px solid #eceeef;
          }

          &-text {
            color: $primary-color;
            opacity: 0.4;
          }
        }

        .upload-btns {
          .upload-btn {
            border: 1px dashed rgba(0, 0, 0, 0.4);
            background-color: rgba(219, 225, 227, 0.2);

            p {
              color: $primary-color;
            }
          }
        }
      }

      .upload-progress {
        background-color: rgba(219, 225, 227, 0.2);
        color: $primary-color;
        border: 1px solid #dbe1e3;

        .bottom-section {
          .message {
            color: rgba(0, 0, 0, 0.6);
          }

          .action-button {
            .MuiButton-label {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .MuiLinearProgress-root {
          background-color: rgba(0, 0, 0, 0.1);
        }
      }
    }
  }

  .folder-form-control-button {
    background-color: #fff;
    color: #000;
    box-shadow: 0px -1px 0px #dbe1e3;

    &:hover {
      background-color: #f4f7f8;
    }

    &.Mui-disabled {
      box-shadow: 0px -1px 0px #dbe1e3;
      color: #000;
      background-color: #fff;
    }
  }

  .folder-form-control {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-label {
      font-size: 12px;
      line-height: 16px;
    }

    &-input {
      .MuiOutlinedInput-root {
        color: #000;
        background-color: #fff;

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #dbe1e3;
        }
      }
    }
  }

  .material-list .material-item .material-item-right .action-menu-buttons .icon-convert {
    &::before {
      color: $primary-color;
    }
  }
}

@include media(phone) {
  .cms-resource-widget .material-item-left .material-item-thumbnail {
    padding: unset;
  }
}
