@import '../variables.scss';


.breadcrumbs {
  .MuiTypography-root {
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
  }

  .MuiBreadcrumbs-separator {
    margin-left: 0;
    margin-right: 0;
  }
}

.gr-select-dialog-title {
  height: 24px;

  .MuiTypography-root {
    font-size: 15px;
    line-height: 24px;
  }

  .MuiBreadcrumbs-ol .MuiBreadcrumbs-li:first-child .MuiTypography-root {
    font-family: $FMediumBold;
    color: #000;
    line-height: 24px;
    align-items: center;
  }

  .MuiBreadcrumbs-separator {
    color: rgba(0, 0, 0, 0.6);
    margin-left: 4px;
    margin-right: 4px;

    .MuiSvgIcon-root {
      width: 24px;
      height: 20px;
    }
  }
}