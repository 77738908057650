.resource-thumbnail {
  width: 100%;
  height: 100%;
  background-color: #f5f7f8;
  display: flex;
  justify-content: center;
  align-items: center;

  // &.resource-thumbnail-loading {
  //   width: 100%;
  // }

  &.black-bg {
    background-color: #222;
  }

  &.image-viewer {
    position: unset;
  }

  .image-viewer {
    background-size: contain;
    width: auto;
  }

  .content-center,
  .loading {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .content-center {
    padding: 8px;
    font-size: 13px;
  }

  .item-icon,
  .icon-folder {
    font-size: 54px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .icon-folder {
    color: #dbe1e3;

    &::after {
      font-size: 16px;
      color: #000;
    }
  }

  .item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
