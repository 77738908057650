@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.todo-item {
  padding: 8px 16px;
  background-color: #F4F7F8;
  border-radius: 4px;

  &.clickable {
    cursor: pointer;
  }

  &.closed {
    opacity: 0.6;
  }

  &.disable-onclick {
    pointer-events: none;
  }

  &:first-child {
    margin-top: 0;
  }

  .bodycontent {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;

    .description {
      font-size: 13px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.6);
      max-width: 384px;
      word-wrap: break-word;
      white-space: normal;
    }
  }

  .bodybottom {
    display: flex;
    justify-content: space-between;
    gap: 4px;

    .user-info {
      display: flex;
      gap: 4px;

      .user-avatar {
        width: 24px;
        height: 24px;

        h6 {
          font-size: 12px;
        }
      }
    }

    .duedate {
      font-family: 'MediumLLWeb-Regular';
      font-size: 13px;
      line-height: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }

    .assignee {
      font-family: 'MediumLLWeb-Regular';
      font-weight: normal;
      font-size: 13px;
      line-height: 24px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.6);
    }

    &.unassigned {
      justify-content: flex-end;
    }
  }

  .bodytop {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .bodytitle {
      display: flex;
      align-items: center;
      gap: 8px;
      max-width: 380px;

      &.done {
        .priority {
          background-color: rgba(219, 225, 227, 0.2);

          .priority__content {
            color: rgba(255, 255, 255, 0.6);
          }
        }
      }

      .title {
        font-family: 'MediumLLWeb-Regular';
        font-weight: 400;
        color: $primary-color;
        line-height: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .priority {
        background-color: #76a2fe;
        border-radius: 2px;
        height: 16px;
        padding: 2px 4px;
        line-height: 12px;
        letter-spacing: 0.5px;

        &__content {
          color: $primary-color;
          font-family: 'MediumLLWeb-Bold';
          font-size: 8.5px;
          line-height: 12px;
          text-transform: uppercase;
        }
      }
    }

    .action {
      opacity: 1;

      .icon-button {
        padding: 0;
      }

      .Mui-disabled {
        svg {
          color: rgba(0, 0, 0, 0.4);
        }
      }

      svg {
        font-size: 1.4rem;
        color: #000;
      }
    }
  }

  &:hover {
    .bodytop .action {
      opacity: 1;
    }
  }
}

@include media(phone) {
  .todo-item .bodytop .action {
    opacity: 1;
  }
}