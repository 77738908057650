@import '../responsiveUtils.scss';
@import '../variables.scss';

.space-email-verify-dialog {
  .MuiDialog-paper {
    min-width: 400px;

    .responsive-dialog-title {
      text-transform: uppercase;
      position: relative;
      top: 0;
    }

    .icon-box {
      width: 50px;
      height: 50px;
      background-color: $green-color;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      margin-bottom: 24px;

      .icon-envelop {
        color: $secondary-color;
        font-size: 24px;
      }
    }



    .content {
      margin-top: 0;
      padding: 29px !important;
      text-align: center;

      .description {
        margin: 24px 0;
        line-height: 24px;
      }
    }
  }
}

@include media(phone) {
  .space-access-pin-dialog {
    .MuiDialog-paper {
      min-width: unset;
    }
  }
}