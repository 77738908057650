@import '../responsiveUtils.scss';

.share-video-dialog {
  .MuiDialog-paper {
    width: 1280px;
    height: 100%;

    .header {
      background-color: #fff;
    }
  }

  .dialog-content {
    max-width: 1280px;
  }

  .header .logo {
    position: absolute;
    top: 24px;
    left: 24px;
    height: 23px;
  }

  .close-button {
    top: 13px;
    right: 6px;
  }

  .content {
    padding: 0;
    display: flex;
    height: 100%;
    overflow: hidden;
    flex-direction: row;
  }

  .left {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f4f7f8;

    .form {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 72px;
    }

    .textfield {
      width: 80%;

      ::before {
        border: none;
      }

      &.title {
        .MuiInputBase-root {
          font-family: 'F37Judge-MediumCondensed';
          font-size: 40px;

          letter-spacing: 0.02em;
        }

        input::placeholder {
          color: #dbe1e3;
          opacity: 1;
        }
      }
    }

    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 24px;
      opacity: 0.6;
    }

    .dot {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      span {
        height: 4px;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
      }
    }

    .player {
      padding: 10px 24px 24px;

      div[class*='Player'] {
        background-color: #f4f7f8;
        padding: 8px 0;
      }

      div[class*='VaamBadge'] {
        display: none;
      }

      div[class*='video'] {
        border-radius: 4px;
        height: 380px;
      }

      video {
        border-radius: 4px;
      }
    }
  }

  .right {
    width: 100%;
    color: #000;
    display: flex;
    flex: 1;
    flex-direction: column;

    .title {
      padding: 24px;
    }

    .vaam-participant-tab {
      flex: 1 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      // .vaam-participant-tab-body {
      //   flex: 1;
      //   overflow: auto;
      // }
    }

    .MuiDivider-root {
      margin: 0 20px;
    }

    .footer {
      padding: 0 24px 24px 24px;
    }

    .action-buttons {
      margin-top: 10px;
      display: flex;
      justify-content: flex-end;
      gap: 24px;
      .MuiButton-root {
        width: 100%;
      }
    }

    .regular-rounded-button {
      border-radius: 100px;
      padding: 8px 16px;
      font-size: 15px;
      line-height: 24px;
      text-transform: uppercase;
      background-color: #000;
      color: #fff;
      font-family: 'MediumLLWeb-Bold';

      &.Mui-disabled {
        background-color: #eceeef;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .btn-gray {
      color: #000;
      background-color: #eceeef;
      margin-right: 24px;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

@include media(tab) {
  .share-video-dialog {
    padding: 40px;

    .MuiDialog-container {
      overflow: auto;
      min-height: 300px;

      .header .title {
        height: 76px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-family: 'MediumLLWeb-Bold';
      }
    }

    .MuiDialog-paper {
      width: 100%;
      height: 100%;
      overflow: auto;
    }

    .content {
      overflow-y: auto;
      display: grid;

      .left {
        .form {
          margin-top: 0;
        }

        .player {
          display: flex;
          justify-content: center;
        }
      }

      .right {
        .vaam-participant-tab-header {
          padding-left: 0;
        }

        .participant-list-container {
          max-height: 200px;
        }
      }
    }

    div[class*='video'] {
      height: 233px !important;
      width: 373px;
    }
  }
}

@include media(desktop) {
  .share-video-dialog {
    .MuiDialog-paper {
      max-height: calc(100vh - 80px);
    }

    .content {
      .left {
        width: 45%;
      }

      .right {
        width: 55%;
      }
    }
  }
}

@include media(md-desktop) {
  .share-video-dialog {
    .MuiDialog-paper {
      max-height: 720px;
    }

    .content {
      .left {
        width: 55%;
      }

      .right {
        width: 45%;
      }
    }
  }
}