.banner {
  min-height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 0 26px;

  &-warning {
    background-color: #ad2de1;
  }

  &-info {
    background-color: #4783FF;
  }

  .banner-left {
    display: flex;
    align-items: center;

    .info-icon {
      color: #fff;
      font-size: 20px;
      margin-right: 10px;
    }

    .description {
      line-height: 20px;
    }
  }

  .banner-right {
    button {
      color: #fff;
    }
  }
}
