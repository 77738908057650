.closed-space-dialog {
  .MuiDialog-paper {
    height: 240px;
    max-height: 240px;
    min-height: unset !important;
  }

  .content {
    flex: unset;
    gap: 24px;
    justify-content: unset !important;
  }

  .icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eceeef;
    border-radius: 50%;
    margin-top: 4px;

    .lock-icon {
      font-size: 23px;
    }
    .visibility-off-icon {
      font-size: 23px;
      color: rgba(0, 0, 0, 0.2);
    }
  }

  .message {
    text-align: center;
  }
}
