.cms-block-pages {
  border-bottom: 1px solid #ECEEEF !important;

  &.fixed {
    position: relative !important;
    width: 100%;
  }
}

.cms-pages-navigation {
  display: flex;
  width: 100%;
  justify-content: center;

  &.sticky {
    position: sticky !important;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }

  .cms-pages-navigation-tab {
    border-bottom: none;
    padding: 0;

    .tab-item {
      opacity: 1;
      border-radius: unset;

      .tab-wrapper {
        line-height: 1.2;
        color: #000000;
      }
    }
  }

  &-mobile {
    display: flex;
    justify-content: flex-end;
    height: 56px;
    align-items: center;
    padding-right: 12px;

    .MuiIconButton-root {
      color: #000000;
    }

    .cms-pages-navigation {
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      height: calc(100vh - 100%);
      z-index: 99;
      background-color: rgba(0, 0, 0, 0.4);

      .cms-pages-navigation-tab {
        flex-direction: column;
        width: 100%;
        height: fit-content;
        padding: 16px;

        .tab-item {
          .tab-wrapper {
            display: inline-block;
            width: fit-content;
          }
        }
      }
    }

    .MuiButtonBase-root {
      width: 40px;
      height: 40px;
      padding: 8px;
    }
  }
}

.space-template-designer-body {
  .row-container-margin-top {
    .cms-block-pages {
      .cms-theme-preview-row-container {
        &.no-padding-top {
          padding-top: 4px !important;
        }

        &.no-padding-bottom {
          padding-bottom: 4px !important;

        }
      }
    }
  }
}