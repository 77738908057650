.warning-custom-message-popup {
  display: flex;
  flex-direction: row;

  .warning-custom-message-popup-content {
    margin-right: 16px;
  }
  .warning-custom-message-popup-actions {
    padding: 4px;
  }

  .regular-rounded-button {
    border-radius: 100px;
    padding: 8px 16px;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
