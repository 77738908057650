.table-container.resources-table {
  padding: 0 8px;

  .table {
    table-layout: fixed;

    .header-cell {
      background-color: #fff;
      font-family: 'MediumLLWeb-Bold';
      font-size: 10px;
      line-height: 16px;
      color: #000;
      text-transform: uppercase;
      white-space: nowrap;
    }

    .MuiTableRow-root {
      &.resource-item {
        position: relative;

        &.mobile-mode {
          .resource-item-actions {
            width: 56px;
          }
        }

      }

      &.disabled-row .MuiTableCell-body {
        color: rgba(0, 0, 0, 0.3) !important;
      }

      &.dimmed {

        .material-item-left,
        .material-item-right .material-item-title-wrapper .material-item-title {
          opacity: 0.6;
        }
      }

      &.draggable {
        cursor: move;

        .resource-name {
          cursor: move;
        }
      }

      &.isDragging {
        opacity: 0;
      }

      &.mobile-mode {
        .resource-name {
          gap: 16px;
        }
      }

      &.new-file {
        background-color: #f0f5ff;

        .action-menu-buttons {
          display: none;
        }

        .blue-dot-wrapper {
          display: block;
        }
      }

      &:hover {
        background-color: #F4F7F8;

        .action-menu-buttons {
          display: block;
        }

        .blue-dot-wrapper {
          display: none;
        }
      }
    }

    .MuiTableCell-root {
      border-bottom: none;
      padding: 8px 16px;

      &.indicator {
        padding: 0;
      }

      .resource-indicator {
        line-height: 20px;
        width: 16px;
        height: 16px;

        &::before {
          color: rgba(0, 0, 0, 0.6);
        }

        &.icon-onedrive {
          font-size: 12px;
        }
      }

      .title {
        display: flex;
      }

      &.MuiTableCell-head {
        &.resource-name {
          width: auto;
        }

        &.table-head {
          margin-top: 5px;
        }
      }

      &.MuiTableCell-paddingNone {
        padding: 0;

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .MuiTableSortLabel-icon {
      font-size: 16px;
    }

    .MuiTableSortLabel-root {
      display: flex;

      &.MuiTableSortLabel-active {
        color: #000;

        &.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
          color: #000;
        }
      }

      &:hover {
        color: #000;

        .MuiTableSortLabel-icon {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .action-button {
      width: 40px;
      padding-right: 0;
      padding-left: 0;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }

    .action-menu-button {
      padding: 0px;

      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 100px;
      }

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.3);
      }

      .MuiSvgIcon-root {
        font-size: 24px;
      }
    }

    .blue-dot-wrapper {
      height: 24px;
      text-align: center;
    }

    .MuiTableCell-paddingCheckbox {
      width: 24px;
      padding-right: 0;
    }

    .MuiCheckbox-colorPrimary {
      padding: 0;

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.3);
      }
    }

    .control-button {
      color: #000;
      margin-left: auto;
      padding: 0;
      padding-right: 8px;

      &.active {
        color: #4783ff;
      }

      &:hover {
        color: #4783ff;
      }

      .icon-given-control {
        font-size: 16px;
      }
    }
  }

  .load-more {
    width: 100%;
    font-family: 'MediumLLWeb-Bold';
    font-size: 10px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;

    &:hover {
      color: #000;
    }
  }
}