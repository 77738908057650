@import '../../variables.scss';

.chat-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .chat-header {
    display: flex;
    align-items: center;
    position: relative;
    padding: 30px 16px;
    background-color: #fff;

    .user-avatar {
      margin-right: 12px;
      width: 24px !important;
      height: 24px !important;
      h6 {
        font-size: 12px;
      }
    }

    .back-button {
      cursor: pointer;
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: $primary-color;
        font-size: 24px;
      }
    }

    .name {
      color: $primary-color;
      margin: 0;
      font-family: 'MediumLLWeb-Bold';
    }
  }

  .chat-body {
    display: flex;
    flex: 1;
    overflow-y: auto;
    padding: 0px;

    .comments-header {
      margin: 0;
      padding: 20px;
    }

    .comments-list {
      padding: 20px 20px 0 20px;
    }
  }
}
