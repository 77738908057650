// orientation: landscape and portrait
@mixin media($breakpoint, $orientation: null) {
  @if $breakpoint ==phone {
    @if $orientation ==null {
      @media (max-width: 767px) and (orientation: portrait)  {
        @content;
      }
    }

    @else {
      @media (max-width: 900px) and (orientation: #{$orientation}) {
        @content;
      }
    }
  }

  @else if $breakpoint == tab {
    @media (min-width: 768px) and(max-width: 1023px) {
      @content;
    }
  }

  @else if $breakpoint == desktop {
    @media (min-width: 1024px) {
      @content;
    }
  }

  @else if $breakpoint == md-desktop {
    @media (min-width: 1280px) {
      @content;
    }
  }

  @else if $breakpoint == x-desktop {
    @media (min-width: 1680px) {
      @content;
    }
  } @else {
    @media (#{$breakpoint}) {
      @content;
    }
  }
}