@import '../responsiveUtils.scss';

.space-info {
  display: flex;
  align-items: center;
  .space-name {
    display: flex;
    align-items: center;
    align-self: flex-start;
    gap: 12px;
    max-width: 400px;
    height: 40px;

    &.light {
      color: #fff;
    }
    h1 {
      margin: 0;
      line-height: 40px;
      font-family: 'MediumLLWeb-Bold';
      letter-spacing: 0;
      font-size: 18px;
    }
    &-logo {
      margin-right: 20px;
    }
  }
  .space-name-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;

    .MuiSvgIcon-root {
      font-size: 20px;
      color: #000;
    }
    .icon-lock {
      font-size: 20px;
    }
  }
  .info-space-button {
    margin-left: 6px;
  }
}


@include media(phone) {
  .space-info {
    .space-name {
      &.has-icon {
        width: calc(100% - 20px);
      }
      width: 100%;
      h1 {
        font-size: 18px;
        line-height: 40px;
        font-family: 'MediumLLWeb-Bold';
      }
      .space-name-icon {
        width: 24px;
        height: 24px;
        min-width: 24px;
      }
    }
  }
}
