@import '../../variables.scss';

.material-new-folder-drawer {
  .MuiDrawer-paper {
    width: 500px;
    background-color: #fff;
    color: #000;
    top: 62px;
    left: 72px;
    bottom: 8px;
    border-radius: 10px;
    height: auto;
  }

  .MuiBackdrop-root {
    background-color: transparent;
  }

  .header {
    height: 84px;
    background-color: $secondary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-family: 'MediumLLWeb-Bold';
    font-size: 16px;
  }

  .alert-info {
    background-color: #47484d;
    color: #fff;
  }
}