.share-3rd-parties {
  display: flex;
  flex-flow: row;
  flex-basis: max-content;
  width: 100%;
  flex-direction: column;
  .row {
    justify-content: space-evenly;
    display: flex;
    > div {
      display: flex;
      width: 100px;
      flex-direction: column;
      text-align: center;
      height: 64px;
      margin: 24px;
      margin-top: 0;
      cursor: pointer;
      justify-content: space-between;
      &:hover {
        .app-text {
          text-decoration: underline;
        }
      }

      > span {
        font-size: 13px;
      }

      .icon {
        font-size: 40px;
      }
    }
  }

}