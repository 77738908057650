.datetime-picker {
  position: relative;
  .datetime-container {
    display: flex;
    flex: 1;
    flex-direction: column;

    > div {
      display: flex;
      width: 100%;
    }

    div.date {
      width: 100%;
      display: flex;
    }
  }
  .clear-button {
    position: absolute;
    right: 35px;
    top: 0;
    padding: 9px;
  }

  .MuiInputBase-root {
    .MuiInputAdornment-positionEnd {
      .Mui-disabled {
        color:rgba(0, 0, 0, 0.2);
      }
    }
  }
  
  .MuiIconButton-root {
    padding: 9px;

  }
}

.DateTimeDialog {
  .MuiButton-label {
    font-family: 'MediumLLWeb-Bold';
  }

  .MuiPickersDay-daySelected {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 500;
    background-color: #bcb8b8 !important;
  }

  .MuiPickersDay-current {
    color: #000 !important;
  }
}
