@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?wy642x');
  src:  url('fonts/icomoon.eot?wy642x#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?wy642x') format('truetype'),
    url('fonts/icomoon.woff?wy642x') format('woff'),
    url('fonts/icomoon.svg?wy642x#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-unsupported-file:before {
  content: "\ea7f";
  color: #4783ff;
}
.icon-pages .path1:before {
  content: "\ea7b";
  color: rgb(0, 0, 0);
}
.icon-pages .path2:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-pages .path3:before {
  content: "\ea7d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-pages .path4:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-open-link:before {
  content: "\ea30";
}
.icon-minus-liked:before {
  content: "\ea2f";
}
.icon-keynote .path1:before {
  content: "\ea79";
  color: rgb(71, 131, 255);
}
.icon-keynote .path2:before {
  content: "\ea7a";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-upload-folder:before {
  content: "\ea77";
}
.icon-upload-files:before {
  content: "\ea78";
}
.icon-filter2:before {
  content: "\ea74";
}
.icon-company-analytics .path1:before {
  content: "\ea6f";
  color: rgb(195, 99, 233);
}
.icon-company-analytics .path2:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(71, 131, 255);
}
.icon-company-analytics .path3:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(71, 131, 255);
}
.icon-company-analytics .path4:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(71, 131, 255);
}
.icon-company-analytics .path5:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(71, 131, 255);
}
.icon-space-analytics .path1:before {
  content: "\ea6a";
  color: rgb(71, 131, 255);
}
.icon-space-analytics .path2:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(211, 129, 245);
}
.icon-space-analytics .path3:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(211, 129, 245);
}
.icon-space-analytics .path4:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(211, 129, 245);
}
.icon-space-analytics .path5:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(211, 129, 245);
}
.icon-phone-dark .path1:before {
  content: "\ea63";
  color: rgb(255, 255, 255);
}
.icon-phone-dark .path2:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-desktop-light .path1:before {
  content: "\ea65";
  color: rgb(0, 0, 0);
}
.icon-desktop-light .path2:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-phone-light:before {
  content: "\ea67";
  color: #fff;
}
.icon-desktop-dark .path1:before {
  content: "\ea68";
  color: rgb(255, 255, 255);
}
.icon-desktop-dark .path2:before {
  content: "\ea69";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-notes:before {
  content: "\ea62";
}
.icon-chat-new:before {
  content: "\ea61";
  color: #f44a4a;
}
.icon-touch:before {
  content: "\ea60";
  color: #4783ff;
}
.icon-circle-xmark:before {
  content: "\ea5f";
}
.icon-pointer:before {
  content: "\ea5e";
  color: #fff;
}
.icon-secure-icon:before {
  content: "\ea5d";
  color: #606367;
}
.icon-design-1:before {
  content: "\ea5b";
}
.icon-home:before {
  content: "\ea5c";
}
.icon-k-command:before {
  content: "\ea56";
  color: #fff;
}
.icon-number-8:before {
  content: "\ea5a";
  color: #fff;
}
.icon-number-7:before {
  content: "\ea59";
  color: #fff;
}
.icon-shift:before {
  content: "\ea58";
  color: #fff;
}
.icon-line:before {
  content: "\ea57";
  color: #fff;
}
.icon-subscript:before {
  content: "\ea54";
}
.icon-superscript:before {
  content: "\ea55";
}
.icon-command:before {
  content: "\ea53";
  color: #fff;
}
.icon-new-info .path1:before {
  content: "\ea51";
  color: rgb(255, 255, 255);
}
.icon-new-info .path2:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(71, 131, 255);
}
.icon-facebook .path1:before {
  content: "\ea46";
  color: rgb(3, 155, 229);
}
.icon-facebook .path2:before {
  content: "\ea47";
  margin-left: -1.025390625em;
  color: rgb(255, 255, 255);
}
.icon-gmail .path1:before {
  content: "\ea48";
  color: rgb(66, 133, 244);
}
.icon-gmail .path2:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(52, 168, 83);
}
.icon-gmail .path3:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(234, 67, 53);
}
.icon-gmail .path4:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(251, 188, 4);
}
.icon-gmail .path5:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(197, 34, 31);
}
.icon-linkedin .path1:before {
  content: "\ea4d";
  color: rgb(2, 136, 209);
}
.icon-linkedin .path2:before {
  content: "\ea4e";
  margin-left: -1.025390625em;
  color: rgb(255, 255, 255);
}
.icon-twitter .path1:before {
  content: "\ea4f";
  color: rgb(3, 169, 244);
}
.icon-twitter .path2:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-participant-interaction:before {
  content: "\ea45";
}
.icon-website .path1:before {
  content: "\ea43";
  color: rgb(0, 0, 0);
}
.icon-website .path2:before {
  content: "\ea44";
  margin-left: -1.111328125em;
  color: rgb(255, 255, 255);
}
.icon-zip .path1:before {
  content: "\ea41";
  color: rgb(255, 255, 255);
}
.icon-zip .path2:before {
  content: "\ea42";
  margin-left: -1.25em;
  color: rgb(244, 74, 74);
}
.icon-googledrive .path1:before {
  content: "\ea3b";
  color: rgb(0, 102, 218);
}
.icon-googledrive .path2:before {
  content: "\ea3c";
  margin-left: -1.1201171875em;
  color: rgb(234, 67, 53);
}
.icon-googledrive .path3:before {
  content: "\ea3d";
  margin-left: -1.1201171875em;
  color: rgb(0, 131, 45);
}
.icon-googledrive .path4:before {
  content: "\ea3e";
  margin-left: -1.1201171875em;
  color: rgb(38, 132, 252);
}
.icon-googledrive .path5:before {
  content: "\ea3f";
  margin-left: -1.1201171875em;
  color: rgb(0, 172, 71);
}
.icon-googledrive .path6:before {
  content: "\ea40";
  margin-left: -1.1201171875em;
  color: rgb(255, 186, 0);
}
.icon-tag:before {
  content: "\ea3a";
}
.icon-google .path1:before {
  content: "\ea22";
  color: rgb(234, 67, 53);
}
.icon-google .path2:before {
  content: "\ea23";
  margin-left: -0.98046875em;
  color: rgb(52, 168, 83);
}
.icon-google .path3:before {
  content: "\ea24";
  margin-left: -0.98046875em;
  color: rgb(74, 144, 226);
}
.icon-google .path4:before {
  content: "\ea38";
  margin-left: -0.98046875em;
  color: rgb(251, 188, 5);
}
.icon-outlook:before {
  content: "\ea39";
  color: #0072c6;
}
.icon-lock:before {
  content: "\ea21";
}
.icon-white-board .path1:before {
  content: "\ea33";
  color: rgb(0, 0, 0);
}
.icon-white-board .path2:before {
  content: "\ea34";
  margin-left: -1.111328125em;
  color: none;
}
.icon-white-board .path3:before {
  content: "\ea35";
  margin-left: -1.111328125em;
  color: rgb(255, 255, 255);
}
.icon-white-board .path4:before {
  content: "\ea36";
  margin-left: -1.111328125em;
  color: rgb(0, 0, 0);
}
.icon-record:before {
  content: "\ea37";
}
.icon-move:before {
  content: "\ea32";
  color: #fff;
}
.icon-js-script:before {
  content: "\ea31";
}
.icon-globe:before {
  content: "\ea28";
  color: #fff;
}
.icon-eye-dropper:before {
  content: "\ea27";
}
.icon-transfer:before {
  content: "\ea26";
}
.icon-columns:before {
  content: "\ea25";
}
.icon-scale-question .path1:before {
  content: "\ea1d";
  color: rgb(204, 221, 255);
}
.icon-scale-question .path2:before {
  content: "\ea1e";
  margin-left: -1.0419921875em;
  color: rgb(0, 0, 0);
}
.icon-wordcloud-question .path1:before {
  content: "\ea1f";
  color: rgb(204, 221, 255);
}
.icon-wordcloud-question .path2:before {
  content: "\ea20";
  margin-left: -1.0419921875em;
  color: rgb(0, 0, 0);
}
.icon-quiz-question .path1:before {
  content: "\ea29";
  color: rgb(204, 221, 255);
}
.icon-quiz-question .path2:before {
  content: "\ea2a";
  margin-left: -1.0419921875em;
  color: rgb(0, 0, 0);
}
.icon-poll-question .path1:before {
  content: "\ea2b";
  color: rgb(204, 221, 255);
}
.icon-poll-question .path2:before {
  content: "\ea2c";
  margin-left: -1.0419921875em;
  color: rgb(0, 0, 0);
}
.icon-move-right:before {
  content: "\ea2d";
  color: #fff;
}
.icon-move-left:before {
  content: "\ea2e";
  color: #fff;
}
.icon-fix-height .path1:before {
  content: "\ea16";
  color: rgb(0, 0, 0);
}
.icon-fix-height .path2:before {
  content: "\ea17";
  margin-left: -0.7998046875em;
  color: rgb(255, 255, 255);
}
.icon-fix-height .path3:before {
  content: "\ea18";
  margin-left: -0.7998046875em;
  color: rgb(255, 255, 255);
}
.icon-fix-width .path1:before {
  content: "\ea19";
  color: rgb(0, 0, 0);
}
.icon-fix-width .path2:before {
  content: "\ea1a";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-fix-width .path3:before {
  content: "\ea1b";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-image-1:before {
  content: "\ea1c";
}
.icon-new-zoom .path1:before {
  content: "\ea13";
  color: rgb(57, 132, 253);
}
.icon-new-zoom .path2:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-new-zoom .path3:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-onedrive .path1:before {
  content: "\ea0f";
  color: rgb(3, 100, 184);
}
.icon-onedrive .path2:before {
  content: "\ea10";
  margin-left: -1.560546875em;
  color: rgb(0, 120, 212);
}
.icon-onedrive .path3:before {
  content: "\ea11";
  margin-left: -1.560546875em;
  color: rgb(20, 144, 223);
}
.icon-onedrive .path4:before {
  content: "\ea12";
  margin-left: -1.560546875em;
  color: rgb(40, 168, 234);
}
.icon-icon-cad .path1:before {
  content: "\ea0b";
  color: rgb(71, 131, 255);
}
.icon-icon-cad .path2:before {
  content: "\ea0c";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-cad .path3:before {
  content: "\ea0d";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-cad .path4:before {
  content: "\ea0e";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-gear-solid:before {
  content: "\ea0a";
}
.icon-file-solid:before {
  content: "\ea09";
}
.icon-file-excel-solid:before {
  content: "\ea08";
}
.icon-file-word-solid:before {
  content: "\ea07";
}
.icon-file-powerpoint-solid:before {
  content: "\ea06";
}
.icon-file-pdf-solid:before {
  content: "\ea05";
}
.icon-image-solid:before {
  content: "\ea04";
}
.icon-pause-circle-solid:before {
  content: "\ea03";
}
.icon-plus-solid:before {
  content: "\ea02";
}
.icon-map-marker-alt-solid:before {
  content: "\ea01";
}
.icon-camera-solid:before {
  content: "\ea00";
}
.icon-film-solid:before {
  content: "\e9ff";
}
.icon-minus-solid:before {
  content: "\e9fe";
}
.icon-check-solid:before {
  content: "\e9fd";
}
.icon-dot-circle:before {
  content: "\e9fc";
}
.icon-info-circle:before {
  content: "\e9fb";
}
.icon-triangle-exclamation:before {
  content: "\e9fa";
}
.icon-circle-solid:before {
  content: "\e9f9";
}
.icon-times-solid:before {
  content: "\e9f8";
}
.icon-chalkboard-teacher:before {
  content: "\e9f7";
}
.icon-chalkboard:before {
  content: "\e9f6";
}
.icon-info-blue:before {
  content: "\e9f5";
  color: #4783ff;
}
.icon-privacy:before {
  content: "\e9f4";
}
.icon-icon-stp .path1:before {
  content: "\e9ea";
  color: rgb(71, 131, 255);
}
.icon-icon-stp .path2:before {
  content: "\e9eb";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-icon-stp .path3:before {
  content: "\e9ec";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-stp .path4:before {
  content: "\e9ed";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-stp .path5:before {
  content: "\e9ee";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-stl .path1:before {
  content: "\e9ef";
  color: rgb(71, 131, 255);
}
.icon-icon-stl .path2:before {
  content: "\e9f0";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-icon-stl .path3:before {
  content: "\e9f1";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-stl .path4:before {
  content: "\e9f2";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-stl .path5:before {
  content: "\e9f3";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-dwg .path1:before {
  content: "\e9cf";
  color: rgb(71, 131, 255);
}
.icon-icon-dwg .path2:before {
  content: "\e9d0";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-icon-dwg .path3:before {
  content: "\e9d1";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-dwg .path4:before {
  content: "\e9d2";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-dwg .path5:before {
  content: "\e9d3";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-igs .path1:before {
  content: "\e9d4";
  color: rgb(71, 131, 255);
}
.icon-icon-igs .path2:before {
  content: "\e9d5";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-icon-igs .path3:before {
  content: "\e9d6";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-igs .path4:before {
  content: "\e9d7";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-igs .path5:before {
  content: "\e9d8";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-jt .path1:before {
  content: "\e9d9";
  color: rgb(71, 131, 255);
}
.icon-icon-jt .path2:before {
  content: "\e9da";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-icon-jt .path3:before {
  content: "\e9db";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-jt .path4:before {
  content: "\e9dc";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-mp3 .path1:before {
  content: "\e9dd";
  color: rgb(31, 218, 139);
}
.icon-icon-mp3 .path2:before {
  content: "\e9de";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-sat .path1:before {
  content: "\e9df";
  color: rgb(71, 131, 255);
}
.icon-icon-sat .path2:before {
  content: "\e9e0";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-icon-sat .path3:before {
  content: "\e9e1";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-sat .path4:before {
  content: "\e9e2";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-sat .path5:before {
  content: "\e9e3";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-step .path1:before {
  content: "\e9e4";
  color: rgb(71, 131, 255);
}
.icon-icon-step .path2:before {
  content: "\e9e5";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-icon-step .path3:before {
  content: "\e9e6";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-step .path4:before {
  content: "\e9e7";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-step .path5:before {
  content: "\e9e8";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-icon-step .path6:before {
  content: "\e9e9";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-folder-arrow-up:before {
  content: "\e9ce";
}
.icon-check-circle .path1:before {
  content: "\e9cc";
  color: rgb(31, 218, 139);
}
.icon-check-circle .path2:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(230, 255, 245);
}
.icon-virtual-background:before {
  content: "\e9cb";
}
.icon-clock .path1:before {
  content: "\e9c9";
  color: rgb(31, 218, 139);
}
.icon-clock .path2:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-salesforce .path1:before {
  content: "\e9c6";
  color: rgb(0, 161, 224);
}
.icon-salesforce .path2:before {
  content: "\e9c7";
  margin-left: -1.4287109375em;
  color: rgb(255, 255, 255);
}
.icon-salesforce .path3:before {
  content: "\e9c8";
  margin-left: -1.4287109375em;
  color: rgb(255, 255, 255);
}
.icon-refresh:before {
  content: "\e9c3";
}
.icon-spinner:before {
  content: "\e9c4";
}
.icon-white-error .path1:before {
  content: "\e9c1";
  color: rgb(255, 255, 255);
}
.icon-white-error .path2:before {
  content: "\e9c2";
  margin-left: -1.0419921875em;
  color: rgb(244, 74, 74);
}
.icon-given-control:before {
  content: "\e9c0";
}
.icon-share-screen:before {
  content: "\e9be";
}
.icon-share-website:before {
  content: "\e9bf";
}
.icon-close-bar:before {
  content: "\e9bc";
}
.icon-setting-text:before {
  content: "\e9bd";
  color: #4783ff;
}
.icon-zoom .path1:before {
  content: "\e9b9";
  color: rgb(33, 150, 243);
}
.icon-zoom .path2:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-zoom .path3:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-download:before {
  content: "\e9b8";
}
.icon-video-message:before {
  content: "\e9b3";
  color: #fff;
}
.icon-no-preview .path1:before {
  content: "\e9b0";
  color: rgb(65, 65, 65);
}
.icon-no-preview .path2:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-no-preview .path3:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-convert:before {
  content: "\e9b2";
  color: #fff;
}
.icon-hubspot:before {
  content: "\e9ae";
  color: #ff7a59;
}
.icon-dynamics365:before {
  content: "\e9ad";
  color: #002050;
}
.icon-web-link:before {
  content: "\e9ac";
}
.icon-access:before {
  content: "\e9a4";
}
.icon-notify:before {
  content: "\e9a5";
}
.icon-users:before {
  content: "\e9a6";
}
.icon-receipt:before {
  content: "\e9a7";
}
.icon-integration:before {
  content: "\e9a8";
}
.icon-person:before {
  content: "\e9a9";
}
.icon-duplicate:before {
  content: "\e9a3";
}
.icon-insight:before {
  content: "\e9a2";
}
.icon-dashboard:before {
  content: "\e99d";
}
.icon-feedback:before {
  content: "\e99e";
}
.icon-homepage:before {
  content: "\e99f";
}
.icon-logout:before {
  content: "\e9a0";
}
.icon-support:before {
  content: "\e9a1";
}
.icon-share-people:before {
  content: "\e99c";
}
.icon-play-big:before {
  content: "\e994";
}
.icon-star:before {
  content: "\e995";
}
.icon-share:before {
  content: "\e996";
}
.icon-sign:before {
  content: "\e997";
}
.icon-shield:before {
  content: "\e993";
  color: #4783ff;
}
.icon-share-folder:before {
  content: "\e992";
}
.icon-checked .path1:before {
  content: "\e991";
  color: rgb(255, 255, 255);
}
.icon-checked .path2:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(65, 65, 65);
}
.icon-crop-square:before {
  content: "\e990";
}
.icon-send-email:before {
  content: "\e98d";
}
.icon-octagon:before {
  content: "\e98a";
}
.icon-envelop:before {
  content: "\e98c";
}
.icon-bold:before {
  content: "\e987";
}
.icon-italic:before {
  content: "\e988";
}
.icon-underline:before {
  content: "\e989";
}
.icon-resource-link .path1:before {
  content: "\e976";
  color: rgb(71, 131, 255);
}
.icon-resource-link .path2:before {
  content: "\e977";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-show:before {
  content: "\e986";
}
.icon-file-plus:before {
  content: "\e960";
}
.icon-folder:before {
  content: "\e984";
}
.icon-folder-plus:before {
  content: "\e985";
}
.icon-design:before {
  content: "\e982";
}
.icon-space-template:before {
  content: "\e983";
}
.icon-calendar:before {
  content: "\e979";
}
.icon-chevron-down:before {
  content: "\e97a";
}
.icon-chevron-up:before {
  content: "\e97b";
}
.icon-delete:before {
  content: "\e97c";
}
.icon-pin-off:before {
  content: "\e97d";
}
.icon-pin:before {
  content: "\e97e";
}
.icon-todolist:before {
  content: "\e97f";
}
.icon-update:before {
  content: "\e980";
}
.icon-urgent:before {
  content: "\e981";
}
.icon-resource-all:before {
  content: "\e978";
}
.icon-correct .path1:before {
  content: "\e973";
  color: rgb(255, 255, 255);
}
.icon-correct .path2:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(31, 218, 139);
}
.icon-error:before {
  content: "\e975";
  color: #f44a4a;
}
.icon-paragraph-right:before {
  content: "\e966";
}
.icon-paragraph-left:before {
  content: "\e967";
}
.icon-paragraph-center:before {
  content: "\e968";
}
.icon-align-center:before {
  content: "\e969";
}
.icon-move-up:before {
  content: "\e96a";
}
.icon-move-down:before {
  content: "\e96b";
}
.icon-image-text-overlay:before {
  content: "\e96c";
}
.icon-align-top:before {
  content: "\e96d";
}
.icon-align-bottom:before {
  content: "\e96e";
}
.icon-carousel:before {
  content: "\e96f";
}
.icon-hero:before {
  content: "\e970";
}
.icon-image-text:before {
  content: "\e971";
}
.icon-text:before {
  content: "\e972";
}
.icon-widget:before {
  content: "\e965";
}
.icon-close:before {
  content: "\e964";
}
.icon-outlook-dark:before {
  content: "\e961";
}
.icon-google-dark:before {
  content: "\e962";
}
.icon-apple:before {
  content: "\e963";
}
.icon-word .path1:before {
  content: "\e954";
  color: rgb(71, 131, 255);
}
.icon-word .path2:before {
  content: "\e955";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-pdf .path1:before {
  content: "\e956";
  color: rgb(244, 74, 74);
}
.icon-pdf .path2:before {
  content: "\e957";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-picture .path1:before {
  content: "\e958";
  color: rgb(255, 255, 255);
}
.icon-picture .path2:before {
  content: "\e959";
  margin-left: -1.25em;
  color: rgb(173, 45, 225);
}
.icon-video .path1:before {
  content: "\e95a";
  color: rgb(255, 255, 255);
}
.icon-video .path2:before {
  content: "\e95b";
  margin-left: -1.25em;
  color: rgb(71, 131, 255);
}
.icon-powerpoint .path1:before {
  content: "\e95c";
  color: rgb(173, 45, 225);
}
.icon-powerpoint .path2:before {
  content: "\e95d";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-excel .path1:before {
  content: "\e95e";
  color: rgb(31, 218, 139);
}
.icon-excel .path2:before {
  content: "\e95f";
  margin-left: -1.25em;
  color: rgb(255, 255, 255);
}
.icon-attachment:before {
  content: "\e951";
}
.icon-send:before {
  content: "\e952";
}
.icon-search:before {
  content: "\e953";
}
.icon-expand:before {
  content: "\e950";
}
.icon-message:before {
  content: "\e94f";
  color: #00cd80;
}
.icon-upload:before {
  content: "\e94e";
}
.icon-chart-with-bg .path1:before {
  content: "\e94c";
  color: rgb(65, 65, 65);
}
.icon-chart-with-bg .path2:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(31, 218, 139);
}
.icon-recording .path1:before {
  content: "\e94a";
  color: rgb(65, 65, 65);
}
.icon-recording .path2:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(244, 74, 74);
}
.icon-chart:before {
  content: "\e949";
  color: #1fda8b;
}
.icon-info:before {
  content: "\e948";
}
.icon-menu:before {
  content: "\e947";
}
.icon-chevron-right:before {
  content: "\e945";
}
.icon-chevron-left:before {
  content: "\e946";
}
.icon-image:before {
  content: "\e944";
}
.icon-speaker-off:before {
  content: "\e943";
}
.icon-mic-off:before {
  content: "\e941";
}
.icon-video-off:before {
  content: "\e942";
}
.icon-video-on:before {
  content: "\e93e";
}
.icon-speaker-on:before {
  content: "\e93f";
}
.icon-mic-on:before {
  content: "\e940";
}
.icon-door:before {
  content: "\e93d";
}
.icon-hand:before {
  content: "\e93b";
}
.icon-screen:before {
  content: "\e93a";
}
.icon-cup:before {
  content: "\e939";
}
.icon-todo2:before {
  content: "\e937";
}
.icon-emails-settings:before {
  content: "\e933";
}
.icon-invite-participant:before {
  content: "\e935";
}
.icon-pen:before {
  content: "\e931";
}
.icon-filter:before {
  content: "\e92c";
}
.icon-phone:before {
  content: "\e926";
}
.icon-participant-invite:before {
  content: "\e915";
}
.icon-participants:before {
  content: "\e916";
}
.icon-remove-from-stage:before {
  content: "\e917";
}
.icon-resources:before {
  content: "\e918";
}
.icon-screen-off:before {
  content: "\e919";
}
.icon-screen-on:before {
  content: "\e91a";
}
.icon-settings:before {
  content: "\e91b";
}
.icon-todo:before {
  content: "\e91c";
}
.icon-upload-users:before {
  content: "\e91d";
}
.icon-invite-to-stage:before {
  content: "\e91e";
}
.icon-agenda:before {
  content: "\e91f";
}
.icon-ask-for-feedback:before {
  content: "\e920";
}
.icon-audio-off:before {
  content: "\e921";
}
.icon-audio-on:before {
  content: "\e922";
}
.icon-camera-off:before {
  content: "\e923";
}
.icon-camera-on:before {
  content: "\e924";
}
.icon-chat:before {
  content: "\e925";
}
.icon-edit:before {
  content: "\e927";
}
.icon-email-settings:before {
  content: "\e928";
}
.icon-hand-off:before {
  content: "\e929";
}
.icon-hand-on:before {
  content: "\e92a";
}
.icon-agenda-fill:before {
  content: "\e92b";
}
.icon-hand-off-fill:before {
  content: "\e92d";
}
.icon-mic-off-fill:before {
  content: "\e92e";
}
.icon-screen-off-fill:before {
  content: "\e92f";
}
.icon-camera-off-fill:before {
  content: "\e930";
}
.icon-chat-fill:before {
  content: "\e932";
}
.icon-participants-fill:before {
  content: "\e934";
}
.icon-resources-fill:before {
  content: "\e936";
}
.icon-todo-fill:before {
  content: "\e938";
}
.icon-spce-logo:before {
  content: "\e914";
}
.icon-video-indexer:before {
  content: "\e913";
}
.icon-calendar-clock:before {
  content: "\e910";
}
.icon-grid-squares:before {
  content: "\e911";
}
.icon-camera-circle:before {
  content: "\e912";
}
.icon-broadcast:before {
  content: "\e90d";
}
.icon-user:before {
  content: "\e90e";
}
.icon-play:before {
  content: "\e90f";
}
.icon-square-check-dualtone .path1:before {
  content: "\e908";
  color: rgb(22, 73, 245);
}
.icon-square-check-dualtone .path2:before {
  content: "\e90b";
  margin-left: -0.875em;
  color: rgb(255, 255, 255);
  opacity: 0.7;
}
.icon-square-check-dualtone .path3:before {
  content: "\e90c";
  margin-left: -0.875em;
  color: rgb(255, 255, 255);
}
.icon-square-dualtone .path1:before {
  content: "\e909";
  color: rgb(68, 68, 68);
  opacity: 0.5;
}
.icon-square-dualtone .path2:before {
  content: "\e90a";
  margin-left: -0.875em;
  color: rgb(68, 68, 68);
}
.icon-studio .path1:before {
  content: "\e904";
  color: rgb(68, 68, 68);
  opacity: 0.5;
}
.icon-studio .path2:before {
  content: "\e907";
  margin-left: -1.125em;
  color: rgb(68, 68, 68);
}
.icon-hotel .path1:before {
  content: "\e905";
  color: rgb(68, 68, 68);
  opacity: 0.6;
}
.icon-hotel .path2:before {
  content: "\e906";
  margin-left: -1.125em;
  color: rgb(68, 68, 68);
}
.icon-map-marked-alt .path1:before {
  content: "\e902";
  color: rgb(68, 68, 68);
  opacity: 0.5;
}
.icon-map-marked-alt .path2:before {
  content: "\e903";
  margin-left: -1.125em;
  color: rgb(68, 68, 68);
}
.icon-radiobuttonchecked .path1:before {
  content: "\e900";
  color: rgb(255, 255, 255);
}
.icon-radiobuttonchecked .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(68, 68, 68);
}
.icon-subscription-error:before {
  content: "\e93c";
}
.icon-slack .path1:before {
  content: "\e98b";
  color: rgb(224, 30, 90);
}
.icon-slack .path2:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(54, 197, 240);
}
.icon-slack .path3:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(46, 182, 125);
}
.icon-slack .path4:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(236, 178, 46);
}
.icon-Scrive-dark:before {
  content: "\e98e";
}
.icon-Scrive-light:before {
  content: "\e98f";
}
.icon-single-chat:before {
  content: "\e9aa";
}
.icon-calender-fill:before {
  content: "\e9ab";
}
.icon-no-internet .path1:before {
  content: "\e9af";
  color: rgb(236, 238, 239);
}
.icon-no-internet .path2:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.12;
}
.icon-no-internet .path3:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.12;
}
.icon-no-internet .path4:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.12;
}
.icon-grid:before {
  content: "\e9c5";
}
.icon-move-area:before {
  content: "\ea76";
}
.icon-video-message-2:before {
  content: "\ea75";
  color: #1fda8b;
}
.icon-grid-1:before {
  content: "\ea80";
}
