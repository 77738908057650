.script-preview {
  // padding: 50px 0;

  .script-content {
    display: flex;
    justify-content: center;

    .iframe-temp {
      // flex: 1;
      border: none;
    }
  }

  .script-preview-message {
    font-family: 'MediumLLWeb-Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #dbe1e3;
    border-radius: 4px;
    padding: 8px 12px;
  }

  .script-preview-hint {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    color: rgba(0, 0, 0, 0.6);
  }

  // Default style for Salesforce script
  .salesforce-default-style {
    display: flex;
    flex-direction: column;
    align-items: center;
    form {
      display: flex;
      flex-direction: column;
      gap: 4px;
      width: 50%;
      max-width: 400px;
    }

    input[type='text'] {
      border: 1px solid #dbe1e3;
      border-radius: 4px;
      height: 40px;
    }

    input[type='submit'] {
      width: 100px;
      margin-left: auto;
      margin-right: auto;
      height: 40px;
      background: #000;
      border-radius: 100px;
      color: #fff;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
    }
  }
}
