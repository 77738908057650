/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The font file(s) should be placed relative to the regular CSS file.
 *
 */

/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 *
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media.
 *
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * spce.com (monthly pageviews: <50K)
 *
 */

/* subset */

@font-face {
  font-family: 'MediumLLSub-RegularSubset';
  src: url('MediumLLSub-RegularSubset.woff2') format('woff2');
}

@font-face {
  font-family: 'MediumLLSub-RegularSubset';
  src: url('MediumLLSub-RegularSubset.woff') format('woff');
}

@font-face {
  font-family: 'MediumLLSub-BoldSubset';
  src: url('MediumLLSub-BoldSubset.woff2') format('woff2');
}

@font-face {
  font-family: 'MediumLLSub-BoldSubset';
  src: url('MediumLLSub-BoldSubset.woff') format('woff');
}

/* complete */

@font-face {
  font-family: 'MediumLLWeb-Regular';
  src: url('MediumLLWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MediumLLWeb-Regular';
  src: url('MediumLLWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'MediumLLWeb-Bold';
  src: url('MediumLLWeb-Bold.woff') format('woff');
}

@font-face {
  font-family: 'MediumLLWeb-Bold';
  src: url('MediumLLWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'F37Judge-MediumCondensed';
  src: url('F37Judge-MediumCondensed.woff2') format('woff2');
}
