.participant-level-wrapper .participant-level-select {
  .MuiSelect-root.MuiOutlinedInput-input {
    padding: 0;

    .selected-value {
      display: flex;
      align-items: center;
      padding: 10px 16px;
    }
  }
}

.access-level-select-popover.picker .MuiMenuItem-root {
  p {
    width: 100%;
    padding: 0;
  }
}