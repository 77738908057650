.users-list {
  display: flex;
  flex: 1;
  margin-top: 20px;
  flex-direction: column;
  overflow: auto;

  .item {
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 40px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }

    .avatar {
      display: flex;
      flex: 0 1;
      justify-content: center;
      align-items: center;

      > span {
        width: 40px;
        height: 40px;
        background-color: #000;
        color: #fff;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'F37Judge-MediumCondensed';
        font-size: 20px;
        margin-right: 10px;
      }
    }

    .info {
      display: flex;
      flex: 1;
      flex-direction: column;

      > div {
        display: flex;
        flex-grow: 1;
        align-items: center;
        line-height: 18px;
        gap: 8px;

        > .fullname {
          font-family: 'MediumLLWeb-Regular';
        }

        > .email {
          opacity: 0.6;
          font-size: 13px;
          line-height: 16px;
        }

        .role {
          background-color: #dbe1e3;
          padding-left: 5px;
          padding-right: 5px;
          font-size: 8.5px;
          font-weight: normal;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #000;
        }
      }
    }
  }
}
