@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.cms-todo-widget {
  display: flex;
  flex-direction: column;

  .cms-widget-header {
    .right-section {
      .MuiIconButton-root {
        padding: 0;
      }

      .icon-filter,
      .icon-expand {
        font-size: 16.5px;
        color: #000;
      }
    }
  }

  .cms-widget-items {
    gap: 8px;
    padding: 0px 24px;

    .cms-widget-item {
      border-radius: 4px;
      max-height: 80px;
      // &.opened {
      //   border-left: 2px solid #76a2fe;
      // }

      &:hover {
        background-color: #f4f7f8;

        .right-section {
          .MuiButtonBase-root {
            opacity: 1;
          }
        }
      }

      .middle-section {
        .todo-title-section {
          width: 100%;
        }

        .item-name {
          font-weight: 400;
          font-family: 'MediumLLWeb-Regular';
          font-weight: 400;
        }

        .item-email {
          font-size: 13px;
          font-size: 13px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.4);
          mix-blend-mode: normal;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
        }

        .todo-categories {
          margin-top: 6px;
        }

        .tag {
          background: #76a2fe;
          border-radius: 2px;
          font-weight: bold;
          font-size: 8.5px;
          line-height: 12px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: $primary-color;
          padding: 2px 4px;
          margin-left: 8px;
          white-space: nowrap;

          &.done {
            background: #eeede7;
          }
        }
      }
    }
  }

  .checklist-form {
    background-color: #fff;

    .checklist-form-body {
      margin-top: 0;

      .form-fields {
        .MuiInputBase-root {
          color: #000;
          background-color: #fff;
          border: 1px solid #dbe1e3;
          padding: 10px 12px !important;

          input {
            padding: 0 !important;
          }
        }

        .MuiInput-underline {
          &:hover {
            border: 1px solid #dbe1e3 !important;
          }
        }

        .checklist-item-label {
          margin-top: 24px;
        }

        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.54);
        }

        .checklist-checkbox {
          margin-top: 24px;
          margin-left: 0;
          padding-left: 0;

          .MuiFormControlLabel-label {
            color: #000;
            margin-left: 12px;
          }
        }

        .MuiSelect-select {
          padding: 0 !important;

          &:focus {
            background-color: #fff;
          }
        }
      }
    }
  }

  .text-center {
    flex: 1;
    padding: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
}

@include media(phone) {
  .cms-todo-widget .cms-widget-items .cms-widget-item .right-section .MuiButtonBase-root {
    opacity: 0.6;
  }
}