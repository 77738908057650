@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.join-meeting-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #17171a;
    opacity: 0.4;
  }

  &.fullscreen {
    .MuiDialog-paper {
      max-width: unset;
      max-height: unset;
    }
  }

  .content {
    position: relative;
    min-height: 100px;
    padding: 24px 24px 80px 24px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    z-index: 1;
    color: #000;
    max-width: 500px;
    width: 500px;

    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    .MuiButton-containedSizeSmall {
      .MuiButton-label {
        font-size: 14px;
        font-weight: bold;
      }
    }

    .MuiOutlinedInput-root {
      color: #000;
    }

    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
      border-color: rgba(0, 0, 0, 0.23);
    }

    .MuiFormHelperText-root.Mui-error {
      margin-left: 0;
      color: #F44A4A;
      font-size: 13px;
    }

    .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: #000;
    }

    .message {
      text-align: center;
      z-index: 1;
      font-size: 15px;
      line-height: 24px;

      .title {
        text-transform: uppercase;
        font-family: MediumLLWeb-Bold;
        font-size: 18px;
        line-height: 24px;
        display: block;
        margin-bottom: 4px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 452px;
      }

      .sub-title {
        opacity: 0.6;
      }
    }

    .background {
      display: flex;
      width: 100%;
      min-height: 200px;
      margin-bottom: 20px;
      max-height: 50vh;
    }

    .logo-image {
      width: 100px;
      margin: auto;
      margin-bottom: 10px;
    }

    .description {
      max-width: 452px;
      margin: auto;
      text-align: center;

      >a {
        color: $primary-color;
      }
    }

    >span {
      text-align: center;
      margin-bottom: 20px;
      padding-left: 40px;
      padding-right: 40px;
    }

    .terms-and-condition {
      text-align: left;
      max-width: 452px;
      margin-top: 24px;
      margin-bottom: 32px;
      font-size: 13px;
      line-height: 16px;

      .terms-label {
        min-height: 24px;
      }

      .policy {

        .customer-terms {
          width: 100%;
          margin: 0;
          margin-bottom: 8px;
        }

        .terms {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          margin-top: -3px;
          color: rgba(0, 0, 0, 0.60);
        }

        .error {
          color: #F44A4A;
          font-size: 13px;
          margin-top: 8px;
          line-height: 16px;
        }
      }

      .privacy-policy {
        margin-top: 24px;

        text-align: center;
      }
    }

    .button-wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .button-join {
        text-align: center;
        background-color: #000;
        padding: 25px;
        border-radius: 0 0 4px 4px;

        .MuiButton-label {
          font-family: F37Judge-MediumCondensed;
          font-size: 30px;
          line-height: 30px;
          font-weight: normal;
        }
      }
    }

    .form {
      display: flex;
      gap: 16px;
      justify-content: space-between;
      max-width: 452px;
      margin: auto;
      margin-top: 24px;
      width: 100%;

      &.fullwidth {
        >.MuiFormControl-fullWidth {
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .join-meeting-dialog {
    .MuiDialog-paper {
      width: 100%;
      max-height: 100%;
    }

    .MuiButton-fullWidth {
      width: 100%;
    }

    .dialog-content {
      .close-button {
        right: 10px;
        top: 10px;
      }

      .content {
        padding: 20px;
        width: 100%;
      }
    }
  }
}

@include media(phone) {
  .join-meeting-dialog {
    .mobile-join-meeting-box {
      margin: 0 16px;
      border-radius: 4px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
      background-color: rgba(65, 65, 65, 0.8);
      backdrop-filter: blur(10px);
      width: calc(100% - 32px);

      .content {
        padding-left: 24px;
        padding-right: 24px;
        width: 100%;

        .message {
          font-size: 18px;
          margin-bottom: 10px;

          span {
            font-size: 15px;
            line-height: 20px;
            opacity: 0.6;
          }
        }

        .MuiOutlinedInput-root {
          background-color: #fff;
        }

        .MuiOutlinedInput-inputMarginDense {
          color: #000000;
        }

        .Mui-focused {
          border: 1px solid #000000;
        }

        .button {
          margin-top: 10px;
        }

        .form {
          margin-top: 15px;

          >.MuiFormControl-fullWidth {
            width: 48%;
          }

          &.fullwidth {
            >.MuiFormControl-fullWidth {
              width: 100%;
            }
          }
        }
      }
    }
  }
}