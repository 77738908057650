@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.cms-web-content-preview {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 0;
  width: 100%;

  &.empty {
    background-color: rgba(0, 0, 0, 0.3);
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    color: $primary-color;

    .info-icon {
      &.icon-new-info {
        .path2 {
          &::before {
            color: #dbe1e3;
          }
        }
      }
    }

    .cms-web-content-preview-content {
      margin: auto;
      display: flex;
      gap: 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
      padding: 16px;

      &.column-1 {
        width: 80%;
        margin: 0 auto;
        padding: 0;
      }

      .icon-section {
        background-color: #dbe1e3;
        padding: 2.5px 7.5px;

        .link-icon {
          color: #fff;
          font-size: 35px;
        }
      }
    }

    .cms-web-content-preview-title {
      font-family: 'MediumLLWeb-Bold';
      letter-spacing: 0.02em;
      // width: 100%;
      font-size: 15px;
      line-height: 24px; /* 160% */

      &.large {
        font-size: 85px;
        line-height: 80px;
      }

      &.medium {
        font-size: 60px;
        line-height: 72px;
      }

      &.small {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .cms-web-content-preview-description {
      font-size: 15px;
      line-height: 24px;
      // padding: 0 15px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      // text-overflow: ellipsis;
      // overflow: hidden;
    }
  }

  .loading-box {
    min-height: 200px;
  }
}

@include media(phone) {
  .cms-web-content-preview {
    &-info {
      .cms-web-content-preview-title {
        &.large {
          font-size: 60px;
          line-height: 72px;
        }

        &.medium {
          font-size: 40px;
          line-height: 48px;
        }

        &.small {
          display: none;
        }
      }
      .cms-web-content-preview-content {
        &.column-2,
        &.column-3 {
          .cms-web-content-preview-title,
          .cms-web-content-preview-description {
            display: none;
          }
        }
      }
    }
  }
}
