.accessible-users {
  display: flex;
  overflow: hidden;
  flex-direction: column;

  .header {
    display: flex;
    flex-direction: row;
    height: 24px;
    width: 100%;
    justify-content: space-between;
    > span {
      &:first-child {
        font-family: 'MediumLLWeb-Bold';
      }

      &:last-child {
        opacity: 0.4;
      }
    }
    .header-left {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      width: 100%;
      &.has-button {
        justify-content: flex-start;
      }
      > span {
        &:first-child {
          font-family: 'MediumLLWeb-Bold';
        }

        &:last-child {
          opacity: 0.4;
        }
      }
    }
  }
}
