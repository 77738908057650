@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.cms-card-preview {
  display: flex;
  height: 100%;

  &.active {
    box-shadow: 0 0 0 2px #ad2de1;
  }

  &.allowHover {
    &:hover {
      box-shadow: 0 0 0 2px #ad2de1;
    }
  }

  .cms-card-preview-info-wrapper {
    display: flex;
    width: 100%;

    &.positionAbsolute {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 3;
      color: $secondary-color;
      color: #fff;
    }
  }

  .cms-card-preview-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    z-index: 3;

    &.column-1 {
      .cms-card-preview-description {
        max-width: 700px;
      }
    }

    &.center {
      align-items: center;
    }

    &.left {
      align-items: flex-start;
    }

    &.right {
      align-items: flex-end;
    }

    &.justify {
      align-items: stretch;
    }

    .cms-card-preview-title {
      font-family: 'F37Judge-MediumCondensed';
      letter-spacing: 0.02em;
      width: 100%;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      max-width: 700px;

      &.large {
        font-size: 85px;
        line-height: 80px;
      }

      &.medium {
        font-size: 60px;
        line-height: 72px;
      }

      &.small {
        font-size: 28px;
        line-height: 32px;
      }

      &.center {
        text-align: center;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.justify {
        text-align: justify;
      }
    }

    .cms-card-preview-description {
      font-size: 15px;
      line-height: 20px;
      width: 100%;
      white-space: pre-wrap;
      overflow-wrap: break-word;

      p {
        margin: 0;
      }

      &.ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
      }

      &.center {
        text-align: center;
      }

      &.left {
        text-align: left;
      }

      &.right {
        text-align: right;
      }

      &.justify {
        text-align: justify;
      }

      ul,
      ol {
        display: flex;
        flex-direction: column;

        li {
          text-align: left;
        }
      }

      &.center ul,
      ol {
        align-items: center;
      }

      &.right ul,
      ol {
        align-items: flex-end;
      }
    }

    .hyperlink {
      color: #4783ff;
    }

    .cms-card-preview-buttons {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;

      .btn-cms {
        text-transform: none;
        max-width: 400px;

        .MuiButton-label {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }

      &.center {
        justify-content: center;
      }
    }

    &.positionAbsolute {
      color: $secondary-color;
      max-width: 98%;

      &.column-1 {
        max-width: 700px;
      }

      &.column-2 {
        max-width: 500px;
      }

      &.topCenter {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;

        &.column-1 {
          margin-top: 80px;
        }
      }

      &.topLeft {
        left: 16px;
        margin-top: 32px;

        &.column-1 {
          margin-left: 80px;
          margin-top: 80px;
        }
      }

      &.topRight {
        right: 16px;
        margin-top: 32px;

        &.column-1 {
          margin-right: 80px;
          margin-top: 80px;
        }
      }

      &.middleLeft {
        top: 50%;
        left: 16px;
        transform: translate(0, -50%);

        &.column-1 {
          left: 80px;
        }
      }

      &.middleCenter {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.middleRight {
        top: 50%;
        right: 16px;
        transform: translate(0, -50%);

        &.column-1 {
          right: 80px;
        }
      }

      &.bottomLeft {
        bottom: 0;
        left: 16px;
        margin-bottom: 32px;

        &.column-1 {
          left: 80px;
          margin-bottom: 80px;
        }
      }

      &.bottomCenter {
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 32px;

        &.column-1 {
          margin-bottom: 80px;
        }
      }

      &.bottomRight {
        bottom: 32px;
        right: 16px;

        &.column-1 {
          bottom: 80px;
          right: 80px;
        }
      }
    }

    &.maxWidth1920 {
      max-width: 1920px;
      margin-left: auto;
      margin-right: auto;
      padding: 0 80px;
    }
  }

  .cms-card-preview-image-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .cms-card-preview-image {
      /* Start: Aspect Ratio */
      width: 100%;
      margin: 0 auto;
      position: relative;
      /* If you want text inside of it */

      >iframe {
        position: absolute;
      }

      &.ratio11 {
        padding-bottom: 100%;
        /* 1:1 Aspect Ratio */
      }

      &.ratio169 {
        padding-bottom: 56.25%;
        /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      }

      &.ratio32 {
        padding-bottom: 66.66%;
        /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6666)  */
      }

      &.ratio23 {
        padding-bottom: 150%;
        /* 2:3 Aspect Ratio (divide 3 by 2 = 1.5)  */
      }

      /* End: Aspect Ratio */

      .cms-card-image {
        width: 100%;
        height: 100%;
        /* Start: Aspect Ratio */
        position: absolute;
        /* End: Aspect Ratio */
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-fit: contain;

        /* Start: Aspect Ratio */
        position: absolute;
        /* End: Aspect Ratio */
      }

      &-overlay {
        // width: 100%;
        // height: 100%;
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  &.column-2,
  &.column-3,
  &.column-4 {
    height: 100%;

    .cms-card-preview-info-wrapper {
      height: 100%;

      .cms-card-preview-buttons {
        margin-top: auto;
      }
    }
  }
}

.cms-card-preview.mobile-mode {
  .cms-card-preview-info {
    .cms-card-preview-title {
      &.large {
        font-size: 60px;
        line-height: 72px;
      }

      &.medium {
        font-size: 40px;
        line-height: 48px;
      }

      &.small {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .cms-card-preview-description {
      max-width: 100%;
      padding: 0;
    }

    .cms-card-preview-buttons {
      .btn-cms {
        margin-bottom: 5px;
        max-width: 100%;
      }
    }
  }
}

@include media(phone) {
  .cms-card-preview {
    &.column-2 {
      .cms-card-preview-image-wrapper {
        .cms-card-preview-image {
          &.paddingB-ratio32 {
            padding-bottom: 72.88%;
          }
        }
      }
    }
  }
}