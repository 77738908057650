@import '../variables.scss';
@import '../responsiveUtils.scss';

.runtime-drawer {
  position: relative;

  .MuiList-padding {
    padding: 0;
  }

  &.MuiDrawer-root {
    width: 0;
    flex-shrink: 0;
    background-color: transparent;

    .MuiDrawer-paper {
      width: 696px;
      top: 62px;
      bottom: 64px;
      left: 8px;
      z-index: 1;
      height: auto;
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    }

    &.drawerLeftOpen {
      width: 696px;
      transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

      .MuiDrawer-paperAnchorDockedLeft {
        border-right: none;
      }
    }

    &.drawerClose {
      width: 0;
      overflow-x: hidden;
      transition: width 195ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  }

  .MuiDrawer-paperAnchorDockedRight {
    border-left: none;
  }

  .runtime-drawer-widgets {
    display: flex;
    overflow: hidden;
    flex: 1;

    .participant-tab {
      flex: 1;
      display: flex;
      overflow: hidden;
    }

    .chat-tab {
      display: flex;
      flex: 1;
      overflow: hidden;

      .chat-main-container {
        .event-chat {
          padding: 0px;
          padding-right: 0;
          padding-top: 0;
        }

        .participant-list-container {
          padding: 20px 25px;
        }

        .chat-container {
          padding: 0;
        }

        .participant-list-container {
          padding: 0;
        }

        .channel-list-container {
          padding: 0;
        }
      }
    }

    .timeline-body {
      background-color: #fff;
    }

    .todo-list-tab {
      flex: 1;
      display: flex;
      overflow: hidden;
    }

    .agenda-widget {
      .timeline-container {
        .timeline-activities {
          flex: 1;
        }
      }
    }

    .breakout-rooms-container {
      .activity-breakout-rooms {
        // padding: 20px;
        overflow: hidden;
        position: relative;
      }
    }
  }

  .MuiListItem-root {
    justify-content: center;
    padding: 11px 0;

    .inactive {
      svg {
        color: #969595;
      }
    }

    .event-messages {
      background-color: #cd3636;
      position: absolute;
      right: 19px;
      top: 11px;
      width: 6px;
      height: 6px;
      border-radius: 20px;
    }

    .new-messages {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 11px;
      top: 8px;

      span {
        background-color: #cd3636;
        color: #fff;
        font-size: 8px;
        padding: 1px 5px;
        border-radius: 10px;
        font-weight: bold;
      }
    }
  }

  .MuiListItemIcon-root {
    justify-content: center;
  }

  .horizontal-avatars {
    width: 40px;
    display: flex;

    .avatar-container {
      margin-right: -5px;
    }
  }

  .vertical-avatars {
    display: flex;
  }
}

@include media(phone) {
  .runtime-drawer {
    &.MuiDrawer-root {
      .MuiDrawer-paper {
        width: 100%;
      }

      &.drawerLeftOpen {
        width: 100%;
      }
    }

    .runtime-drawer-widgets {
      .agenda-widget .timeline-container .timeline-activities {
        padding: 15px;
        padding-bottom: 0;
      }

      .chat-tab .chat-main-container .event-chat {
        padding-right: 0;

        .comments-container .comments-list {
          padding-right: 15px;
        }
      }
    }
  }
}

@include media(phone, landscape) {
  .landscape {
    .runtime-drawer {
      &.MuiDrawer-root {
        .MuiDrawer-paper {
          padding-bottom: 0;
          left: 8px;
          bottom: 64px;
          top: 72px;
        }
      }
    }
  }
}