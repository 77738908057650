@import '../../variables.scss';

.categories-field-container {
  .categories-autocomplete {
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0px 12px !important;

      #categories-input {
        padding: 0 !important;
        height: 40px;
      }
    }
  }

  .MuiChip-root {
    background-color: #ccddff;
    height: auto;
    margin-right: 4px;
  }

  .MuiChip-label {
    text-transform: uppercase;
    font-family: $FMediumBold;
    display: flex;
    line-height: 12px;
    letter-spacing: 0.5px;
    align-items: center;
    padding: 2px 0px 2px 4px;
    font-size: 8.5px;
  }

  .MuiChip-deleteIcon {
    color: #000 !important;
    height: 10px;
    width: 10px;
    margin-left: 4px;
  }

  .categories-maximum {
    font-size: 12px;
  }
}

#categories-input-popup {
  max-height: 240px;
  .add-button {
    color: #000;
    background-color: #fff;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-transform: unset;
    font-family: $FMediumRegular;
    font-size: 15px;
    margin: -16px;
    width: calc(100% + 32px);

    .icon-plus-solid {
      font-size: 10px;
    }
  }
  .categories-field-group {
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
    padding: 8px 12px;
    font-family: $FMediumBold;
    font-size: 8.5px;
    line-height: 12px;
    letter-spacing: 0.5px;
  }

  .MuiAutocomplete-option {
    min-height: 40px;
    opacity: 1;
    padding: 0 16px;

    .options-maximum-container {
      color: #000;
    }

    .options-container {
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      color: #000;

      .options-icons-container {
        display: none;
        gap: 16px;

        .options-icons-edit,
        .options-icons-delete {
          align-items: center;
          display: flex;
          opacity: 0.6;
          color: #000;
        }

        .options-icons-edit:hover,
        .options-icons-delete:hover {
          opacity: 1;
        }

        .MuiSvgIcon-root {
          color: unset;
          width: 19px;
        }
      }

      .MuiChip-root {
        background-color: #ccddff;
        height: 16px;
        color: #000;

        .MuiChip-label {
          text-transform: uppercase;
          font-family: $FMediumBold;
          display: flex;
          line-height: 12px;
          letter-spacing: 0.5px;
          align-items: center;
          padding: 2px 4px;
          font-size: 8.5px;
        }
      }
    }
  }

  .MuiAutocomplete-option[data-focus='true'] {
    background-color: #f4f7f8 !important;
    .options-container .options-icons-container {
      display: flex;
    }
  }

  .MuiAutocomplete-option[aria-selected='true'] {
    background-color: #fff;
  }
}
