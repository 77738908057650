.icon-folder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 18px;

  &::after {
    font-family: 'MediumLLWeb-Bold';
    font-size: 8px;
    position: absolute;
    padding-top: 2px;
  }

  &.white-bg,
  &.black-bg {
    &:before {
      color: #dbe1e3;
    }

    &::after {
      color: #000;
    }
  }

  &.lv1 {
    &::after {
      content: '1';
    }
  }

  &.lv2 {
    &::after {
      content: '2';
    }
  }

  &.lv3 {
    &::after {
      content: '3';
    }
  }
}

.icon-accesslevel {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &::before {
    content: '\e98a';
    font-size: 18px;
    color: #DBE1E3;
  }

  &::after {
    font-family: 'MediumLLWeb-Bold';
    font-size: 10px;
    position: absolute;
    line-height: 2px;
    color: #000;
  }

  &.lv1 {
    &::after {
      content: '1';
    }
  }

  &.lv2 {
    &::after {
      content: '2';
    }
  }

  &.lv3 {
    &::after {
      content: '3';
    }
  }

  &.gray-bg {
    &:before {
      color: #dbe1e3;
    }

    &::after {
      color: #000;
    }
  }

  &.dark-grey-bg {
    &:before {
      color: rgba(0, 0, 0, 0.6);
    }

    &::after {
      color: #fff;
    }
  }
}

.onedrive-gray {
  filter: grayscale(1) brightness(1.5);
  // span[class^='path']::before{
  //   color: rgba($color: lightgray, $alpha: 1);
  // }
}

.google-drive-gray {
  filter: grayscale(1) brightness(1);
}

.icon-move::before {
  color: unset;
}

.icon-door {
  &.green {
    color: #1fda8b;
  }

  &.red {
    color: #ff7f7f;
  }

  &.blue {
    color: #4783ff;
  }
}

.icon-video .path2:before {
  color: #1fda8b;
}