.reminder-notistack-content-wrapper {
  .reminder-notistack-content {
    display: flex;
    padding: 12px 24px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    width: 360px;

    .left-section {
      margin-right: 24px;

      .gravatar {
        position: relative;

        .MuiAvatar-root {
          background-color: #000;
          color: #fff;

          .icon-new-zoom {
            font-size: 26px;
          }
          .teams-logo {
            width: 25px;
          }
        }

        .notify-icon {
          width: 20px;
          height: 20px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          font-size: 9px;
          color: #fff;
          position: absolute;
          bottom: -8px;
          right: -8px;

          &.icon-clock {
            .path2::before {
              margin-left: -9px;
              font-size: 20px;
            }
          }
        }
      }
    }

    .center-section {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .top-section {
        .title {
          span {
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
          }
        }
      }

      .center-section {
        .info {
          display: flex;
          gap: 6px;
          color: rgba(0, 0, 0, 0.6);

          .blue-text {
            color: #4783ff;
          }
        }
      }

      .bottom-section {
        margin-top: 4px;

        .action-buttons {
          display: flex;
          gap: 12px;

          .action-button {
            border-radius: 100px;
            padding: 4px 8px;
            font-family: 'MediumLLWeb-Bold';
            font-size: 10px;
            line-height: 16px;
            background-color: #f4f7f8;
            color: rgba(0, 0, 0, 0.6);
            box-shadow: none;

            &.green {
              background-color: #1fda8b;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
