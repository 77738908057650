@import '../../responsiveUtils.scss';

.notify-card {
  padding: 12px 24px;
  display: flex;
  gap: 24px;
  cursor: pointer;
  min-height: 85px;

  .content {
    flex: 1 1;
    width: 100%;
    overflow: hidden;
    > div {
      span {
        word-break: break-word;
        font-family: 'MediumLLWeb-Bold';
      }
    }

    .blue-text {
      color: #4783ff;
    }

    .content-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        font-family: 'MediumLLWeb-Regular';
      }
    }

    .notify-dot-blue {
      width: 6px;
      height: 6px;
      display: inline-block;
      background: #4783ff;
      border-radius: 100%;
    }

    .btn-join {
      border-radius: 100px;
      background-color: #1fda8b;
      padding: 4px 8px;
      font-weight: 700;
      font-size: 10px;
      line-height: 16px;
    }
  }

  .notify-card-left {
    position: relative;

    .notify-icon {
      width: 20px;
      height: 20px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 9px;
      color: #fff;
      position: absolute;
      bottom: -5px;
      right: -8px;

      &.icon-clock {
        .path2::before {
          margin-left: -9px;
          font-size: 20px;
        }
      }
    }
  }

  &.active {
    background: rgba(118, 162, 254, 0.1);
  }
}
