.googledrive-auth-error {
  display: flex;
  flex-direction: row;
  .googledrive-auth-error-content {
    margin-right: 16px;
  }
  .googledrive-auth-error-actions {
    padding: 4px;
  }

  .regular-rounded-button {
    border-radius: 100px;
    padding: 8px 16px;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
