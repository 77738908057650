@import '../variables.scss';
@import '../responsiveUtils.scss';

.lock {
  background-color: #000;
}

.login-menu {
  .customized-menu-wrapper {
    padding: 0 0px;
    width: 240px;
    max-width: 300px;

    .customized-menu-body {
      max-height: unset;

      .MuiList-padding {
        padding-top: 4px;
      }
    }

    .customized-menu-list {
      .MuiList-root {
        padding: 10px 16px;
      }

      .divider {
        height: 1px;
        background-color: #eceeef;
        width: 100%;
        margin-bottom: 4px;
      }

      .MuiListItemIcon-root {
        .menu-item-icon {
          font-size: 15px;
          padding-right: 12px;
          color: black;
        }
      }

      .MuiListItem-button {
        padding: 6px 16px;
        letter-spacing: normal;

        .icon-folder-arrow-up {
          font-size: 16px;
        }
      }

      .MuiSvgIcon-root {
        font-size: 8px;
      }

      .MuiListItem-root .MuiListItemText-root .MuiListItemText-primary {
        font-size: 15px;
        line-height: 24px;
      }

      .MuiListItemText-root {
        &+.MuiSvgIcon-root {
          font-size: 17px;
          opacity: 1;
          z-index: 9;
          pointer-events: visible;
        }
      }

      .MuiListItem-divider {
        border-bottom: none;
        margin-bottom: 4px;
      }
    }
  }

  .login-menu-header {
    padding: 16px;
    position: relative;
    border-bottom: 1px solid #f4f6f8;

    .title {
      font-family: $FMediumBold;
      padding: 0;
      margin: 10px 0 10px;
      text-transform: uppercase;
      font-size: 8.5px;
    }

    .bold {
      font-family: $FMediumBold !important;
    }

    .capitalize {
      text-transform: capitalize;
    }

    .description {
      margin: 0;
      font-family: $FMediumRegular;
      font-size: 12px;
      line-height: 24px;
      &.bold {
        font-size: 15px;
      }
    }

    .name {
      font-size: 18px;
      font-family: $FMediumBold;
      line-height: 24px;
      margin: 8px 0 24px 0;
    }

    .tag {
      background: #1fda8b;
      padding: 2px 6px;
      font-size: 8.5px;
      border-radius: 2px;
      color: #fff;
      font-weight: bold;
      text-transform: uppercase;

      &.freemium {
        background-color: #1fda8b;
      }

      &.premium {
        background-color: #76a2fe;
      }

      &.enterprise {
        background-color: rgb(38, 38, 38);
      }
    }
  }

  .login-menu-footer {
    text-transform: uppercase;
    font-family: $FMediumBold;
    color: $secondary-color;
    height: 40px;
    font-size: 12px;
    line-height: 16px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;

    &.freemium {
      background-color: #76a2fe;
    }

    &.premium {
      background-color: #000;
    }
  }
  &.login-menu-mobile {
    .MuiPaper-root {
      height: 100%;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    .MuiPopover-paper {
      top: 0 !important;
      left: 0 !important;
    }

    .customized-menu-wrapper {
      width: 100%;
      max-width: 100%;

      .login-menu-header {

        .close-button {
          position: absolute;
          top: 12px;
          right: 12px;
        }
      }

      .customized-menu-list {
        .MuiListItem-button {
          padding: 8px 24px;
        }
      }

      .customized-menu-footer {
        width: 100%;
        position: absolute;
        bottom: 0;
      }
    }
  }
}
