
@import '../variables.scss';
@import '../responsiveUtils.scss';

.space-footer {
  background-color: #fff;
  z-index: 100;


  &.cms {
    border-top: 1px solid #eceeef;
  }
  &.mobile-mode {
    .runtime-footer-left {
      justify-content: center;
    }
  }

  .square-button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    &:hover {
      background-color: #e5e5e5;
    }

    &.active {
      background-color: $primary-color;
      svg,
      i {
        color: $secondary-color;
      }
    }

    svg,
    i {
      color: $primary-color;
      font-size: 20px;
    }
  }

  .runtime-footer-left {
    display: flex;
    align-items: center;
    z-index: 1;
    gap: 16px;

    .chat-button {
      position: relative;

      .new-message {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f44a4a;
        border-radius: 10px;
        position: absolute;
        top: 3px;
        left: 27px;
        padding: 8px;
        width: 16px;
        height: 16px;
      }

      .new-message span {
        color: #fff;
        font-size: 10px;
        font-family: 'Roboto';
      }
    }
  }

  .runtime-footer-center {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .MuiBox-root {
      margin: 0 5px;
    }
  }

  &.position-bottom {
    padding: 8px 24px;
  }

  &.position-left {
    position: fixed;
    left: 8px;
    top: calc(50% + 55px /2);
    border-radius: 100px;
    transform: translateY(-50%);
    display: inline-flex;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    .runtime-footer-left {
      flex-direction: column;
      padding: 8px;
      gap: 8px;
      .square-button {
        padding: 8px;
        border-radius: 50%;
      }
    }
  }
}