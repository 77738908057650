@import '../variables.scss';

.global-resource-preview-pane {
  font-size: 13px;
  line-height: 16px;
  // min-width: 320px;
  overflow-y: auto;
  height: 100%;

  .bold {
    font-family: $FMediumBold;
  }

  .preview-pane-title {
    font-size: 15px;
    line-height: 24px;
    font-family: $FMediumBold;
  }

  .icon-folder {
    font-size: 80px;
    color: #76a2fe;
    margin: 46px 0;
  }

  .previewer {
    position: relative;
    height: 200px;
    margin: 24px 0;

    .image-viewer {
      position: static;
      height: 100%;
      width: 100%;
      object-fit: scale-down;
      background-color: #f5f7f8;
    }

    .download-preview {
      min-height: 200px;
      background-color: #f5f7f8;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .pdf-previewer {
      overflow: hidden;
      height: 100%;
    }

    .audio-viewer {
      background-color: #dbe1e3;
      justify-content: center;

      .audio {
        margin-top: unset;

        audio {
          width: 80%;
        }
      }
    }
  }

  .field-name {
    font-family: $FMediumBold;

    &.nowrap {
      white-space: nowrap;
    }
  }

  .divider-container {
    margin: 10px 0;
  }
}

.small-size-tooltip {
  font-size: 13px !important;
  white-space: nowrap;
}
