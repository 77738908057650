.google-drive-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .no-data {
    margin: auto;
  }

  .root-location {
    font-size: 12px;
  }
  .resource-search-field {
    width: 100% !important;
  }
  .resource-item-thumbnail {
    display: flex;
    align-items: center;
  }
}
