@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.pop-up-footer {
  text-transform: uppercase;
  font-family: MediumLLWeb-Bold;
  color: $secondary-color;
  height: 40px;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subscription-dialog {
  .close-button {
    position: absolute;
    right: 12px;
    top: 11px;
    opacity: 0.5;
    z-index: 1;
  }

  .dialog-content {
    padding: 0;

    .subscription-info {
      max-width: 500px;
      width: 500px;
      min-height: 200px;

      .body {
        padding: 35px 35px 35px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .header {
          display: flex;
          flex-direction: column;

          .subscription-tag {
            background-color: #1fda8b;
            padding: 2px 8px;
            color: #fff;
            border-radius: 2px;
            align-self: flex-end;
            font-size: 8.5px;
            text-transform: uppercase;

            &.freemium {
              background-color: #1fda8b;
            }

            &.premium {
              background-color: #76a2fe;
            }

            &.enterprise {
              background-color: rgb(38, 38, 38);
            }
          }

          .title {
            color: $primary-color;
            margin: 0;
            padding: 0;
            text-align: center;
            font-size: 28px;
            letter-spacing: 2px;
            font-family: 'F37Judge-MediumCondensed';
          }
        }

        .description {
          text-align: center;
          color: #000;
          font-size: 15px;
          margin-top: 5px;
          font-weight: 400;
          margin-bottom: 20px;
          line-height: 26px;
          font-family: 'MediumLLWeb-Regular';
        }

        .tag {
          padding: 2px 5px;
          margin: 0 4px;
          line-height: 28px;
          font-size: 8.5px;
          border-radius: 2px;
          color: #fff;
          font-family: 'MediumLLWeb-Bold';
          text-transform: uppercase;

          &-premium {
            background: #76a2fe;
          }

          &-enterprise {
            background: #000;
          }
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            font-size: 15px;
            color: $primary-color;
            line-height: 24px;
            padding: 5px;
            display: flex;
            align-items: center;
            text-align: justify;

            .premium {
              font-size: 18px;
              margin-right: 8px;
              padding: 3px;
              border-radius: 100px;
              color: #76a2fe;
              background-color: #e5eeff;
            }
            .enterprise {
              font-size: 18px;
              margin-right: 8px;
              padding: 3px;
              border-radius: 100px;
              color: #000;
              background-color: #eceeef;
            }
          }
        }

        .MuiDivider-root {
          width: 100%;
          margin-top: 28px;
        }

        .zoom-description {
          margin-top: 26px;
          text-align: center;
        }

        .price {
          font-size: 15px;
          margin-top: 20px;
          font-weight: 400;

          b {
            font-size: 20px;
            color: #000;
            font-weight: 700;
          }
        }

        .regular-rounded-button {
          background-color: #000;
          height: 88px;
          border-radius: 0px 0px 0px 0px;
          font-size: 32px;
          width: 100%;
          font-weight: 400;
          font-family: 'F37Judge-MediumCondensed';

          &.premium {
            background-color: #76a2fe;
          }

          &.enterprise {
            background-color: #000;
          }

          &.sent {
            background-color: #f4f7f8;
            color: #1fda8b;

            &.sentpremium {
              background-color: #f4f7f8;
              color: #76a2fe;

              svg {
                font-size: 17px;
                margin-right: 5px;
              }
            }
          }
        }

        p {
          margin: 8px 0 0;
          font-size: 12px;
        }

        .button-container {
          justify-content: center;
          width: 500px;
          margin-top: 40px;
          height: 53px;
        }
      }

      .footer-description {
        font-size: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-family: 'MediumLLWeb-Bold';
        margin-top: 20px;
        opacity: 0.6;

        a {
          font-weight: bold;
          text-decoration: inherit;
        }

        svg {
          font-size: 15px;
          margin-left: 5px;
        }
      }
    }

    .message {
      background-color: #eeede7;
      flex: 1 1;
      justify-content: center;
      align-items: center;
      padding: 18px 40px;
      font-size: 12px;
      text-align: center;
      margin: 0 0 -10px;
      display: flex;

      i {
        &.icon-subscription-error {
          margin-right: 5px;
          font-size: 20px;
          align-self: flex-start;
          margin-top: -3px;
        }
      }

      p {
        margin: 0;
      }
    }

    &.billing-form-container {
      .regular-rounded-button {
        background-color: $primary-color;
      }

      .payment-form {
        margin-top: 20px;
      }
    }
  }
}

.checkout-success-popup {
  position: fixed;
  inset: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  flex-direction: column;
  z-index: 9999;

  .bg {
    background: #000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  h1 {
    z-index: 1;
    font-family: MediumLLWeb-Bold;
    text-transform: uppercase;
    font-size: 24px;
  }
  p {
    z-index: 1;
    font-size: 24px;
    margin-top: 0;
    font-family: MediumLLWeb-Regular;
  }

  button {
    background-color: transparent;
    color: #fff;
    border: 0.5px solid #fff;
    height: 39px;
    width: 135px;

    font-family: MediumLLWeb-Regular;
    text-transform: capitalize;
    font-size: 15px;

    &:hover {
      background-color: #fff;
      color: #000;
    }
  }
}

@include media(phone) {
  .subscription-dialog {
    .MuiDialog-paper {
      margin: 16px;
    }
    .dialog-content .subscription-info {
      width: 100%;
      .message {
        padding-left: 16px;
        p {
          margin: 0 32px;
        }
      }
      .body {
        padding: 24px;
        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            font-size: 13px;
            color: $primary-color;
            line-height: 24px;
            text-align: center;
            padding: 5px;
            display: flex;
            align-items: center;

            .premium {
              font-size: 18px;
              margin-right: 8px;
              padding: 3px;
              border-radius: 100px;
              color: #76a2fe;
              background-color: #e5eeff;
            }
            .enterprise {
              font-size: 18px;
              margin-right: 8px;
              padding: 3px;
              border-radius: 100px;
              color: #1fda8b;
              background-color: #e5eeff;
            }
          }
        }
      }
    }
  }
}
