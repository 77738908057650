.control-box {
  display: flex;
  align-items: center;
  gap: 8px;

  .filter-box-wrapper {
    display: flex;
    flex: 1;
  }

  .filter-box {
    flex: 1;
  }
}
