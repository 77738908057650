@import '../responsiveUtils.scss';

.new-popup {
  &.responsive-dialog {
    .MuiDialogContent-root:first-child {
      display: flex;
      flex-direction: column;
    }
    .MuiBackdrop-root {
      background-color: rgba(0, 0, 0, 0.4);
    }
    .close-button svg {
      color: #000;
    }
  }
  .MuiDialog-paper {
    width: 50%;
    max-width: 400px;
    background-color: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-height: 300px;
    color: #000;
  }

  .action-buttons {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #000000;

    > button {
      margin: 0;
      border-radius: 0;
      flex: 1;
    }

    .MuiButton-fullWidth {
      height: 80px;
      border-radius: 0;
    }

    .MuiButton-label {
      font-family: 'F37Judge-MediumCondensed';
      font-weight: 500;
      font-size: 30px;
      line-height: 30px;
      color: #fff;
    }

    .large-button.back-button {
      background-color: #414141;
    }
  }

  .content {
    // min-width: 600px;
    width: 100%;
    min-height: 100px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;

    > h1 {
      font-family: 'MediumLLWeb-Bold';
      margin: 0;
      text-transform: uppercase;
      font-size: 18px;
      line-height: 24px;
      color: #000;
      text-align: center;
    }

    > p {
      font-family: 'MediumLLWeb-regular';
      font-size: 15px;
      text-align: center;
      display: flex;
      flex: 1;
      word-break: break-word;
    }

    > button {
      margin: auto;
      width: 30%;
    }
  }
}

@include media(tab) {
  .new-popup {
    .MuiDialog-paperFullScreen {
      height: auto;
    }
  }
}

@include media(phone) {
  .new-popup {
    .MuiDialog-paper {
      width: 90%;
      max-height: 50%;
      min-height: 200px;
    }
  }
}
