@import '../../variables.scss';

.selected-action {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  .button {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: 'MediumLLWeb-Bold';
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #222228;
    color: $secondary-color;
    .icon {
      color: $secondary-color;
      font-size: 12px;
    }
    &:hover {
      color: $green-color;
      .icon {
        color: $green-color;
      }
    }
  }

  .buttons-right {
    display: flex;
    gap: 8px;
  }
  &.white {
    .button {
      background: #F4F7F8;
      color: rgba(0, 0, 0, 0.6);
      .icon {
        color: #000;
      }
      &:hover {
        color: $green-color;
        .icon {
          color: $green-color;
        }
      }
    }
  }
}