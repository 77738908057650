.select-menu.MuiPaper-root.select-menu-icon {
  min-width: 240px !important;

  .MuiListItemIcon-root {
    min-width: 32px;
    color: #000;
  }
}

.select-options.view-mode-select {
  .MuiSelect-root {
    min-width: 30px
  }
}