@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.checklist-menu-actions {
  li {
    &.MuiMenuItem-root {
      span {
        color: $primary-color;
      }
    }
  }

  .MuiListItemIcon-root {
    min-width: 25px;
  }
}

.checklist-form {
  opacity: 1;
  z-index: 1;
  position: relative;
  overflow: hidden;
  flex-wrap: nowrap !important;
  height: 100%;
  display: flex;
  justify-content: space-between;

  .buttons {
    margin: 0 auto;
    width: 100%;
    padding: 0px 24px;

    .large-save-button {
      margin-bottom: 24px;
      border-radius: 100px;
      background-color: rgba(31, 218, 139, 1);
      width: 100%;
      color: #000;
      font-size: 15px;
      margin-bottom: 24px;
      height: 40px;
      font-family: 'MediumLLWeb-Bold';

      &.Mui-disabled {
        background-color: rgba(236, 238, 239, 1);
        opacity: 1;
        color: rgba(0, 0, 0, 0.4);
      }

      &.Mui-disabled.is-processing {
        background-color: rgba(31, 218, 139, 1);
      }
    }
  }

  .styled-scrollbar {
    &::-webkit-scrollbar {
      width: 0.4em !important;
    }
  }

  .checklist-form-body {
    padding: 0px 24px 24px;
    // background: $primary-color-variant;
    border-radius: 6px;
    overflow: auto;

    .todo-title-input {
      .MuiInputBase-adornedEnd {
        padding-right: 12px;
      }

      .MuiInputAdornment-root {
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }

  .icon-close {
    position: absolute;
    right: 45px;
    top: 45px;
    font-size: 26px;
    cursor: pointer;
  }

  label {
    color: #fff;
  }

  .checklist-item-label {
    font-size: 12px;
    margin-bottom: 5px;

    .optional-text {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .MuiInput-underline {
    &:before {
      border: none !important;
    }

    &:after {
      border: none !important;
    }
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 !important;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  fieldset {
    border: none !important;
    padding: 0 !important;
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 !important;
  }

  .MuiInputBase-root {
    background-color: #ffffff;
    color: #000;
    border-radius: 4px;
    min-height: 40px;
    border: 1px solid #DBE1E3;

    input {
      padding: 0 12px !important;
      height: 40px;
    }

    input:-webkit-autofill {
      box-shadow: 0 0 0 50px #4e4e53 inset;
      /* Change the color to your own background color */
      -webkit-text-fill-color: #fff;
    }

    input:-webkit-autofill:focus {
      box-shadow: 0 0 0 50px #4e4e53 inset;
      /*your box-shadow*/
      -webkit-text-fill-color: #fff;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    margin-left: 0 !important;
  }

  .priority-container {
    display: flex;
    align-items: center;
    margin-top: 35px;

    .priority-button {
      background-color: #eeede7;
      border-radius: 2px;
      font-family: MediumLLWeb-Bold;
      color: #000;
      padding: 10px 15px;
    }
  }

  .MuiSelect-select {
    padding: 0px 15px !important;
  }

  .MuiSelect-icon {
    right: 8px !important;
  }

  // .MuiButton-contained.Mui-disabled {
  //   box-shadow: none;
  //   background-color: #0d0de4 !important;
  // }

  .checklist-checkbox {
    // border: 1px solid red;
    margin-top: 15px;
    padding-left: 2px;


    .Mui-checked {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .description-textarea {
    .MuiInputBase-root {
      padding: 8px 15px;
    }
  }
}

.checklist-state-select,
.checklist-state-menu {
  .MuiListItemIcon-root {
    min-width: 25px;
  }
}

.checklist-state-select {
  .MuiMenuItem-root {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 991px) {
  .checklist-form-dialog {
    .MuiDialog-paper {
      height: auto;
    }
  }
}

@include media(phone) {
  .checklist-form {
    .buttons {
      .large-save-button {
        width: 327px;
      }
    }

    .checklist-form-body {
      margin-top: 16px;
    }
  }
}