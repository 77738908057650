.global-resource-usage-popover {
    .MuiPopover-paper{
        // margin-left: -10px;
    }
    .MuiPopover-paper::-webkit-scrollbar {
        width: 0.5em;
        height: 2em
    }
    .MuiPopover-paper::-webkit-scrollbar-button {
        background: rgb(255, 255, 255)
    }
    .MuiPopover-paper::-webkit-scrollbar-track-piece {
        background: rgb(252, 252, 252)
    }
    .MuiPopover-paper::-webkit-scrollbar-thumb {
        background: rgb(122, 122, 122)
    }
    .customized-resource-usage-item-wrapper {
        width: 240px;
        max-height: 230px;
        .item-header{
            background-color: #F4F7F8;
        }
    }
    .loading{
        width: 43px;
        height: 24px;
        overflow:  hidden;
        margin-left: 24px;
        display: inherit;
    }
    .item-name{
        font-style: normal;
        font-weight: 700;
        font-size: 8.5px;
        line-height: 12px;
        /* identical to box height, or 141% */
        color:  rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
    .item-header{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 12px;
        width: 240px;
        height: 28px;
        background: #F4F7F8;
        flex: none;
        order: 0;
        flex-grow: 0;
        z-index: 0;
    }
    .item-body{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 16px;
        width: 240px;
        height: 40px;
        background: #FFFFFF;
        flex: none;
        order: 6;
        flex-grow: 0;
        z-index: 6;
        text-decoration: none;
    }
    .item-body:hover{
        background: #F4F7F8;
        cursor: pointer;
    }
    .item-body-content{
        font-family: 'MediumLLWeb-Regular';
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #4783FF;
        flex: none;
        order: 0;
        flex-grow: 1;
    }
}
