[class^="alert-"],
[class*=" alert-"] {
  display: flex;
  width: 100%;
  background-color: #F4F7F8;
  min-height: 52px;
  color: #000;
  align-items: flex-start;
  border-radius: 4px;
  padding: 16px 16px 16px 16px;

  >span,
  >div {
    display: flex;
    font-size: 12px;
    white-space: pre-wrap;
    margin-right: 10px;

    .clickable {
      cursor: pointer;
      text-decoration: underline;
      padding-left: 3px;
      padding-right: 3px;
    }

    &.icon {
      max-width: 50px;
      justify-content: center;
      min-width: 20px;
      height: 20px;
      align-items: center;
      display: flex;
      margin-top: -2px;

      &:before {
        width: 10px;
      }
    }

    &.message {
      display: block;

      ul {
        padding-left: 18px;
        margin-top: 4px;
        margin-bottom: 4px;
      }

      a {
        color: #4882ff;
        text-decoration: none;
      }
    }
  }
}

.alert-info {
  background-color: #F0F5FF;
  align-items: center;

  >span {
    &.icon {
      color: #4783ff;
      font-size: 16px;
    }
  }
}

.sidepanel,
.side-panel {

  [class^="alert-"],
  [class*=" alert-"] {
    background-color: #47484d;
    color: #fff;
  }
}