@import '../../responsiveUtils.scss';
.grid-result {
  .question-container {
    margin-bottom: 20px;
  }
  .question-title {
    // display: flex;
    // align-self: center;
    text-align: center;
    font-size: 32px;
    font-family: MediumLLWeb-Regular;
  }
  .total-question {
    text-align: center;
    // display: flex;
    // align-self: center;
  }
}

@include media(phone) {
  .grid-result {
    .question-container {
      padding: 30px 0;
      min-height: 350px;
    }

    .question-footer {
      display: flex;
      justify-content: space-between;
      margin-top: 15%;

      button {
        width: 100px !important;
      }
    }
  }
}
