@import '../responsiveUtils.scss';

.vaam-participant-tab {
  width: 100%;
  margin-top: 16px;

  &-header {
    .tabs {
      min-height: 24px;
      &:first-child {
        margin-left: 0;
      }

      .MuiTab-root {
        min-width: auto !important;
        text-transform: unset;
        font-weight: normal;
        font-family: 'MediumLLWeb-Regular';
        font-size: 15px;
        padding: 0 24px;
        min-height: 24px;

        &.Mui-selected {
          font-family: 'MediumLLWeb-Bold';
        }
      }

      .MuiTabs-indicator {
        background-color: #fff;
      }
    }
  }

  &-body {
    overflow: hidden;
    min-height: 480px;
  }

  .information-alert {
    margin: 20px;
    background-color: #F0F5FF;

    .MuiSvgIcon-root {
      color: #4783FF;
    }
    .MuiAlert-message {
      color: #000;
      font-size: 13px;
    }
  }

  .email-notify-checkbox {
    margin: 10px 0 0 10px;
  }
}
