.iframe-player-container {
    .refresh-btn-container {
        position: absolute; 
        bottom: 75px; 
        left: 8px;
        .refresh-icon {
            background: #fff;
            visibility: hidden;
            font-size: 45px;
            padding: 8px;
            border-radius: 50px;
            color: rgba(0, 0, 0, 0.6)
        }
    }
    .resource-refresh-button {
        left: 20px;
    }
}

.iframe-player-container:hover {
    .refresh-icon {
        visibility: visible;
    }
}