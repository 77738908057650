.global-resources-deleted-dialog {
  .MuiDialog-paper {
    width: 500px;
  }

  .MuiDialogTitle-root {
    padding: 25px 0 10px 0;
  }

  .dialog-title-text {
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #000;
  }

  .content {
    text-align: center;
    padding: 0 25px 25px 25px;
  }

  .image-placeholder-wrapper {
    margin-top: 20px;
  }

  .actions {
    padding: 0;
    margin-top: 20px;

    .btn {
      width: 100%;
      height: 88px;
      padding: 8px 16px;

      .MuiButton-label {
        font-family: 'F37Judge-MediumCondensed';
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
        color: #fff;
      }
    }

    .MuiButton-containedPrimary {
      background-color: #000;
    }

    .MuiButton-containedPrimary.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}
