@import '../variables.scss';
@import '../responsiveUtils.scss';

.runtime-client-container,
.salesroom-container {
  .runtime-drawer .MuiDrawer-paper {
    overflow: hidden;
  }

  .runtime-body,
  .runtime-drawer-widgets {
    display: flex;
    position: relative;
    background-color: $secondary-color;

    .side-panel {
      padding: 0;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      // flex: 1;
      overflow: hidden;
      box-shadow: 8px 0px 20px rgba(0, 0, 0, 0.25);

      .meeting-template-button {
        background-color: rgba(31, 218, 139, 1) !important;
        color: $primary-color;
      }

      .MuiAlert-standardInfo {
        background-color: $primary-color-light;

        .MuiAlert-message {
          color: $secondary-color;
        }
      }

      .icon-loading {
        color: $primary-color;
      }

      &::before {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
        content: '';
      }

      >* {
        z-index: 1;
        position: relative;

        &.message-text {
          position: absolute;
        }
      }

      .slick-dots {
        bottom: unset;
        position: relative;
        display: flex !important;
        flex-wrap: nowrap;
        overflow: auto;
        margin-top: 5px;
        align-items: center;
        margin-left: 15px;
        justify-content: center;

        &::-webkit-scrollbar {
          // width: 8px;
          // height: 8px;
          height: 0;
          background-color: #17171a;
        }

        &::-webkit-scrollbar-thumb {
          background: #fff;
        }

        li.slick-active button:before {
          opacity: 1;
          color: #c4c4c4;
        }

        li button:before {
          font-size: 8px;
          color: #4e4e53;
          opacity: 1;
        }
      }

      .close-side-panel {
        display: flex;
        z-index: 9999;
        position: absolute;
        top: 25px;
        right: 25px;
        // width: 24px;
        // height: 24px;
        font-size: 24px;

        .close-icon {
          padding: 0;
          color: #000;
        }
      }

      .side-panel-title {
        padding: 24px 64px 24px 24px;
        height: 72px;
        align-items: center;
        display: flex;
        justify-content: space-between;

        .side-panel-title-bold {
          font-family: 'MediumLLWeb-Bold';
          font-size: 15px;
        }

        .side-panel-title-info {
          font-family: 'MediumLLWeb-Regular';
          font-size: 13px;
          opacity: 0.6;
        }

        .side-panel-header-right {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 20px;

          .todo-list-filter-popup-container {
            .icon-filter {
              color: #000;
            }
          }

          .close-icon {
            padding: 0;
            color: #000;
          }

          .add-button-updated {
            background-color: rgba(31, 218, 139, 1) !important;
            color: #000;
            font-weight: bold;
            padding: 4px 4px 4px 8px;
            letter-spacing: normal;
            min-width: unset;

            span {
              line-height: 16px;
              font-size: 10px;
            }

            &:hover {
              color: #fff;
            }
          }
        }

        &.side-panel-participant {
          padding-right: 25px;

          .side-panel-header-left {
            display: flex;
            flex-direction: column;

            .form-header-title {
              display: flex;
              align-items: center;
              cursor: pointer;

              svg {
                font-size: 17px;
                margin-right: 8px;
                margin-bottom: 3px;
              }
            }
          }
        }
      }

      .verify-message {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        padding: 40px;
        text-align: center;
      }

      .styled-scrollbar {
        &::-webkit-scrollbar {
          width: 0.4em;
        }
      }

      .meetings-container {
        display: flex;
        flex: 1 1;
        flex-direction: column;
        overflow: hidden;
      }
    }
  }

  .runtime-body {
    .runtime-drawer.MuiDrawer-root {
      position: fixed;
      top: 64px;
      left: 8px;
      bottom: 64px;
      width: 500px;
      z-index: 99;

      &.drawerLeftOpen {
        z-index: 1000;
      }
    }
  }
}

@include media(phone) {

  .runtime-client-container .runtime-drawer .MuiDrawer-paper,
  .salesroom-container .runtime-drawer.MuiDrawer-root .MuiDrawer-paper {
    border: none;
    border-radius: 0;
    top: 0;
    left: 0;
    bottom: 57px;
    box-shadow: none;
  }
}