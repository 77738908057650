.notify-list {
  overflow-y: auto;
  padding-bottom: 5px;

  .message-wrapper {
    overflow: hidden;
    max-width: 224px;
    text-overflow: ellipsis;
    span {
      color: #000;
    }
  }
}

@media (max-width: 899px) {
    .notify-list{
      max-height: 100%;
    }
  }
