.space-tag {
  padding: 2px 4px;
  background: #ccddff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: default;

  &.over-text {
    max-width: 80px;
    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .text {
    text-transform: uppercase;
    font-family: 'MediumLLWeb-Bold';
    font-size: 8.5px;
    line-height: 12px;
    letter-spacing: 0.5px;
    white-space: nowrap;
  }

  .icon {
    color: #000;
    font-size: 5px;
  }
  &.hidden-tag {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }
  &.show-more-button {
    background-color: #DBE1E3;
    cursor: pointer;
    margin-left: 4px;
    &:hover {
      background-color: #abb2b4;
    }
  }
}
