@import '../responsiveUtils.scss';

.video-player-dialog {
  .MuiDialog-paper {
    width: 90%;
    max-width: 1600px;
    height: 85%;
    max-height: 700px;
  }

  .close-button {
    top: 10px;
    right: 10px;
  }

  &.fullscreen {
    .MuiDialog-paper {
      max-width: unset;
      max-height: unset;
    }
  }

  .content {
    // min-width: 600px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > iframe {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {
  .video-player-dialog {
    .MuiDialog-paper {
      max-height: 60%;
    }
  }
}

@include media(phone) {
  .video-player-dialog {
    .MuiDialog-paper {
      width: 100%;
      max-height: 100%;
      height: 100%;
    }

    .dialog-content {
      .close-button {
        right: 10px;
        top: 10px;
      }

      .content {
        padding: 20px;
        margin-top: 25px;
      }
    }
  }
}
