@import '../responsiveUtils.scss';

.download-resource-dialog {
  .dialog-content {
    .close-button {
      right: 0px;
      top: 0px;

      .close-icon {
        color: #000;
      }
    }
  }

  &.responsive-dialog {
    .MuiDialog-paper {
      width: 400px;
      max-width: 80%;
      min-height: 280px;
    }

    .dialog-content {
      justify-content: center;
    }
  }
}

.resource-info-view-container,
.download-resource-view-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  margin: auto;
  max-width: 100%;
  width: 400px;
  position: relative;
  justify-content: center;

  .content {
    min-height: 80px;
    padding: 30px;

    .icon {
      display: flex;
      justify-content: center;

      .icon-no-preview {
        font-size: 60px;
      }
    }

    .text-content {
      margin-top: 28px;
      text-align: center;
      color: #000;

      &-message {
        line-height: 16px;
      }
    }

    .info {
      margin-top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 20px;
      opacity: 0.4;

      .name {
        word-break: break-word;
        max-width: 60%;
        width: auto;
      }
    }

    .dot {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      span {
        height: 4px;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
      }
    }
  }

  .buttons {
    .button {
      background-color: #000;
      min-height: 80px;
      border-radius: 0;
      width: 100%;

      .MuiButton-label {
        font-family: 'F37Judge-MediumCondensed';
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        color: #fff;
      }
    }
  }
}

@include media(phone) {

  .resource-info-view-container,
  .download-resource-view-container {
    min-width: unset;

    .content {
      .text-content .text-content-message {
        text-align: center;
      }
    }
  }

  .download-resource-dialog {
    &.responsive-dialog {
      .MuiDialog-paper {
        max-height: 300px;

        .resource-info-view-container {
          height: 300px;
        }
      }
    }
  }
}