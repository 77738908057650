$primary-color: var(--primary-color);
$primary-color-light: var(--primary-color-light);
$primary-color-variant: var(--primary-color-variant);
$secondary-color: var(--secondary-color);
$secondary-color-light: var(--secondary-color-light);
$secondary-color-variant: var(--secondary-color-variant);
$border-color: var(--border-color);
$border-dark: rgba(var(--primary-color-rgb), 0.1);
$background-color: #f3f3f3;
$green-color: var(--green-color);
$FMediumBold: 'MediumLLWeb-Bold';
$FMediumRegular: 'MediumLLWeb-Regular';
$FMediumBoldSubset: 'MediumLLSub-BoldSubset';
$F37Judge: 'F37Judge-MediumCondensed';