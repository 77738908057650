.comment-action-menu {
    width: 76px;
    height: 20px;
    display: flex;
    background-color: #fff;
    gap: 8px;
    align-items: center;

    .MuiIconButton-root {
        color: rgba(0, 0, 0, 0.6);
        height: 20px;
        width: 20px;
        padding: 0;

        .icon-minus-liked {
            font-size: 14px;
            color: rgba(0, 0, 0, 0.6);

        }

        .icon-share {
            font-size: 12px;
        }

        .MuiSvgIcon-root {
            font-size: 16px;
        }
    }
}