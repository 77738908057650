@import '../responsiveUtils.scss';
@import '../variables.scss';

.resource-preview-dialog {
  .MuiDialog-paper {
    width: 75% !important;
    max-width: 1200px;
    // padding: 40px 84px 15px 84px;
    padding: 40px 40px 15px 40px;
  }

  .close-button {
    right: 0;
    top: 0;
  }

  .resource-preview-container {
    width: 100%;
    min-height: 600px;
    height: 65%;
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;

    iframe {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    .loading {
      i {
        color: $secondary-color;
      }
    }

    .video-container {
      background-color: $primary-color;
    }

    video {
      max-height: 100%;
      background-color: #000;
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px !important;
  }
}

.slider-wrapper {

  .back-button,
  .next-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }

    &.back-button {
      left: 0;
    }

    &.next-button {
      right: 0;
    }
  }
}

@include media(phone) {
  .resource-preview-dialog {
    .MuiDialog-container {
      background-color: #000;
    }

    .resource-preview-container {
      height: calc(100% - 80px);
      max-height: calc(100% - 80px);
      min-height: unset;
    }

    .MuiDialog-paper {
      padding: 0;
      width: 100% !important;
      min-height: 100%;
      height: 100%;

      .close-button {
        top: 2px;
        right: 2px;
        padding: 8px;
      }

      .dialog-content {
        padding: 0px !important;

        .back-button {
          top: unset;
          bottom: 0;
          left: 10px;
        }

        .next-button {
          top: unset;
          bottom: 0;
          right: 10px;
        }

        .video-container {
          top: 0 !important;

          video {
            max-height: unset;
          }
        }
      }
    }
  }
}