@import '../../variables.scss';

.gr-action-buttons-container {
  display: flex;
  gap: 8px;

  .button {
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    font-family: 'MediumLLWeb-Bold';
    font-size: 10px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #f4f7f8;
    color: rgba(0, 0, 0, 0.6);
    height: 24px;

    .icon {
      color: rgba(0, 0, 0, 0.6);
      font-size: 12px;

      &::before {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &:hover {
      color: $green-color;
      .icon {
        color: $green-color;
      }
    }
  }
}

.delete-resource-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin: 0;
    text-align: center;
  }
}
