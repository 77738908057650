.logo-space {
  max-height: 40px;
  max-width: 80px;
  min-height: 40px;
  min-width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  img {
    max-width: 100%;
    height: auto;
    max-height: 40px;
  }
}