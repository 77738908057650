@import '../variables.scss';

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  &.MuiAvatar-colorDefault {
    background-color: #A8C5FF;
    color: $primary-color;
  }

  > h6 {
    margin: auto;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    font-family: 'F37Judge-MediumCondensed';
    color: $primary-color;
  }
}
