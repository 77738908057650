@import '../../../variables.scss';
@import '../../../responsiveUtils.scss';

.cms-video-preview {
  display: flex;

  &.active {
    box-shadow: 0 0 0 2px #ad2de1;
  }

  &.allowHover {
    &:hover {
      box-shadow: 0 0 0 2px #ad2de1;
    }
  }

  .cms-video-preview-video {
    z-index: 0;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
  }

  &-player {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .transcoding-message-container {
      z-index: 3;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
  }

  .cms-video-preview-info-wrapper {
    flex: 1;
    display: flex;
    z-index: 1;
    max-width: 700px;
    margin: 0 auto;

    .cms-video-preview-info {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;

      &.topCenter {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 32px;

        &.column-1 {
          margin-top: 80px;
        }
      }

      &.topLeft {
        left: 0;
        margin-left: 32px;
        margin-top: 32px;

        &.column-1 {
          margin-left: 80px;
          margin-top: 80px;
        }
      }

      &.topRight {
        right: 0;
        margin-right: 32px;
        margin-top: 32px;

        &.column-1 {
          margin-right: 80px;
          margin-top: 80px;
        }
      }

      &.middleLeft {
        top: 50%;
        left: 32px;
        transform: translate(0, -50%);

        &.column-1 {
          left: 80px;
        }
      }

      &.middleCenter {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.middleRight {
        top: 50%;
        right: 32px;
        transform: translate(0, -50%);

        &.column-1 {
          right: 80px;
        }
      }

      &.bottomLeft {
        bottom: 0;
        left: 32px;
        margin-bottom: 32px;

        &.column-1 {
          left: 80px;
          margin-bottom: 80px;
        }
      }

      &.bottomCenter {
        bottom: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 32px;

        &.column-1 {
          margin-bottom: 80px;
        }
      }

      &.bottomRight {
        bottom: 32px;
        right: 32px;
        &.column-1 {
          bottom: 80px;
          right: 80px;
        }
      }

      .cms-video-preview-content {
        margin: auto;
        display: flex;
        gap: 24px;
        flex-direction: column;
        width: 100%;

        &.center {
          text-align: center;
          align-items: center;
          .cms-video-preview-buttons {
            justify-content: center;
          }
        }

        &.left {
          align-items: flex-start;
          text-align: left;
          .cms-video-preview-buttons {
            justify-content: flex-start;
          }
        }

        &.right {
          align-items: flex-end;
          text-align: right;
          .cms-video-preview-buttons {
            justify-content: flex-end;
          }
        }

        .cms-video-preview-title {
          font-family: 'F37Judge-MediumCondensed';
          letter-spacing: 0.02em;
          width: 100%;
          white-space: pre-wrap;

          &.large {
            font-size: 85px;
            line-height: 80px;
          }

          &.medium {
            font-size: 60px;
            line-height: 72px;
          }

          &.small {
            font-size: 28px;
            line-height: 32px;
          }
        }

        .cms-video-preview-description {
          font-size: 15px;
          line-height: 20px;
          width: 100%;
          white-space: pre-wrap;
          overflow-wrap: break-word;
          max-width: 700px;
          p {
            margin: 0;
          }

          &.ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
          }
          ul,
          ol {
            display: flex;
            flex-direction: column;
            li {
              text-align: left;
            }
          }
          &.center ul,
          ol {
            align-items: center;
          }
          &.right ul,
          ol {
            align-items: flex-end;
          }
        }

        .cms-video-preview-buttons {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;

          .btn-cms {
            text-transform: none;
            max-width: 400px;

            .MuiButton-label {
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: block;
            }
          }
        }
      }
    }

    .control-button {
      z-index: 3;
      position: absolute;
      // top: calc(50% - 28px);
      // left: calc(50% - 28px);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $primary-color;
      color: $secondary-color;
      padding: 16px;
      font-size: 20px;
      opacity: 0.8;
      border: 1px solid #fff;
      &:hover {
        background-color: $primary-color;
      }
    }
  }

  .cms-video-preview-thumbnail-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .cms-video-preview-thumbnail {
      width: 100%;
      // margin: 0 auto;
      z-index: 0;
      position: relative; /* If you want text inside of it */

      .cms-video-image {
        width: 100%;
        height: 100%;
        position: absolute;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // object-fit: contain;
        position: absolute;
      }

      &-overlay {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .control-button {
        z-index: 3;
        position: absolute;
        top: calc(50% - 28px);
        left: calc(50% - 28px);
        // margin-top: 25%;
        background-color: $primary-color;
        color: $secondary-color;
        padding: 16px;
        font-size: 20px;
        opacity: 0.8;
        border: 1px solid #fff;
        &:hover {
          background-color: $primary-color;
        }
      }
    }
  }

  &.v-column-2,
  &.v-column-3,
  &.v-column-4 {
    height: 100%;
    .cms-video-preview-info-wrapper {
      height: 100%;
      .cms-video-preview-content {
        height: 100%;
      }
      .cms-video-preview-buttons {
        margin-top: auto;
      }
    }
  }

  &.positionRelative {
    .cms-video-preview-info {
      padding: 16px;
    }
  }
}

// @include media(phone) {
//   .cms-video-preview {
//     &-info {
//       .cms-video-preview-title {
//         &.large {
//           font-size: 60px;
//           line-height: 72px;
//         }

//         &.medium {
//           font-size: 40px;
//           line-height: 48px;
//         }
//       }
//       .cms-video-preview-content {
//         &.column-2,
//         &.column-3 {
//           .cms-video-preview-title,
//           .cms-video-preview-description {
//             display: none;
//           }
//         }
//       }
//     }
//     &-control-button {
//       &.column-1 {
//         .control-button {
//           margin-top: 75%;
//         }
//       }
//       &.column-2 {
//         .control-button {
//           margin-top: 20%;
//         }
//       }
//     }
//   }
// }
