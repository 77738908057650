@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.cms-chat-widget {
  display: flex;
  flex-direction: column;

  .side-panel-title {
    color: #000;
    line-height: 24px;
    font-family: 'MediumLLWeb-Bold';
    padding-left: 24px;
    height: 72px;
    align-items: center;
    display: flex;
    justify-content: left;
  }

  .cms-widget-header {
    .left-section {
      .cms-chat-widget-tabs {
        min-height: 24px;

        .MuiTabs-flexContainer {
          gap: 16px;
        }

        .cms-chat-widget-tab {
          min-width: 0;
          min-height: 0;
          padding: 0;
          font-family: 'MediumLLWeb-Regular';
          font-weight: normal;
          font-size: 15px;
          line-height: 24px;
          text-transform: none;
          color: #000;

          &.Mui-selected {
            border: none;
            font-family: 'MediumLLWeb-Bold';
          }

          .MuiTab-wrapper {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .private-chat-description {
    margin: auto;

    .no-private-chat {
      text-align: center;
    }
  }

  .cms-widget-items {
    .cms-widget-item {
      padding-top: 8px;
      align-items: center;

      &.deleted {
        .comment-item-body {
          padding-left: 25px;

          .text {
            padding: 8px 12px;
            background-color: #dbe1e333;
            border-radius: 4px;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
          }
        }
      }

      &.level-2 {
        padding-left: 57px;
      }

      .left-section {
        margin-right: 12px;
      }

      .middle-section {
        .item-name {
          font-family: 'MediumLLWeb-Regular';
        }

        .more-button {
          opacity: 0;

          &:hover {
            opacity: 1;
          }
        }

        .interaction {
          display: flex;
          font-size: 13px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);

          .likes {
            color: rgba(0, 0, 0, 1);
            display: flex;
          }

          .interaction-button {
            min-width: 0;
            padding: 0;

            &:hover {
              background-color: transparent;
            }

            .MuiButton-label {
              font-family: 'MediumLLWeb-Regular';
              font-weight: normal;
              font-size: 13px;
              line-height: 16px;
              color: rgba(0, 0, 0, 0.6);
              text-transform: none;
            }
          }
        }
      }
    }
  }

  .customized-attach-file-section {
    color: #000;

    .MuiLinearProgress-root {
      width: 100%;
      height: 2px;
      background-color: rgba(0, 0, 0, 0.4);
      margin: 8px 0;

      .MuiLinearProgress-bar {
        background-color: #1fda8b;
      }
    }

    .cancel {
      cursor: pointer;
      padding-left: 10px;
    }

    .progress-container {
      margin-top: 8px;
    }

    .uploaded-file {
      display: flex;
      align-items: center;
      border: 1px solid #dbe1e3;
      border-radius: 4px;
      padding: 6px 11px 5px 16px;
      justify-content: space-between;
      margin-top: 8px;

      .file-name {
        font-size: 15px;
        color: $secondary-color;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(0, 0, 0, 0.6);
      }

      .remove {
        svg {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }

  .cms-widget-footer {
    [class^='alert-'] {
      justify-content: unset;
    }

    .attach-file {
      @extend .customized-attach-file-section;
    }
  }

  .reply-section {
    padding: 4px 24px 16px 24px;
    display: flex;

    .attach-file {
      @extend .customized-attach-file-section;
    }
  }

  .online-background {
    height: 14px;
    width: 14px;
    margin-left: 28px;
    margin-top: -12px;
    border-radius: 100%;
    background-color: #ffffff;
    position: sticky;
    align-items: center;
    text-align: center;
    display: flex;
    flex-wrap: wrap;

    .circle {
      background-color: #dbe1e3;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      margin: auto;
      padding-top: 2px;

      &.online {
        background-color: #1fda8b;
      }
    }
  }

  .cms-chat-widget-control-input {
    .MuiOutlinedInput-root {
      background-color: #f4f7f8 !important;
      // color: $secondary-color !important;
      border-radius: 0;

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      .attach {
        display: flex;
        cursor: pointer;
      }

      button {
        font-size: 1.2rem;

        &.send-button {
          color: #1fda8b;

          &.Mui-disabled {
            color: #000;
            opacity: 0.3;
          }
        }
      }
    }

    input {
      border-radius: 0;
      font-size: 15px;
      line-height: 24px;
      font-family: 'MediumLLWeb-Regular';
      padding: 12px 0 12px 12px !important;
      height: 24px;
    }

    .MuiFormHelperText-root {
      color: #ff7f7f;
      font-size: 10px;
      line-height: 12px;
      margin: 4px 0 0 0;
    }

    &-adornment {
      .MuiIconButton-root {
        color: $primary-color;
      }
    }
  }

  .participant-list-container {
    .participant-list-content {

      // padding: 0;
      .participant-list-filter {
        .filter-box {
          input {
            color: #000;
          }

          .MuiInputAdornment-positionStart {
            .MuiSvgIcon-root {
              color: #000;
            }
          }

          input:-webkit-autofill,
          textarea:-webkit-autofill,
          select:-webkit-autofill,
          input:-webkit-autofill:hover,
          textarea:-webkit-autofill:hover,
          select:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          textarea:-webkit-autofill:focus,
          select:-webkit-autofill:focus,
          input:-webkit-autofill:active,
          textarea:-webkit-autofill:active,
          select:-webkit-autofill:active,
          .MuiInputBase-input:-webkit-autofill {
            box-shadow: 0 0 0 1000px #f4f7f8 inset !important;
            -webkit-text-fill-color: #000 !important;
          }
        }
      }

      .participant-list-body {
        .participant-item {
          &:hover {
            background-color: #f4f7f8;
          }

          .content {
            .name-role-block {
              .name {
                color: #000;
              }
            }
          }

          .avatar-block {
            .avatar {
              span {
                color: #000;

                &.online {
                  color: #00cd7f;
                  opacity: 1;
                }
              }
            }
          }

          .icon-right {
            svg {
              color: #000;
            }
          }
        }
      }
    }
  }

  .comment-box {
    .comment-box-body {
      .input {
        background-color: #f4f7f8;

        textarea {
          color: #000;
        }

        .attach {
          svg {
            color: #000;
          }
        }

        .icon-video-message {
          &::before {
            color: #000;
          }
        }

        .icon-send {
          &.disabled {
            color: rgb(165 165 165 / 60%);
          }
        }

        .slack-link {
          span {
            color: #939393;
          }
        }
      }
    }

    .attach-file {
      p {
        color: #000 !important;
      }
    }
  }
}

@include media(phone) {
  .cms-chat-widget .cms-widget-items .cms-widget-item .middle-section .more-button {
    opacity: 0.6;
  }
}