@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.private-chat-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: hidden;
  position: relative;
  height: 100%;

  .participant-list-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;

    .participant-channel-items {

      .participant-info {
        padding: 8px 24px;
        cursor: pointer;
      }
    }

    .participant-list-filter {
      .filter-box {
        padding: 0px 24px 16px 24px;
      }

      &.select-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
          span {
            color: #fff;
            text-transform: initial;
            font-weight: normal;
            font-family: MediumLLSub-RegularSubset;
          }
        }
      }
    }

    .message {
      display: flex;
      height: 100%;
      justify-content: center;
      align-items: center;
      white-space: normal;
      text-align: center;
    }

    .participant-list-body {
      overflow: auto;
      margin-top: 10px;
      height: 100%;
      padding-bottom: 15px;

      .list {
        padding-bottom: 24px;
      }

      .suggestion {
        font-size: 8.5px;
        font-weight: 700;
        color: rgba(0, 0, 0, 0.60);
        padding-left: 24px;
        text-transform: uppercase;
        line-height: 28px;
      }
    }

    .participant-list-title {
      font-weight: bold;
      color: $secondary_color;
      padding: 0;
      margin: 0;
    }

    .participant-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 24px;
      padding-left: 24px;
      gap: 12px;
      height: 56px;

      &.hover {
        background-color: #fff;
      }

      .tags {
        flex-wrap: nowrap;
        overflow: hidden;
      }

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

      .avatar-block {
        border-radius: 6px 0px 0px 6px;
        text-align: center;
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 0 0 34px;

        .online-background {
          height: 14px;
          width: 14px;
          border-radius: 100%;
          margin-top: 28px;
          margin-left: -12px;
          background-color: #fff;
          position: sticky;
          align-items: center;
          text-align: center;

          .circle {
            background-color: #DBE1E3;
            height: 10px;
            width: 10px;
            border-radius: 100%;
            margin: auto;
            margin-top: 2px;

            &.online {
              background-color: #1FDA8B;
            }
          }

        }

        .avatar {
          display: flex;
          flex: 1 1;

          span {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            color: $secondary-color;
            opacity: 0.4;
            font-family: 'F37Judge-MediumCondensed';
            font-size: 28px;

            &.online {
              color: #00cd7f;
              opacity: 1;
            }
          }
        }
      }

      .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: center;
        white-space: nowrap;
        overflow: hidden;

        .name-role-block {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          width: 100%;

          .role-wrapper {
            margin-top: -2px;

            .role {
              font-family: 'MediumLLWeb-Bold';
              font-size: 8.5px;
              line-height: 12px;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: #000000;
              padding: 2px 4px;
              border-radius: 2px;
            }
          }

          .name {
            display: flex;
            margin: 0;
            color: $primary_color;
            text-transform: capitalize;
            padding-left: 2px;
            padding-bottom: 0px;
            font-family: 'MediumLLWeb-Regular';
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
              text-transform: lowercase;
              margin-left: 6px;
            }

            &.description {
              color: #908e8e;
              font-size: 13px;
            }
          }

          .level {
            position: relative;
            display: flex-inline;
            justify-content: center;
            align-items: center;
            padding: 0 10px;

            i {
              font-size: 20px;
              position: absolute;
            }

            span {
              z-index: 1;
              position: absolute;
              font-size: 10px;
              font-family: 'MediumLLWeb-Regular';
              font-weight: bold;
              font-weight: 700;
              color: #000;
              top: 4px;
            }

            &.only-me {
              i {
                color: #dbe1e3;
                opacity: 0.2;
              }

              span {
                color: #fff;
              }
            }

            &.share-organization {
              i {
                color: #4783ff;
              }

              span {
                color: #fff;
              }
            }

            &.public {
              i {
                color: #fff;
              }

              span {
                color: #000;
              }
            }
          }
        }

        .email {
          display: flex;
          align-items: center;

          svg {
            font-size: 16px;
            opacity: 0.4;
            margin-right: 5px;
          }

          span {
            display: block;
            font-family: 'MediumLLWeb-Regular';
            font-size: 13px;
            line-height: 16px;
            opacity: 0.6;
            padding-left: 3px;
            text-overflow: ellipsis;
            overflow: hidden;
            width: 100%;
          }
        }
      }

      .icon-right {
        display: flex;
        align-items: center;

        .MuiIconButton-root {
          width: 24px;
          height: 24px;
        }

        .buttonOpacity {
          opacity: 0;
          color: #000;
        }

        .whiteButton {
          color: $secondary-color;
          padding: 5px;
          margin-right: -5px;
        }

        .checkbox {
          color: #414141;
        }
      }


      .blue-dot {
        opacity: 0;

        &.active {
          opacity: 1;
        }
      }


      .icon-right {
        width: 24px;
        height: 24px;

        .buttonOpacity {
          opacity: 1;

          .MuiIconButton-root {
            .MuiSvgIcon-root {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }

      .action-buttons {
        .btn {
          padding: 4px 8px;
          border-radius: 4px;
          font-style: normal;
          font-size: 10px;
          font-family: 'MediumLLWeb-Bold';
          line-height: 16px;
          min-width: unset;
          margin-left: 8px;

          &.btn-deny {
            background: rgba(219, 225, 227, 0.2);
          }

          &.btn-accept {
            background: #1FDA8B;
            color: #000;
          }
        }
      }
    }
  }
}

.side-panel {

  .private-chat-container .participant-list-content .participant-item .icon-right .invitationButton {
    i {
      color: $secondary-color;
    }
  }
}

@include media(phone) {

  .private-chat-container .participant-list-content {
    padding-right: 0;
    padding-bottom: 0;
  }

  .private-chat-container .participant-list-content .participant-item .icon-right .buttonOpacity {
    opacity: 1;
  }

  .private-chat-container .side-panel-title {
    padding: 22px 24px !important;
  }
}