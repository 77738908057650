@import '../../variables';

.dnd-file-uploader-common-text-style {
  font-family: 'MediumLLWeb-Regular';
  font-size: 12px;
  line-height: 16px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  border-radius: 0;



  .upload-progress {
    color: $secondary-color;
    background-color: #47484d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 4px;
    align-items: unset;
    margin: 0;

    .MuiLinearProgress-root {
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 8px 0;

      .MuiLinearProgress-bar {
        background-color: #1fda8b;
      }

      &.failed {
        .MuiLinearProgress-bar {
          background-color: #f44a4a;
        }
      }
    }

    .file-name {
      font-size: 15px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .bottom-section {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .message {
        font-size: 12px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.6);

        &.failed {
          color: #f44a4a;
        }

        &.completed {
          color: #1fda8b;
        }
      }

      .action-button {
        padding: 0;
        min-width: 0;

        .MuiButton-label {
          font-family: 'MediumLLWeb-Regular';
          font-size: 12px;
          line-height: 20px;
          font-weight: unset;
          color: rgba(255, 255, 255, 0.6);
          text-transform: capitalize;
        }
      }
    }
  }

  .upload-area {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .upload-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .upload-btn {
        border-radius: 4px;
        border: 1px dashed $secondary_color;
        box-sizing: border-box;
        cursor: pointer;
        min-height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        min-width: auto;
        width: auto;
        padding: 0;

        &.disable-area {
          cursor: default;
        }

        img {
          width: 40px;
        }

        p {
          text-align: center;
          margin: 0;
          padding: 0;
          color: $secondary_color;
          font-size: 13px;
          line-height: 16px;
          width: 100%;
          white-space: nowrap;
          font-family: 'MediumLLWeb-Regular';
        }

        a {
          // color: #76a2fe;
          color: #4783ff;
          cursor: pointer;
          text-decoration: underline;
          font-family: 'MediumLLWeb-Bold';
        }
      }
    }

    .or-divider {
      width: 100%;
      display: flex;
      align-items: center;
      // margin: 16px 0;

      .or-divider-text {
        @extend .dnd-file-uploader-common-text-style;
        font-family: 'MediumLLWeb-Bold';
        color: $secondary-color;
        margin: 0 8px;
        opacity: 0.8;
      }

      hr {
        width: 100%;
        border: 1px solid #4e4e53;
        margin: 0;
      }
    }
  }

  .dnd-file-uploader-control {
    display: flex;
    flex-direction: column;

    .dnd-file-uploader-control-label-wrapper {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      .dnd-file-uploader-control-label {
        margin-bottom: 0;
      }

      .icon-info {
        font-size: 13.33px;
        margin-left: 5.33px;
        cursor: pointer;

        &::before {
          color: rgba(0, 0, 0, 0.6);
        }

        &:hover {
          &::before {
            color: #000;
          }

          // -webkit-filter: invert(100%);
          // filter: invert(100%);
        }
      }
    }

    .dnd-file-uploader-control-label {
      @extend .dnd-file-uploader-common-text-style;
      color: $secondary-color;
      margin-bottom: 4px;
    }

    .dnd-file-uploader-control-input {
      .MuiOutlinedInput-root {
        background-color: #47484d;
        color: $secondary-color;
        border: 1px solid transparent;

        &:hover {
          border: 1px solid #ffffff;
        }

        &.MuiOutlinedInput-adornedEnd {
          padding-right: 12px;
        }

        .MuiOutlinedInput-notchedOutline {
          border: none;
        }

        &.Mui-error {
          border: 1px solid #f44a4a;
        }

        &.Mui-focused {
          border: 1px solid #000000 !important;
        }
      }

      &.text-underline {
        input {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      input {
        border-radius: 4px;
        font-size: 15px;
        line-height: 24px;
        font-family: 'MediumLLWeb-Regular';
        padding: 8px 0 8px 12px !important;
        height: 24px;
      }

      .MuiFormHelperText-root {
        color: #f44a4a;
        font-size: 10px;
        line-height: 12px;
        margin: 4px 0 0 0;
      }

      &.red-border {
        .MuiOutlinedInput-root {
          border: 1px solid #f44a4a;
        }
      }

      .action-buttons {
        display: flex;
        gap: 16px;
        align-items: center;

        .MuiIconButton-root {
          padding: 0;

          .MuiSvgIcon-root {
            font-size: 24px;
          }

          .icon-refresh {
            font-size: 20px;
          }

          .icon-refresh,
          .cancel-icon {
            color: #f44a4a;
          }

          .icon-spinner,
          .check-icon {
            color: #1fda8b;
          }

          .icon-upload {
            font-size: 16px;
          }

          .edit-icon,
          .delete-icon {
            color: rgba(0, 0, 0, 0.6);
          }
        }

        .action-button {
          padding: 0;
          min-width: 0;

          .MuiButton-label {
            font-family: 'MediumLLWeb-Regular';
            font-size: 13px;
            line-height: 16px;
            font-weight: unset;
            color: rgba(255, 255, 255, 0.6);
            text-transform: capitalize;
          }
        }
      }
    }

    .form-warning {
      color: #f44a4a;
      font-size: 12px;
      line-height: 16px;
      margin: 4px 0 0 0;
      white-space: break-spaces;

      &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        display: block;
      }
    }

    .form-info {
      @extend .form-warning;
      color: rgba(0, 0, 0, 0.6);
    }

    .uploaded-files-section {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .invalid-item {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border: 1px solid #dbe1e3;
        padding: 8px 12px;

        .top {
          display: flex;
          justify-content: space-between;

          .name {
            font-size: 15px;
            line-height: 24px;
            font-family: 'MediumLLWeb-Regular';
            word-break: break-all;
          }
        }

        .action-buttons {
          display: flex;
          gap: 16px;
          align-items: center;

          .MuiIconButton-root {
            padding: 0;

            .MuiSvgIcon-root {
              font-size: 24px;
            }

            .icon-refresh {
              font-size: 20px;
            }

            .icon-refresh,
            .cancel-icon {
              color: #f44a4a;
            }

            .icon-spinner,
            .check-icon {
              color: #1fda8b;
            }

            .icon-upload {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  #external-url-input {
    &:hover {
      cursor: pointer;
    }
  }

  * {
    &.disabled {
      pointer-events: none;
      cursor: default;

      .MuiFormControl-root {
        .MuiInputBase-root {
          input {
            background-color: #ECEEEF !important;
            color: rgba(0, 0, 0, 0.3);
          }
        }
      }


      .icon-info {
        pointer-events: auto;
        cursor: pointer;
      }

      .upload-btns .upload-btn {
        background: rgba(219, 225, 227, 0.2);
        border: 1px dashed rgba(255, 255, 255, 0.3);

        p {
          opacity: 0.4;
        }
      }

      .dnd-file-uploader-control-input .MuiOutlinedInput-root {
        background-color: #ECEEEF !important;
        color: rgba(255, 255, 255, 0.3) !important;
      }
    }
  }
}

.marginTop16 {
  margin-top: 16px;
}