@import '../../../variables.scss';
@import '../../../responsiveUtils.scss';

.cms-salesroom-header {
  position: absolute;
  top: 56px;
  z-index: 99;
  width: 100%;
  padding: 7px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .vertical-divider {
    margin: auto;
    margin-top: 12px;
  }
  .divider {
    display: inline-block;
    color: #000;
    margin: 0;
    width: 2px;
    height: 16px;
  }

  .editicon {
    color: #000;
    opacity: 1;
  }

  .left-content {
    display: flex;
    align-items: center;
    gap: 24px;
    align-items: center;
    .space-template-button {
      background-color: #ad2de1;
      padding: 2px 4px;
      width: 57px;
      height: 16px;
      color: white;
      font-size: 8.5px;
      line-height: 12px;
      font-weight: bold;
      cursor: none;
      border-radius: 2px;
    }
    .tag-template-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .template-tag {
      background-color: #ad2de1;
      padding: 2px 4px;
      height: 16px;
      color: #fff;
      font-weight: 700;
      border-radius: 2px;
      align-self: flex-end;
      font-size: 8.5px;
      text-transform: uppercase;
    }
    .space-info {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }

  .right-content {
    display: flex;
    gap: 8px;
    align-items: center;
    .user-avatar {
      background-color: #000000 !important;
      h6 {
        color: #ffffff !important;
      }
    }
    .vertical-divider {
      margin: auto 8px;
      margin-top: 12px;
    }
    .MuiIconButton-root {
      &.Mui-disabled {
        opacity: 0.2;
        color: #000;
      }
    }
  }

  .text-truncate {
    font-size: 18px;
  }

  .button-icon {
    color: #000;
    opacity: 1;
  }

  .button-icon {
    // color: $secondary-color;
    // background-color: $primary-color;
    background-color: #fff;
    color: $primary-color;
    padding: 9px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    .icon {
      font-size: 20px;
    }
    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }

    &:hover {
      color: $primary-color;
      background-color: #f4f7f8;
    }

    &.vaam-icon {
      font-size: 18px;

      &:hover {
        color: $primary-color;
        background-color: #fff;
        .icon-video-message::before {
          color: $primary-color;
          background-color: #fff;
        }
      }

      &.disabled {
        opacity: 0.2;
      }

      .icon-video-message::before {
        color: $primary-color;
      }
    }
  }

  .login-menu-button {
    margin-left: 6px;
  }
}

.space-action-menu-runtime.space-action-list .MuiMenuItem-root {
  width: 230px;
}

@include media(phone) {
  .cms-salesroom-header {
    gap: 16px;
    .text-truncate {
      font-size: 15px;
    }
    .left-content {
      overflow-x: hidden;
      justify-content: space-between;
      gap: 8px;
    }
    .right-content {
      gap: 16px;
      .MuiButtonBase-root.button-icon {
        width: 24px;
        height: 24px;
        padding: 0;
        margin: 0;
      }
      .login-menu-button {
        margin-left: 0;
      }
    }
  }
}
