@import '../../variables.scss';

.cms-meeting-widget {
  display: flex;
  flex-direction: column;

  .cms-widget-items {
    .cms-widget-item {
      .left-section {
        .day-info {
          font-family: 'F37Judge-MediumCondensed';
          font-size: 32px;
          line-height: 38px;
          letter-spacing: 0.02em;
        }

        .month-info {
          font-family: 'MediumLLWeb-Bold';
          font-size: 12px;
          line-height: 16px;
          text-transform: uppercase;
          color: rgba(0, 0, 0, 0.4);
        }
      }

      .middle-section {
        .top-section {
          display: flex;
        }
      }
    }
  }

  .meetings-timeline {
    overflow-y: auto;

    .date {
      .day {
        color: rgba(0, 0, 0, 0.4);
      }

      .today {
        color: #1fda8b;
      }
    }

    .meeting-timeline-item {
      >.info {
        background-color: #f4f7f8;

        // .tags {
        //   span {
        //     color: #000;
        //   }
        // }

        .item-content {
          .spce-btn {
            color: #000;
            background-color: #dbe1e3;

            &:hover {
              color: #1fda8b;
            }

            &.active {
              background-color: #1fda8b;

              &:hover {
                color: #fff;
              }
            }
          }
        }

        .list-item-buttons {
          .icon-chart-with-bg {
            .path1:before {
              color: #fff;
            }

            .path2:before {
              color: #1fda8b;
            }
          }

          .icon-urgent {
            background-color: #fff;
          }

          .action-menu-button,
          .icon-button {
            color: rgba(0, 0, 0, 0.6);
            background-color: transparent;

            :hover {
              background-color: #eeede7;
              border-radius: 50px;
            }
          }
        }

        .description {
          font-size: 13px;
        }
      }

      .icon-vertical-block {
        .MuiIconButton-root {
          background-color: #f4f7f8;
          color: rgba(0, 0, 0, 0.2);
        }

        .vertical-line {
          border: 1px solid #f4f7f8;
        }

        &.active {
          .MuiIconButton-root {
            color: #1fda8b;
          }

          .vertical-line {
            border: 1px solid #1fda8b;
          }
        }
      }

      .last-item-vertical-line {
        border: 1px solid #f4f7f8;
      }
    }

    .checklist-form {
      background-color: #fff;

      .checklist-form-body {
        margin-top: 0;

        .form-fields {
          .MuiInputBase-root {
            color: #000;
            background-color: #fff;
            border: 1px solid #dbe1e3;
            padding: 10px 12px !important;

            input {
              padding: 0 !important;
            }
          }

          .MuiInput-underline {
            &:hover {
              border: 1px solid #dbe1e3 !important;
            }
          }

          .checklist-item-label {
            margin-top: 24px;
          }

          .MuiSvgIcon-root {
            color: rgba(0, 0, 0, 0.54);
          }

          .checklist-checkbox {
            margin-top: 24px;
            margin-left: 0;
            padding-left: 0;

            .MuiFormControlLabel-label {
              color: #000;
              margin-left: 12px;
            }
          }

          .MuiSelect-select {
            padding: 0 !important;

            &:focus {
              background-color: #fff;
            }
          }
        }
      }
    }

    .todo-toggle {
      color: rgba(0, 0, 0, 0.54);

      .todo-toggle-vertical-block {
        .todo-toggle-vertical-line {
          border: 1px solid #f4f7f8;
        }
      }
    }
  }

  .cms-widget-header {
    .right-section {
      .icon-filter {
        color: rgba(0, 0, 0);
        cursor: pointer;
      }
    }
  }
}