.menu-action-cms-container {
  position: absolute;
  z-index: 1399;
  top: -46px;
  left: 50%;
  transform: translateX(-50%);
  
  .menu-action-cms {
    display: flex;
    margin: auto;
    margin-bottom: 4px;
  
    .actions {
      margin: auto;
      display: flex;
      text-align: center;
      align-items: center;
      background-color: #000000;
      border-radius: 4px;
      padding: 2px 12px;
      color: #ffffff;
  
      .MuiBox-root {
        padding: 0px 16px 0px 16px;
        .divider {
          height: 16px;
          width: 2px;
          background-color: rgba(219, 225, 227, 0.2);
        }
      }
  
      .icon-buttons {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
  
        &.Mui-disabled {
          opacity: 0.4;
          cursor: none;
          pointer-events: none;
        }
  
        .icon {
          font-size: 22px;
          opacity: 1;
          color: #fff;
          &:hover {
            color: #1fda8b;
          }
        }
  
        .icon-duplicate {
          color: #ffffff;
          font-size: 16px;
        }
  
        .duplicateIcon {
          opacity: 1;
          &:hover {
            color: #1fda8b;
          }
        }
      }
  
      .button {
        min-width: 44px;
        height: 40px;
        display: flex;
        gap: 4px;
        color: #fff;
        font-size: 15px;
        padding: 0;
  
        .MuiButton-label {
          display: flex;
          gap: 4px;
          white-space: nowrap;
          .icon {
            width: 20px;
            height: 20px;
          }
        }
  
        &:hover {
          color: #1fda8b;
        }
  
        &.Mui-disabled {
          opacity: 0.4;
          cursor: none;
          pointer-events: none;
        }
  
        &.disabled {
          opacity: 0.4;
          cursor: inherit;
  
          &:hover {
            color: #fff;
            opacity: 0.4;
          }
        }
      }
    }
  }
}
