.comment-text {
  display: flex;
  gap: 4px;

  &>span:first-child {
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
  }

  .icon-video-message:before {
    color: rgba(0, 0, 0, 0.6);
  }
}