.edit-category-dialog {
  .edit-categories-input {
    .MuiOutlinedInput-input {
      padding: 8px 12px;
    }
    .Mui-error {
      margin-left: 0;
    }
  }
}
