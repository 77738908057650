@import '../../variables.scss';

.materials-move-dialog {
  .MuiDialog-paper {
    width: 900px;
    min-height: 600px;
    // height: 100%;
  }

  .MuiDialogTitle-root {
    padding: 0;
  }

  .MuiDialogContent-root {
    padding: 0;

    .page-not-found {
      // height: 412px;
      height: 328px;
    }
  }

  .block-button {
    width: 100%;
  }

  .materials-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;

    .materials-header-right-section {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .materials-header-title {
      font-family: MediumLLWeb-Bold;
      font-size: 15px;
      line-height: 24px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .materials-header-info {
      color: rgba(0, 0, 0, 0.4);
      font-size: 12px;
      line-height: 16px;
    }

    .search-button,
    .close-button {
      cursor: pointer;
      padding: 0;
      color: #000;
    }

    .add-button {
      color: #fff;
      background-color: #000;

      &:hover {
        background-color: #000;
      }
    }
  }

  .directories-item {
    background-color: #fff;
    color: #000;

    .title {
      color: #000;
    }

    &.disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }

  .MuiDialogActions-root {
    padding: 0;
  }
  .filter-box {
    padding: 24px 24px 0 24px;
  }
}
.material-list {
  overflow: hidden;
}

.move-popup-popper {
  .resource-item {
    &.disabled {
      opacity: 0.5;
      cursor: no-drop;
    }
  }
}

.resource-item-popper {
  z-index: 2000 !important;
}
