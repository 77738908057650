.teams-connected-message-popup {
  bottom: 80px !important;
  background: rgba(65, 65, 65, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);
  border-radius: 4px;

  .reconnect-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    .reconnect-left-content {
      .icon-info {
        font-size: 20px;
        color: #4783ff;
      }
    }

    .reconnect-right-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .title {
        margin: 0;
        padding: 0;
        font-family: 'MediumLLWeb-Bold';
        font-size: 15px;
        line-height: 24px;
      }

      .description {
        margin: 0;
        padding: 0;
      }
    }
  }

  .reconnect-buttons-container {
    .reconnect-button {
      background: #000000;
      border-radius: 100px;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 24px;
      padding: 8px 16px;
      margin-right: 10px;
      min-width: 157px;

      span {
        color: #ffffff !important;
      }
    }

    .reconnect-close-button {
      padding: 5px;
    }
  }
}

@media (min-width: 600px) {
  .external-chat-reconnect-popup {
    .MuiSnackbarContent-root {
      min-width: 670px !important;
      padding: 10px 20px;
    }

    &.teams-meeting-reconnect-popup {
      .MuiSnackbarContent-root {
        min-width: 750px !important;
        padding: 10px 20px;
      }
    }
  }
}
