.vaam-recording-box {
  position: absolute;
  top: 55px;
  right: 20px;
  width: 380px;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  z-index: 999;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);

  .action-buttons button {
    width: 158px;
    height: 40px;
    border-radius: 100px;
    padding: 8px, 16px, 8px, 16px;
  }

  .move-area-button {
    cursor: move;
  }

  .stop-button {
    width: 73px;
    height: 40px;
    border-radius: 100px;
  }

  .moveable-line {
    --moveable-color: none !important;
  }

  .body {
    position: relative;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 24px;
    padding: 24px;

    .logo {
      height: 24px;
      min-width: auto;
    }

    .move-area-button {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .MuiIconButton-root {
      width: 24px;
      height: 24px;

      &:hover {
        background-color: transparent;
      }
    }
    .MuiSwitch-switchBase {
      width: unset;
      height: unset;
    }
  }

  .switch-enhanced {
    flex-direction: unset;
    position: absolute;
    right: 50px;
    bottom: 116px;
    z-index: 100;

    .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
      opacity: 1;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    .recorder {
      width: 100%;
      justify-self: center;

      // vaam recorder
      .vaam-input {
        height: 430px;
        background-color: #fff;
        position: relative;
        padding: 0;

        >div {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          >div:last-child {
            width: 100%;
            bottom: 0 !important;
          }
        }

        video {
          width: 240px;
          height: 240px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px solid #dbe1e3;
          opacity: 1;
        }

        .loading {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 200px;

          .icon-spinner {
            color: #1fda8b;
            font-size: 40px;
          }

          p {
            font-size: 18px;
            text-transform: uppercase;
            font-family: 'MediumLLWeb-Bold';
          }
        }
      }

      div[class*='VaamBadge'] {
        display: none;
      }

      div[class*='ButtonArea'] {
        background-color: #fff;
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-start;
        row-gap: 5px;
        padding: 0;

        >div {
          &:last-child {
            margin-bottom: 60px;
          }
        }
      }

      button[class*='PreRecordingControls'] {
        background-color: #1fda8b;
        font-family: 'MediumLLWeb-Bold';
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-size: 15px;
        width: 332px;
        margin: auto;
        margin-top: 24px;
        margin-bottom: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        height: 40px;

        &:hover {
          color: #fff;
        }

        &:disabled {
          color: rgba(0, 0, 0, 0.4);
          background-color: #ECEEEF;
        }
      }

      label[class*='Select___StyledLabel'] {
        width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #dbe1e3;
        background-color: #fff;
        margin-left: 50px;
      }

      div[class*='ControlButtons___StyledBox'] {
        top: -60px !important;
        width: 66px;
        height: 28px;
        border-radius: 100px;
        background-color: #414141CC !important;

        span {
          padding: 2px 8px 2px 2px;
          font-size: 15px;
          line-height: 24px;
          text-align: center;
        }
      }

      span[class*='Select___StyledSpan'] {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;

        >span {
          display: flex;
        }
      }

      // recorded video
      div[class*='video___StyledBox'] {
        video {
          width: 100%;
          height: 100%;
          border-radius: unset;
        }
      }

      &.recording-started {
        .vaam-input {
          height: 325px;
        }

        div {
          background-color: unset;
        }

        .switch-enhanced {
          display: none;
        }

        div[class*='ButtonArea'] {
          align-items: center;

          >div {
            &:last-child {
              margin-bottom: 20px;
            }
          }
        }

        label[class*='Select___StyledLabel'] {
          margin-left: 0px;
        }
      }

      &.done-recording {
        .vaam-input {
          padding: 0 24px;
          height: 190px;

          >div {
            justify-content: center;

            >div:last-child {
              display: none;
            }
          }
        }

        .action-buttons {
          margin: 24px 0;
          display: flex;
          justify-content: center;
          gap: 24px;

          .regular-rounded-button {
            line-height: 16px;
          }

          .btn-gray {
            background-color: #eceeef;
            color: #000;
            margin-right: 24px;
          }
        }
      }
    }

  }
}

.unset-bg {
  background-color: unset;
  box-shadow: none;
}

.set-top {
  top: -20px !important;
}

.set-hidden {
  display: none !important;
}

.set-visibility-hidden {
  visibility: hidden;
}

.green-dot {
  width: 8px;
  height: 8px;
  padding-left: 8px;
  margin-left: 8px;
  margin-right: 4px;
  margin-top: 9px;
  background-color: #1FDA8B !important;
  border-radius: 50%;
}