.spce-btn {
  &.official {
    border-radius: 100px;
    padding: 8px 16px;
    line-height: 24px;
    text-transform: uppercase;
    font-family: 'MediumLLWeb-Bold';
    text-decoration: none;
    max-height: 40px;
    align-items: center;
    text-align: center;
    font-size: 15px;
    border: none;
    cursor: pointer;

    & svg {
      font-size: 20px;
      margin-left: 4px;
    }

    i {
      margin-left: 4px;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.4) !important;
      background-color: #eceeef !important;
      cursor: inherit;
    }

    &.regularPrimary {
      background-color: #000000;
      color: #ffffff;

      &:hover {
        color: #1fda8b;
        background-color: #000000;
      }
    }

    &.regularSecondary {
      background-color: #f4f7f8;
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        color: #000000;
        background-color: #f4f7f8;
      }
    }

    &.regularGreen {
      background-color: #1fda8b;
      color: #000000;

      &:hover {
        color: #ffffff;
        background-color: #1fda8b;
      }
    }

    &.regularRed {
      background-color: #f44a4a;
      color: #ffffff;

      &:hover {
        color: #000000;
        background-color: #f44a4a;
      }
    }

    &.regularDarkGrey {
      background-color: #222228;
      color: #ffffff;

      &:hover {
        color: #1fda8b;
        background-color: #222228;
      }
    }

    &.disabled {
      background-color: #eceeef;
      color: rgba(0, 0, 0, 0.4);
      cursor: inherit;

      &:hover {
        color: rgba(0, 0, 0, 0.4);
        background-color: #eceeef;
      }
    }
  }

  &.rectangle {
    border-radius: 4px;
    height: 24px;
    text-transform: uppercase;
    font-size: 10px;
    padding: 4px 8px;
    line-height: 16px;
    font-family: 'MediumLLWeb-Bold';
    cursor: pointer;

    .MuiButton-label {
      gap: 4px;
    }

    // .MuiSvgIcon-root {

    // }

    &:disabled, &.disabled {
      color: rgba(0, 0, 0, 0.4) !important;
      background-color: #eceeef !important;
    }

    & svg {
      font-size: 16px;
      margin-right: -4px;
    }

    & span {
      & span {
        margin-left: 4px;
      }
    }

    &.rectangleDarkGrey {
      background-color: #222228;
      color: #ffffff;

      &:hover {
        color: #1fda8b;
        background-color: #222228;
      }
    }

    &.rectanglePrimary {
      background-color: #000000;
      color: #ffffff;

      &:hover {
        color: #1fda8b;
        background-color: #000000;
      }
    }

    &.rectangleGrey {
      background-color: rgba(219, 225, 227, 0.2);
      color: #ffffff;

      &:hover {
        color: #1fda8b;
        background-color: rgba(219, 225, 227, 0.2);
      }

      &:disabled {
        background-color: rgba(219, 225, 227, 0.2) !important;
        color: rgba(255, 255, 255, 0.4) !important;
      }
    }

    &.rectangleSecondary {
      background-color: #f4f7f8;
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        color: #000000;
        background-color: #f4f7f8;
      }
    }

    &.rectangleGreen {
      background-color: #1fda8b;
      color: #000000;

      &:hover {
        color: #ffffff;
        background-color: #1fda8b;
      }
    }
  }

  &.large {
    margin: auto;
    width: 100%;
    min-height: 88px;
    color: #fff;
    text-transform: uppercase;
    font-family: 'F37Judge-MediumCondensed';
    font-size: 32px;
    line-height: 40px;
    font-weight: 500;
    border-radius: 0;

    &:disabled, &.disabled {
      color: rgba(0, 0, 0, 0.3) !important;
      background-color: #eceeef !important;
    }

    &.largeSecondary {
      background-color: #f4f7f8;
      color: rgba(0, 0, 0, 0.6);

      &:hover {
        background-color: #f4f7f8;
        .MuiButton-label {
          color: #000000;
        }
      }
      .MuiButton-label {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &.largePrimary {
      background-color: #000000;
      color: #ffffff;

      &:hover {
        background-color: #000000;
        .MuiButton-label {
          color: #1fda8b;
        }
      }
    }
  }

  &.small {
    border-radius: 100px;
    text-transform: uppercase;
    text-decoration: none;
    max-height: 40px;
    padding: 4px 8px;
    min-width: 48px;
    &:disabled, &.disabled {
      color: rgba(0, 0, 0, 0.4) !important;
      background-color: #eceeef !important;
    }
    &.rectangle {
      border-radius: 4px;
    }

    .MuiButton-label {
      min-width: 25px;
      height: 16px;
      font-family: 'MediumLLWeb-Bold';
      font-size: 10px;
      line-height: 16px;
    }

    &.grey {
      color: var(--secondary-color);
      background-color: rgba(219, 225, 227, 0.2);
      &:hover {
        color: #1fda8b;
        background-color: rgba(219, 225, 227, 0.2);
      }
    }
    &.green {
      background-color: #1fda8b;
      color: #000000;
      &:hover {
        color: #ffffff;
        background-color: #1fda8b;
      }
    }

    &.secondary {
      background-color: #f4f7f8;
      color: rgba(0, 0, 0, 0.6);
      &:hover {
        color: #000000;
        background-color: #f4f7f8;
      }
    }
    &.primary {
      background-color: #000000;
      color: #ffffff;
      &:hover {
        color: #1fda8b;
        background-color: #000000;
      }
    }
    &.DarkGrey {
      background-color: #DBE1E3;
      color: #000;
      &:hover {
        color: #ffffff;
        background-color: #1fda8b;
      }
    }
  }
}
