@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.comment-box {
  .comment-box-body {
    display: flex;
    align-items: center;

    .avatar {
      width: 40px;
      display: flex;

      svg {
        font-size: 30px;
      }
    }

    .input {
      width: 100%;
      background-color: #f4f7f8;
      color: #000;
      padding: 12px;
      position: relative;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .replying {
        display: flex;
        flex-direction: column;
        gap: 4px;
        position: relative;

        .top-info {
          height: 16px;
          color: rgba(0, 0, 0, 0.6);
          position: relative;
          display: flex;
          gap: 4px;
          align-items: center;
          font-size: 13px;
        }

        .close-response {
          position: absolute;
          right: 0;
          margin: auto;
          bottom: 0;
          top: 0;
          width: 16px;
          height: 16px;
          border-radius: 50px;
          border: 1px solid rgba(0, 0, 0, 0.6);
          padding: 0;

          .MuiSvgIcon-root {
            font-size: 12px;
          }
        }

        .bottom-info {
          word-break: break-word;
          margin-right: 25px;
          color:
            rgba(0, 0, 0, 0.6);
        }
      }

      .MuiInputBase-root {
        padding: 0;
      }

      textarea {
        padding-right: 30px;
        color: #000;
        padding-top: 0px;
      }

      .action-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left,
        .right {
          display: flex;
          align-items: center;

          .edit-buttons {
            display: flex;
            gap: 16px;

            span {
              cursor: pointer;
              display: flex;
            }
          }
        }
      }

      .icon-video-message {
        font-size: 20px;
        cursor: pointer;

        &.disabled {
          opacity: 0.2;
        }
      }

      .attach {
        display: flex;
        margin-right: 12px;

        svg {
          color: $primary-color;
          cursor: pointer;
        }
      }

      .send {
        display: flex;
        margin-right: 7px;

        svg {
          color: $primary-color;
          cursor: pointer;
        }
      }

      .icon-send {
        font-size: 18px;
        cursor: pointer;
        color: #1fda8b;

        &.disabled {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .slack-link {
        position: absolute;
        bottom: 12px;
        left: 18px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        right: 10px;
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        align-items: center;
        padding-right: 25px;
        gap: 8px;

        &.re-connect-container {
          justify-content: space-between;
        }

        span {
          color: rgba(0, 0, 0, 0.4);
          font-size: 15px;
          font-family: 'MediumLLWeb-Regular';
          line-height: 24px;

          &.no-channel {
            cursor: pointer;
          }
        }

        .external-chat-reconnect-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          gap: 5px;
          background-color: rgba(219, 225, 227, 0.2);
          border-radius: 4px;
          padding: 0 8px;
          cursor: pointer;
          min-width: 82px;

          span {
            font-size: 10px;
            line-height: 21px;
          }

          svg {
            font-size: 13px;
          }

          .icon-spinner {
            font-size: 9px;
            color: #fff;
          }
        }

        a:link {
          color: #76a2fe;
        }

        a:visited {
          color: #76a2fe;
        }
      }
    }
  }

  .validation {
    font-size: 15px;
    color: #f44a4a;
  }

  .attach-file {
    margin-left: 0;
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .MuiLinearProgress-barColorSecondary {
      background-color: #1fda8b !important;
    }

    .MuiLinearProgress-colorSecondary {
      background-color: #DBE1E3;
    }

    .MuiBox-root {
      gap: 16px;
    }

    .MuiLinearProgress-root {
      height: 2px;
    }

    .MuiTypography-root {
      color: $primary-color;
      font-family: 'MediumLLWeb-Regular';
      font-size: 13px;
    }

    .cancel {
      cursor: pointer;
      padding-left: 10px;
      color: $primary-color;
    }

    .uploaded-file {
      display: flex;
      align-items: center;
      background: #ffffff;
      border: 1px solid #DBE1E3;
      border-radius: 4px;
      padding: 8px 12px;
      justify-content: space-between;

      .file-name {
        font-size: 15px;
        color: $primary-color;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .remove {
        svg {
          font-size: 18px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
}