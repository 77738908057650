@import '../responsiveUtils.scss';

.info-space-button.MuiIconButton-root {
  padding: 0px;
  width: 15px;
  height: 15px;

  .MuiIconButton-label {
    span.icon-new-info {
      color: rgba(0, 0, 0, 0.6);
      font-size: 15px;

      .path2:before {
        color: rgba(0, 0, 0, 0.6);
      }

      &:hover,
      &:focus {
        color: #000000;

        .path2:before {
          color: rgba(0, 0, 0, 0.6);
        }
      }

    }
  }
}

.space-details-popup {
  .MuiPopover-paper {
    top: 40px !important;
    width: 500px;
  }

  &.responsive-dialog .close-button {
    top: 11px;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 34px;

    .text-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'MediumLLWeb-Bold';
    }

    .edit-button {
      padding: 5px;
    }

    .close-button {
      position: absolute;
      right: 24px;
      width: 24px;
      height: 24px;
      color: #000;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
  }

  label {
    font-family: 'MediumLLWeb-Bold';
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 4px;
  }

  .row-content {
    display: flex;
    align-items: center;
    word-break: break-all;

    &.privacy-level {
      gap: 8px;
    }

    .copy-link {
      padding: 0;
      width: 100%;

      .generic-url-text {
        max-width: 350px;
        justify-content: space-between;

        .MuiOutlinedInput-input {
          padding: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 0;
        }
      }

      .btn-copy-link {
        background: #f4f7f8;
        border-radius: 4px;
        font-size: 10px;
        line-height: 16px;
        // height: 24px;
        padding: 4px 8px;

        .MuiButton-label {
          text-transform: uppercase;
          font-family: 'MediumLLWeb-Bold';
        }

        // &:focus,
        &:hover {
          color: #000;
          background-color: #c3c7c8;
        }

        // &.show-link-copied {
        //   color: #000;
        //   background-color: #1fda8b;
        // }
      }
    }

    &.tags {
      gap: 4px;
    }
  }

  .alert-info {
    &>span.icon {
      margin-right: 16px;
    }

    &>div {
      font-size: 13px;
    }
  }
}

@include media(phone) {
  .space-details-popup.responsive-dialog {
    .MuiDialog-paper {
      width: 100%;
      max-height: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }

    .close-button {
      right: 10px;
      top: 10px;
    }
  }

  .info-space-button.MuiIconButton-root .MuiIconButton-label {
    font-size: 16px;
  }
}