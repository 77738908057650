.ex-storage-noconnect-info {
  padding: 40px;
  text-align: center;

  &.disabled {
    display: none;
  }

  .ex-storage-logo {
    font-size: 32px;
    margin-bottom: 30px;
  }

  .primary-text {
    margin-bottom: 5px;
  }

  .secondary-text {
    color: rgba(0, 0, 0, 0.6);
    margin-top: 0;
    margin-bottom: 25px;
  }
}
.root-location {
  font-size: 12px;
}
.actions-header .resource-search-field {
  width: 100%; // prevent using important, It will be override the codes, need the parent class to find it
}
.resource-item-thumbnail {
  display: flex;
  align-items: center;
}
