@import url('https://fonts.googleapis.com/css?family=Work+Sans');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;700;900&display=swap');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@import 'icon/style.css';
@import 'icon/icons.scss';
@import './variables.scss';
@import './responsiveUtils.scss';
@import './fonts/fonts.scss';
@import './styles/alert.scss';
@import './styles/popover.scss';

:root {
  --primary-color: #000000;
  --primary-color-rgb: 0, 0, 0;
  --primary-color-light: #17171a;
  --primary-color-variant: #222228;
  --secondary-color: #ffffff;
  --secondary-color-light: #f4f6f8;
  --secondary-color-variant: #c8c8c8;
  --preview-primary-color: #270095;
  --preview-secondary-color: #1649f5;
  --green-color: #00cd7f;
  --border-color: #eaeaea;
}

html,
body {
  font-family: 'MediumLLWeb-Regular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  color: $primary-color;
  letter-spacing: normal;
}

b {
  font-family: 'MediumLLWeb-Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'F37Judge-MediumCondensed', 'MediumLLWeb-Bold', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  letter-spacing: 0.5px;
}

button {
  &:focus {
    outline: none;
  }
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icon-spinner {
  -webkit-animation: spin 3s linear infinite;
  -moz-animation: spin 3s linear infinite;
  animation: spin 3s linear infinite;
  color: rgb(0, 0, 0);
}

.authorization-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 50px;
  left: 0;
}

.app {
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .clickable {
    cursor: pointer;
  }

  // &.app-runtime {
  //   --primary-color: #000000;
  //   --primary-color-light: #17171a;
  //   --primary-color-variant: #646464;
  //   --secondary-color: #ffffff;
  //   --secondary-color-light: #f4f6f8;
  //   --secondary-color-variant: #c8c8c8;
  //   --preview-primary-color: #270095;
  //   --preview-secondary-color: #1649f5;
  // }

  img.logo {
    height: 40px;
    min-width: 40px;
  }

  img.thumbnail {
    image-rendering: auto;
  }

  i.active-icon {
    color: $green-color !important;
  }

  .app-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 35px;
    position: relative;
    // background-image: url('./images/People.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // height: 6vh;
    height: 60px;
    z-index: 10;

    .app-header-bg {
      background-color: $primary-color;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      opacity: 0.8;
    }

    a {
      z-index: 1;

      img {
        // width: 160px;
        padding: 10px 0 2px;
        max-height: 45px;
      }
    }

    .right-items {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        z-index: 1;
        text-decoration: none;
        padding: 0 40px 0 0;
        font-weight: bold;
        font-size: 14px;
        color: #fff;
      }
    }
  }

  .banner {
    width: 100%;
    position: fixed;
    top: 70px;
    z-index: 99;
  }

  .regular-rounded-button {
    border-radius: 100px;
    padding: 8px 16px;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;

    &.black-btn-white-uppercase-mediumllweb-bold-text {
      font-family: $FMediumBold;
      font-weight: unset;
      text-transform: uppercase;
      color: $secondary-color;
      background-color: $primary-color;
    }
  }

  .btn {
    border-radius: 100px;
    padding: 10px 15px;
    font-size: 15px;
    line-height: 20px;
    text-transform: uppercase;
    font-family: $FMediumBold;
    text-decoration: none;
    max-height: 40px;

    &-primary {
      color: $secondary-color;
      background-color: $primary-color;

      &:hover {
        background-color: $primary-color;
        color: #1fda8b;
      }
    }

    &-secondary {
      background-color: #f4f6f8;
      color: rgba(0, 0, 0, 0.4);

      &:hover {
        color: $primary-color;
        background-color: #eceeef;

        &:focus {
          color: $primary-color;
          background-color: #dbe1e3;
        }
      }
    }

    &-share-link {
      color: $secondary-color;
      background-color: $primary-color;

      &:hover {
        background-color: $primary-color;

        &:hover {
          background-color: $primary-color;
          color: #1fda8b;
        }

        &.opened {
          color: #1fda8b;
        }
      }
    }

    &-gray {
      color: $secondary-color;
      background-color: rgba(219, 225, 227, 0.2);
    }

    &-red {
      color: $secondary-color;
      background-color: #f44a4a;

      &:hover {
        background-color: #d40c0c;
        color: $secondary-color;
      }

      &:focus {
        background-color: #d40c0c;
        color: $secondary-color;
      }
    }

    &-gray-text {
      background-color: $secondary-color;
      color: rgba(0, 0, 0, 0.4);

      &:hover,
      &:focus {
        color: $primary-color;
        background-color: $secondary-color;
      }
    }

    &:disabled {
      color: $secondary-color;
    }

    &-green {
      color: $primary-color;
      background-color: $green-color;

      &:hover {
        color: $secondary-color;
        background-color: $green-color;
      }

      &:disabled {
        background-color: #eceeef;
        color: rgba(0, 0, 0, 0.3);
      }
    }

    &-icon {
      padding: 4px 8px 4px 4px;
      font-size: 10px;
      line-height: 16px;
      border-radius: 4px;

      &.small {
        .MuiSvgIcon-root {
          font-size: 14px;

          &.active {
            opacity: 0.3;
          }
        }
      }

      &.gray {
        background-color: #f4f7f8;
        color: $primary-color;
      }

      &.primary {
        background-color: $primary-color;
        color: $secondary-color;
      }
    }

    &-outline {
      color: #000;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #dbe1e3;
      text-transform: capitalize;
      font-family: $FMediumRegular;
      font-weight: normal;
      line-height: 18px;

      &:hover {
        background-color: #fff;
        border-color: #000;
      }

      &.active {
        background-color: #000;
        color: #fff;
      }
    }

    &-small {
      padding: 6px 8px;
      font-size: 10px;
      line-height: 12px;
      min-width: 48px;
    }
  }

  .MuiInputBase-root {
    font-family: $FMediumRegular;
    letter-spacing: normal;
  }

  .MuiTypography-root,
  .MuiButton-root {
    letter-spacing: normal;
  }

  .large-button {
    font-family: 'F37Judge-MediumCondensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    min-height: 80px;

    &:hover {
      color: $green-color;
    }

    &.back-button {
      background-color: #414141;
    }
  }

  >.app-container {
    max-width: none;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0;
    position: relative;
    flex-direction: column;
    flex: 1;

    >.app-body {
      display: flex;
      flex: 1;
      overflow: hidden;
      background-color: #eceeef;

      >section {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
      }
    }

    &.fullscreen {
      .admin-container {
        height: unset;
        display: flex;
        flex-grow: 1;
      }
    }

    .pulic-login-menu {
      position: absolute;
      top: 36px;
      right: 40px;
    }

    .admin-container {
      background-color: #f7f6ff;
      height: calc(100% - 95px);
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}

.spce-link {
  color: #4783ff;
  font-family: $FMediumBold;
  cursor: pointer;
}

.notification-mes {
  .MuiAlert-root {
    color: white;
    background-color: rgba(65, 65, 65, 0.8);

    .MuiAlert-message {
      padding: 14px 0;

      .MuiAlertTitle-root {
        font-weight: bold;
        color: white;
      }
    }

    .MuiAlert-icon {
      display: flex;
      align-items: center;
    }
  }

  .opacity60 {
    opacity: 0.6;
  }
}

.page-not-found {
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;

  >div {
    margin: auto;
    text-align: center;

    >p {
      margin-top: 20;

      &.text {
        font-size: 1em;
        opacity: 1;
        font-weight: bold;
      }

      &.description {
        font-size: 1em;
        opacity: 0.7;
        margin-top: 10;
      }
    }
  }
}

.styled-scrollbar {
  &::-webkit-scrollbar {
    // width: 8px;
    // height: 8px;
    width: 0.6em;
    background-color: $secondary-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondary-color-variant;
  }
}

.hidden-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.rating {
  display: flex;
  margin-left: -2px;

  svg {
    font-size: 14px;
  }
}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullwidth {
  width: 100%;
}

.content-editable {
  outline: none;
  padding: 5px;

  &.error {
    color: red;
  }
}

.image-viewer {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;

  img {
    max-width: 100%;
  }
}

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $secondary-color;
  border-radius: 6px;

  &.disabled {
    >div {
      pointer-events: none;
    }

    .azuremediaplayer {
      .vjs-big-play-button {
        display: none !important;
      }
    }
  }

  video {
    outline: none;
  }
}

@media (orientation: landscape) and (max-width: 850px) {
  .app {
    >.app-container {
      overflow: visible !important;
    }
  }
}

@include media(phone, landscape) {
  .landscape {
    height: 100vh;
  }
}

.mobile {
  visibility: hidden;
  height: 0;
  opacity: 0;
}

.desktop {
  visibility: visible;
  height: auto;
  opacity: 1;
}

.bold {
  font-family: $FMediumBold;
}

.avatar-container {
  width: 40px;
  display: flex;
  align-self: flex-start;

  .avatar {
    display: flex;
    align-items: center;
    width: 25px;
    height: 25px;
    background-color: #00cd7f;
    border-radius: 6px 0px 0px 6px;
    justify-content: center;
    border: 1px solid #17171a;
    box-sizing: border-box;

    span {
      font-family: $F37Judge;
      text-transform: uppercase;
      color: $primary-color;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
    }
  }
}

.conference-user-field {
  padding-left: 0;
  max-height: 400px;
  overflow: auto;
  margin: 0;

  .MuiAutocomplete-option {
    color: $primary-color;
  }

  .MuiAutocomplete-option[aria-disabled='true'] {
    opacity: 0.6;
  }

  span.user-label {
    position: relative;
    min-height: 40px;
    display: flex;
    flex: 1;
    align-items: center;

    .icon-urgent {
      position: absolute;
      right: 10px;
      width: 20px;
      height: 20px;
      display: flex;
      font-size: 10px;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: $secondary-color;
      color: #fff;
    }
  }
}

.menu-action-buttons {
  .MuiPaper-root {
    background-color: $secondary_color;
    padding: 4px 0;
    min-width: 200px;

    .MuiMenuItem-root {
      display: flex;

      .MuiListItemIcon-root {
        min-width: 30px;
        color: #000;
      }

      .MuiTypography-noWrap {
        color: $primary-color;

        .file-access-level-string {
          font-size: 14px;
          padding-left: 20px;
          opacity: 0.6;
        }
      }

      .arrow-forward-icon {
        display: flex;
        margin-left: 60px;
        cursor: pointer;

        .MuiSvgIcon-root {
          font-size: 15px;
          justify-self: center;
        }
      }

      .icon-convert {
        &::before {
          color: #000;
        }
      }
    }

    .MuiListItem-root.Mui-disabled {
      pointer-events: auto; // to show tooltip on a disabled button/item in ActionButtons component
    }
  }

  .icon-send-email {
    font-size: 18px;
  }
}

.action-buttons-item-tooltip {
  margin-top: 18px !important;
}

.large-rounded-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;

  button {
    font-family: 'F37Judge-MediumCondensed', 'Helvetica Neue', Helvetica, Arial, sans-serif;

    &:hover {
      color: $green-color;
    }
  }

  >span.message {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 30px;
  }

  .MuiButton-fullWidth {
    height: 75px;
    border-radius: 0;
  }

  .MuiButton-label {
    font-weight: 500;
    font-size: 30px;
    line-height: 30px;
  }
}

.loading-container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  span,
  p {
    text-align: center;
    white-space: break-spaces;
  }
}

.loading-box {
  display: flex;
  align-items: center;
  padding: 16px;
  flex: 1;
}

.MuiAutocomplete-popper {
  .MuiAutocomplete-paper {
    color: $secondary-color;
  }
}

.text-truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-black {
  color: #000;
}

.text-gray {
  color: rgba(0, 0, 0, 0.6);
}

.text-red {
  color: #f44a4a;
}

.blink_me {
  animation: blinker 3s linear infinite;
}

.btn-back-home {
  position: absolute;
  z-index: 99;
  right: 128px;
  top: 8px;
}

.flex-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
}

.hidden {
  display: none;
}

.blue-dot {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: #4783ff;
  display: inline-block;
}

.disableHovering {
  &:hover {
    cursor: default !important;
    background-color: transparent !important;
  }
}

.waypoint-holder {
  z-index: -9999;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.waypoint-viewing-monitor {
  z-index: 99999;
  position: absolute;
  top: 20%;
  left: 10px;
  bottom: 20%;
  width: 1px;
}

.load-more {
  display: block;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.text-link {
  color: #76a2fe;
  text-decoration: underline;
  font-family: $FMediumBold;
  font-size: 15px;
  transition: 0.3s ease all;
  cursor: pointer;

  &:hover {
    color: #4783ff;
  }
}

.select-menu {
  &.MuiPaper-root {
    border-radius: 4px;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
    margin-top: 4px;
  }

  .MuiMenuItem-root {
    // padding-top: 8px;
    // padding-bottom: 8px;
    min-height: 40px;

    &.Mui-selected,
    &.Mui-selected:hover {
      background-color: #f4f7f8;
    }
  }

  &-top.MuiPaper-root {
    margin-top: -8px;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@include media(phone) {
  .app {
    overflow: hidden;

    .runtime-drawer {
      z-index: 100;
    }

    .app-container.runtime,
    .salesroom-container {
      .space-content-dialog {
        right: 0;
        // top: 56px;
      }

      .styled-scrollbar {
        &::-webkit-scrollbar {
          width: 0.3em;
          background-color: transparent;
        }
      }
    }
  }

  img.logo {
    height: 40px;
    max-width: 60px;
    min-width: 40px;
  }
}

@include media(phone, landscape) {
  .orientation-lock {
    /* Rotate the content container */
    transform: rotate(-90deg);
    transform-origin: left top;
    /* Set content width to viewport height */
    width: calc(100vh - 40px);
    /* Set content height to viewport width */
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

@supports (-webkit-touch-callout: none) {

  /* CSS specific to iOS devices */
  body {
    overscroll-behavior-y: contain;
  }
}