@import '../variables.scss';

.global-resources-tabs {
  display: flex;
  gap: 10px;
  .tabs.tabs-level-2 {
    margin: 0;
    flex: 1;
    padding-top: 0;
  }
}
