.vaam-participant-select {
  padding: 0 24px;
  padding-right: 12px;
  height: 100%;

  .MuiCheckbox-colorPrimary.Mui-checked {
    color: #000;
  }

  .participant-list {
    padding: 16px 0;
    height: 100%;
    overflow: hidden;

    &-container {
      overflow: auto;
      height: 80%;
      min-height: 300px;
      scrollbar-gutter: stable;
      scroll-snap-align: start;
      overscroll-behavior: contain;

      &::-webkit-scrollbar {
        width: 0.4em;
        background-color: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background: #dbe1e3;
        border-radius: 6px;
      }
    }

    .participant-list-header {
      margin-bottom: 16px;
      .list-title {
        font-weight: bold;
      }
      .list-subtitle {
        opacity: 0.6;
      }
    }

    .select-all {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 6px;
      .MuiCheckbox-root {
        &.Mui-checked {
          color: #000;
        }
      }
    }

    &-item {
      .form-checkbox {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 8px 0;

        &.MuiFormControlLabel-labelPlacementStart {
          margin-left: 0;
        }
      }

      .MuiFormControlLabel-label {
        width: 90%;
      }

      .participant-item-label {
        display: flex;
        align-items: center;

        .user-avatar {
          margin-right: 16px;
        }

        .info {
          min-width: 200px;
        }

        .info-title {
          display: flex;
          gap: 10px;

          .name {
            line-height: 24px;
            // font-weight: bold;
          }
        }

        .description {
          font-size: 13px;
          opacity: 0.6;
          height: 16px;
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
