.space-blocking-access {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f6f8;
  display: flex;
  flex-direction: column;
  z-index: 9999;

  .header {
    .preview-meeting-header {
      button {
        box-shadow: none;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex: 1;
    margin: 20px;
    background: #fff;
    padding: 0 20%;
    overflow: auto;

    .firewall-buttons {
      margin-top: 20px;
      display: flex;
      gap: 10px;
      .white-list-button {
        background-color: #1fda8b;
      }
    }

    .body-content {
      flex: 1;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
    }

    .headline {
      font-family: F37Judge-MediumCondensed;
      font-size: 48px;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }

    .title-bold {
      font-family: 'MediumLLWeb-Bold';
      margin: 0;
      font-size: 15px;
      color: rgba(0, 0, 0, 0.6);
    }

    .description {
      opacity: 0.5;
      margin: 0;
      font-size: 15px;
    }

    .body-footer {
      padding: 20px 0;
      font-size: 12px;

      a {
        font-family: MediumLLWeb-Bold;
        opacity: 1 !important;
      }
    }
  }
}
