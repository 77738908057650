@import '../../variables.scss';

.conferenceuser-invitation-dialog {
  .MuiDialog-paper {
    width: 50%;
    max-width: 600px;
  }

  .content {
    // min-width: 600px;
    width: 100%;
    min-height: 200px;
    padding: 20px;
  }
}

.participant-info-container {
  margin-top: 25px;
  position: relative;
  background-color: $primary-color-light;
  padding: 10px 20px 20px;
  border-radius: 6px;
  min-height: 93px;

  .title {
    margin: 15px 0;
  }

  .icon-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  .MuiTypography-body1 {
    color: $secondary_color;
  }

  .MuiTypography-body1 {
    color: $secondary_color !important;
  }

  .item-label {
    color: $secondary_color;
    margin-bottom: 5px;
  }

  .MuiInput-underline {
    &:before {
      border: none !important;
    }
    &:hover {
      border: none !important;
    }
    &:after {
      border: none !important;
    }
  }

  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 0 !important;
  }

  .MuiAutocomplete-endAdornment {
    display: none;
  }

  .MuiInputBase-root {
    background-color: #f4f6f8;
    color: #000;
    border-radius: 6px;
    min-height: 40px;

    input {
      padding: 0 15px !important;
    }
    input:-webkit-autofill {
      box-shadow: 0 0 0 50px #f4f6f8 inset; /* Change the color to your own background color */
      -webkit-text-fill-color: #000;
    }

    input:-webkit-autofill:focus {
      box-shadow: 0 0 0 50px #f4f6f8 inset; /*your box-shadow*/
      -webkit-text-fill-color: #000;
    }
  }

  .MuiFormHelperText-root.Mui-error {
    margin-left: 0 !important;
  }

  .MuiSvgIcon-root {
    color: #fff;
  }

  .buttons {
    margin: 20px 0 0;

    button {
      min-height: 40px;
      max-height: 45px;

      span {
        font-size: 20px;
      }
    }
  }
}
