@import '../variables.scss';

.play-video-button.MuiIconButton-root {
  z-index: 200;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 160px;
  width: 160px;
  background-color: rgba(60,69,79,.7);
  border: 2px solid #384049;
  border-radius: 0;
  box-shadow: 0 0 1px rgba(255,255,255,.25);
  color: #fff;
  cursor: pointer;
  padding: 0 1px;
  text-align: center;
  .MuiSvgIcon-root {
    text-shadow: 0 0 1px #000;
    font-size: 125px;
  }
  &:hover {
    background-color: rgba(60,69,79,.7);
    border: 2px solid #fff;
  }
}