.table-pagination {
  padding: 24px;

  .select-rows-per-page {
    .MuiSelect-root {
      padding: 8px 44px 8px 12px;
      font-size: 15px;
      line-height: 24px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #dbe1e3;
    }

    .MuiSelect-icon {
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .MuiPaginationItem-page {
    font-family: 'MediumLLWeb-Bold';
    font-size: 13px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.6);
    min-width: 24px;
    width: 24px;
    height: 24px;
    padding: 4px;
    margin: 0 6px;
    background-color: #f4f7f8;
    border: none;

    &.Mui-selected {
      background-color: #000;
      color: #fff;
    }

    .MuiPaginationItem-icon {
      font-weight: 700;
    }

    &:hover {
      color: #000;

      &.Mui-selected {
        color: #fff;
      }
    }
  }

  .info {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

  .MuiPaginationItem-ellipsis {
    color: rgba(0, 0, 0, 0.6);
    font-family: 'MediumLLWeb-Bold';
    width: 24px;
  }
}