@import '../../responsiveUtils.scss';

.google-drive-connection-dialog {
  &.hidden {
    display: none;
  }
  .MuiDialog-paper {
    background-color: #ffffff;
    width: 500px;
    min-height: 450px;
    color: #000;
    backdrop-filter: blur(20px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .content {
    width: 100%;
    padding: 30px 20px 40px;
    padding-bottom: 100px;
    text-align: center;

    .title {
      padding-top: 25px;
      font-size: 18px;
      line-height: 24px;
      font-family: 'MediumLLWeb-Bold';
      text-transform: uppercase;
    }

    .icon-googledrive {
      font-size: 48px;
    }

    .message {
      margin: 16px 0;
      font-size: 15px;
      line-height: 20px;
      font-family: 'MediumLLWeb-regular';
      display: block;
      text-align: center;
    }

    .note {
      padding: 20px 0;
      color: #666666;
    }
  }

  .icon-check-circle {
    font-size: 20px;
    margin-right: 13px;
  }

  .MuiListItem-root {
    padding: 0 10px;
  }

  .MuiListItemIcon-root {
    min-width: 34px;
  }

  .MuiAlert-standardWarning {
    background-color: #faf2fd;
    color: #000;
    margin: 12px 4px;
    text-align: left;
    .MuiAlert-icon {
      color: #ad2de1;
    }
  }

  .buttons {
    width: 100%;
    position: absolute;
    bottom: 44px;
    padding: 0 24px;
    height: auto;
    display: inline-block;

    .google-drive-login-button {
      position: relative;
      width: 100%;
      justify-content: center;

      .drive-icon {
        position: absolute;
        left: 13px;
        top: 11px;
        font-size: 18px;
      }
    }

    .button {
      margin: auto;
      width: 100%;
      height: 100%;
      color: #fff;
      text-transform: uppercase;
      font-family: 'F37 Judge';
      font-size: 30px;

      &.confirm {
        background-color: #000;
      }

      &.MuiButton-root {
        border-radius: 0;
      }

      .MuiButton-label {
        font-family: 'F37Judge-MediumCondensed';
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        color: #fff;
      }
    }
  }
}
