@import '../responsiveUtils.scss';

.spce-popover.share-space-popover {
  .MuiPopover-paper {
    margin-left: 0px;
    width: 400px;
    .content {
      padding-top: 0;
      .sub-title {
        font-family: 'MediumLLWeb-Bold';
        margin-top: 10px;
        padding-left: 24px;
      }
      .row-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px;
      }

      .tab-content {
        display: flex;
        width: 100%;
        flex-direction: column;

        .check-box {
          padding: 20px 24px;
          .MuiFormControlLabel-root {
            margin-right: 0;
            align-items: start;
            .MuiCheckbox-root {
              margin-top: -8px;
              margin-right: 8px;
            }
          }
        }

        .invite-warning {
          padding: 24px;

          .alert-info {
            background-color: #F4EFF7;
            margin-right: 50px;
            .icon {
              &:before {
                color: #AD2DE1;
              }
            }
          }
        }
      }

      .MuiDivider-root {
        background-color: #eceeef;
      }

      .copy-link {
        padding: 24px;
      }
    }
  }
}


@include media(phone) {
  .spce-popover.share-space-popover .MuiPopover-paper {
    top: unset !important;
    bottom: 0 !important;
    left: 0 !important;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    .content {
      .tabs {
        margin-top: 24px;
        padding: 0 16px;
      }

      .copy-link {
        padding: 14px 16px 24px;
        gap: 16px;
        .generic-url-text {
          width: unset;
          flex: 1;
        }
      }
    }
  }
}