@import '../../variables';

.dnd-file-uploader-common-text-style {
  font-family: 'MediumLLWeb-Regular';
  font-size: 12px;
  line-height: 16px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  border-radius: 0;

  .upload-progress {
    color: $secondary-color;
    background-color: #47484d;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px 16px;
    border-radius: 4px;
    align-items: unset;
    margin: 0;

    .MuiLinearProgress-root {
      width: 100%;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.2);
      margin: 8px 0;

      .MuiLinearProgress-bar {
        background-color: #1fda8b;
      }

      &.failed {
        .MuiLinearProgress-bar {
          background-color: #ff7f7f;
        }
      }
    }

    .file-name {
      font-size: 15px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }

    .bottom-section {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .message {
        font-size: 12px;
        line-height: 20px;
        color: rgba(255, 255, 255, 0.6);

        &.failed {
          color: #ff7f7f;
        }

        &.completed {
          color: #1fda8b;
        }
      }

      .action-button {
        padding: 0;
        min-width: 0;

        .MuiButton-label {
          font-family: 'MediumLLWeb-Regular';
          font-size: 12px;
          line-height: 20px;
          font-weight: unset;
          color: rgba(255, 255, 255, 0.6);
          text-transform: capitalize;
        }
      }
    }
  }

  .upload-area {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .upload-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .upload-btn {
        border-radius: 4px;
        border: 1px dashed $secondary_color;
        box-sizing: border-box;
        cursor: pointer;
        min-height: 88px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1;
        min-width: auto;
        width: auto;
        padding: 0;

        &.disable-area {
          cursor: default;
        }

        img {
          width: 40px;
        }

        p {
          text-align: center;
          margin: 0;
          padding: 0;
          color: $secondary_color;
          font-size: 13px;
          line-height: 16px;
          width: 100%;
          white-space: nowrap;
          font-family: 'MediumLLWeb-Regular';
        }

        a {
          // color: #76a2fe;
          color: #4783ff;
          cursor: pointer;
          text-decoration: underline;
          font-family: 'MediumLLWeb-Bold';
        }
      }
    }
  }

  .dnd-file-uploader-control-label-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 4px;

    .dnd-file-uploader-control-label {
      @extend .dnd-file-uploader-common-text-style;
      color: $secondary-color;
    }

    .icon-info {
      font-size: 13.33px;
      margin-left: 5.33px;
      cursor: pointer;

      &::before {
        color: rgba(0, 0, 0, 0.6);
      }

      &:hover {
        &::before {
          color: #000;
        }
      }
    }
  }

  .uploader-completed {
    .text {
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .uploaded-folder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid #dbe1e3;
    padding: 8px 12px;
    overflow: hidden;

    .folder-name {
      display: flex;
      align-items: center;
      overflow: hidden;
      gap: 12px;

      .folder-name-text {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block;
      }

      .icon-folder {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .delete-icon-button {
      padding: 0;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  * {
    &.disabled {
      pointer-events: none;
      cursor: default;

      .icon-info {
        pointer-events: auto;
        cursor: pointer;
      }

      .upload-btns .upload-btn {
        background: rgba(219, 225, 227, 0.2);
        border: 1px dashed rgba(255, 255, 255, 0.3);

        p {
          opacity: 0.4;
        }
      }

      .dnd-file-uploader-control-input .MuiOutlinedInput-root {
        background-color: rgba(219, 225, 227, 0.2) !important;
        color: rgba(255, 255, 255, 0.3) !important;
      }
    }
  }

  .form-warning {
    color: #f44a4a;
    font-size: 13px;
    line-height: 16px;
    text-align: left;
  }

  &.light {
    .dnd-file-uploader-control-label-wrapper {
      .icon-info::before {
        color: rgba(0, 0, 0, 0.6);
      }

      .dnd-file-uploader-control-label {
        color: $primary-color;
      }
    }

    .upload-area {
      color: $primary-color;

      .upload-btns {
        .upload-btn {
          border-color: rgba(0, 0, 0, 0.6);

          p {
            color: $primary-color;
          }
        }
      }
    }

    .upload-progress {
      color: $primary-color;
      background-color: #f4f7f8;

      .MuiLinearProgress-root {
        background-color: #dbe1e3;
      }

      .action-button .MuiButton-label,
      .bottom-section .message {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .uploaded-folder {
      .folder-name .icon-folder {
        color: #dbe1e3;
      }

      .delete-icon-button {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .uploader-completed {
      .text {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .upload-errors-container {
      border-top-color: #eceeef;
      box-shadow: none;

      &.MuiAccordion-root:before {
        display: none;
      }

      &.MuiPaper-root {
        color: #000;

        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .upload-error {
        border-color: #dbe1e3;
      }
    }
  }
}

.upload-errors-container {
  margin-top: 24px;
  padding-top: 4px;
  border-top: 1px solid rgba(255, 255, 255, 0.6);

  &.MuiPaper-root {
    background-color: transparent;
    color: #000;
    box-shadow: none;
  }

  .MuiSvgIcon-root {
    color: #000;
  }

  .MuiIconButton-edgeEnd {
    margin-left: -22px;
    margin-right: 0;
  }

  .upload-errors-header {
    flex-direction: row-reverse;

    .MuiAccordionSummary-content {
      font-family: $FMediumBold;
    }

    &.MuiAccordionSummary-root {
      min-height: unset !important;
      margin: 0;
    }
  }

  .MuiAccordionDetails-root {
    padding: 8px 0;
    flex-direction: column;
    gap: 8px;
  }

  .upload-error {
    border: 1px solid #dbe1e3;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 15px;
    line-height: 24px;

    .error-message {
      color: #f44a4a;
      font-size: 13px;
      line-height: 16px;
      text-align: left;
    }

    .error-message-filename {
      overflow: hidden;
      word-break: break-all;
    }
  }
}

.marginTop16 {
  margin-top: 16px;
}