@import '../../responsiveUtils.scss';

.table-container.participants-table {
  padding: 0 8px;

  .table {
    .header {
      background-color: #fff;
      font-family: 'MediumLLWeb-Bold';
      font-size: 10px;
      line-height: 16px;
      color: #000;
      text-transform: uppercase;
    }

    .MuiTableCell-root {
      border-bottom: none;
      padding: 8px 16px;

      .title {
        display: flex;
      }

      &.MuiTableCell-head {
        &:hover {
          background-color: #f4f7f8;
        }
      }
    }

    .MuiTableSortLabel-icon {
      font-size: 16px;
    }

    .MuiTableSortLabel-root {
      display: flex;
      white-space: nowrap;

      &.MuiTableSortLabel-active {
        color: #000;

        &.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
          color: #000;
        }
      }

      &:hover {
        color: #000;

        .MuiTableSortLabel-icon {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }


    .action-button {
      width: 40px;
      max-width: 40px;
      padding-right: 0;
      padding-left: 0;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }

    .btn-three-dots {
      padding: 4px;

      &:hover {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.04);
        border-radius: 100px;
      }
    }

    .participant-checkbox {
      width: 24px;
      padding-right: 0;

      .MuiCheckbox-colorPrimary {
        padding: 0;
      }
    }

    .participant-info {

      .content .name-role-block .name {
        display: inline-block;
        width: unset;
      }
    }

    .MuiTableRow-root.MuiTableRow-hover:hover {
      background-color: #f4f7f8;
    }

    .control-button {
      color: #000;
      margin-left: auto;
      padding: 8px;
      padding-right: 8px;
      width: 40px;
      height: 40px;

      &.active {
        color: #4783ff;
      }

      &:hover {
        background-color: #f4f7f8;
      }

      .icon-given-control {
        font-size: 16px;
      }
    }

    .last-modified-cell {
      width: 160px;
    }
  }

  &.participants-table-mobile {
    .table {
      table-layout: fixed;

      .participant-cell {
        width: 90%;
      }
    }
  }
}