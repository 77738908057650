.sort-select {
  .button {
    padding: 8px 12px;
    min-width: 150px;
    border-radius: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    height: 40px;

    &:hover,
    &.active {
      border-color: #000;
    }

    &.active {
      border-width: 2px;
    }

    .label {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
    }

    .icon {
      color: rgba(0, 0, 0, 0.6);
      width: 24px;
      height: 24px;
    }
  }
}