@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.google-login-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dadce0;
  color: #3c4043;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  border-radius: 4px;
  cursor: pointer;
  max-width: 100%;
  margin: auto;

  &:hover {
    box-shadow: none;
    border-color: #d2e3fc;
    outline: none;
    background-color: rgba(66, 133, 244, 0.04);
  }

  .google-icon {
    margin: 0 24px;
    font-size: 18px;
  }

  .label {
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 600;
    letter-spacing: .21875px;
    color: rgba(0,0,0,.54);
  }
}
