@import '../variables.scss';
@import '../responsiveUtils.scss';

.reset-password-container {
  background-color: $primary_color;
  display: flex;
  flex: 1;
  letter-spacing: normal;

  .left-column {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    color: #fff;

    .logo {
      position: absolute;
      left: 40px;
      top: 40px;
    }

    .form {
      background-color: #222228;
      max-width: 400px;
      min-width: 400px;
      padding: 24px;
      border-radius: 6px;

      .title {
        margin-top: 0;
        font-family: $FMediumBold;
        color: #fff;
        margin-bottom: 8px;
        font-size: 18px;
        line-height: 24px;
      }

      .description {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 24px;
        margin-top: 0;
      }

      .label {
        color: #fff;
        margin-bottom: 4px;
        display: block;
        font-size: 12px;
        line-height: 16px;
      }

      .success-message {
        color: #1FDA8B;
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 10px;
      }

      .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        border: 1px solid #fff;
      }

      .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border: 1px solid #fff;
      }

      .MuiInputBase-root {
        background-color: rgba(219, 225, 227, 0.2);
        color: #fff;
        border-radius: 6px;

        // min-height: 40px;
        .MuiInputBase-input {
          padding: 0px 12px;
          font-size: 15px;
          line-height: 20px;
          min-height: 40px;
        }

        input:focus {
          border-color: #fff;
        }

        input:-webkit-autofill {
          box-shadow: 0 0 0 50px #fff inset;
          /* Change the color to your own background color */
          -webkit-text-fill-color: #fff;
        }

        input:-webkit-autofill:focus {
          box-shadow: 0 0 0 50px #fff inset;
          /* Change the color to your own background color */
          -webkit-text-fill-color: #fff;
        }
      }

      .MuiSvgIcon-root {
        fill: rgba(255, 255, 255, 0.4);
      }

      .MuiInput-underline {
        &:before {
          border: none !important;
        }

        &:hover {
          // border: none !important;
          border: 1px solid rgba(219, 225, 227, 0.2);
        }

        &.Mui-focused {
          border-color: #fff;
        }

        &:after {
          border: none !important;
        }
      }

      .MuiFormHelperText-root.Mui-error {
        margin-left: 0 !important;
        margin-top: 8px;
      }

      .button-container {
        margin-top: 24px;
        text-align: left;

        .send-button {
          border-radius: 100px;
          padding: 8px 16px;
          font-size: 15px;
          line-height: 20px;
          background-color: #1fda8b;
          color: #000;
          min-width: 88px;
          height: 40px;

          &.Mui-disabled {
            background-color: rgba(65, 65, 65, 0.4);
            color: #fff;
          }
        }
      }
    }

    .helper-text {
      font-size: 12px;
      line-height: 16px;
      color: rgba(255, 255, 255, 0.6);
      margin-top: 4px;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  .reset-password-container {
    overflow-x: auto;

    .left-column {
      padding: 0 24px;
      flex: 1 1 100%;
      max-width: 100%;
      justify-content: flex-start;

      .logo {
        left: 24px;
        top: 24px;

        img {
          max-width: 100%;
        }
      }

      .form {
        margin-top: 88px;
        min-width: auto;
        width: 100%;
        background-color: transparent;
        padding: 0;
      }

      .go-back {
        width: 100%;
      }
    }

    .right-column {
      display: none;
    }
  }
}