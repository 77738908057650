/* stylelint-disable no-descending-specificity */
@import '../responsiveUtils.scss';
@import '../variables.scss';

.app {
  > .app-container {
    &.runtime {
      overflow: hidden;

      .styled-scrollbar {
        &::-webkit-scrollbar-thumb {
          background-color: #ddd;
        }
      }

      .recording-indicator {
        position: absolute;
        top: 0;
        left: 0;
        color: $secondary-color;
        z-index: 999;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;

        &::before {
          content: ' ';
          background-color: $primary-color-variant;
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          opacity: 0.9;
        }

        &.recorder {
          display: none;
        }

        i.icon-film-solid {
          font-size: 30px;
          padding: 15px;
        }

        &.stopped {
          background-color: #999;
        }
      }

      .app-header {
        .app-header-bg {
          background-color: $primary-color;
        }

        a {
          img {
            width: unset;
            max-height: 40px;
          }
        }
      }

      .runtime-client {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin: auto;
        width: 100%;
        // max-width: 1700px;
        height: calc(100% - 50px);
        position: relative;
        overflow: hidden;
        background-color: #f9f8fa;

        &.recorder {
          pointer-events: none;

          .player-container {
            height: 100%;
            margin: 0;
            border-radius: 0;
          }
        }

        .activity-item {
          background-color: $primary-color;

          .activity-background {
            background-color: $secondary-color !important;
          }
        }

        > .header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-right: 0;
          height: 8vh;
          min-height: 8vh;
        }

        .player-container {
          width: 100%;
          height: calc(100% - 50px);
          // padding-bottom: 56.25%;

          > .page-not-found {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }

        .player-widget {
          width: 100%;
          height: 100%;
        }

        .runtime-client-container {
          overflow: hidden;
          margin-top: 0;
          background-color: #fff;
          height: 100%;

          .runtime-widgets-container {
            align-content: flex-start;
            height: calc(100% - 20px);
            overflow: hidden;
            display: none;
            // order: 1;
            // height: 100%;

            .chat-tab {
              width: 100%;
            }
          }

          .widgets-tabs {
            margin-bottom: 10px;
          }

          .widgets {
            display: flex;
            // margin-bottom: 60px;
            height: 100%;
            overflow: hidden;
            margin-top: 6px;
            position: relative;

            .comments-left-column {
              width: 100%;
              // padding: 0 26px 0 0;
              padding-right: 10px;
              margin-top: 0;
              overflow: hidden;
              height: calc(100% - 20px);

              .comments-container {
                height: calc(100% - 80px);
                padding: 0;

                .comments-list {
                  height: 100%;
                  padding-bottom: 50px;
                }
              }

              .close-comment {
                position: absolute;
                top: -2px;
                // right: 14px;
                right: 0;
                z-index: 9;

                svg {
                  cursor: pointer;
                  font-size: 25px;
                }
              }
            }
          }

          .runtime-player {
            height: 100%;
            overflow: auto;
            -webkit-overflow-scrolling: touch;
            flex: 1;
            background-color: #f9f8fa;
            padding: 0 10px 10px;

            .actions-container {
              display: flex;
              justify-content: space-between;
              margin: 10px 0;
            }

            .joined {
              display: flex;
              align-items: center;
              justify-content: center;
              // padding-top: 5px;

              svg {
                color: $primary-color;
                font-size: 19px;
                margin-right: 3px;
              }

              span {
                font-size: 12px;
                color: $primary-color;
                margin-top: 3px;
              }
            }

            .buttons {
              display: flex;
              height: 30px;
              align-items: center;
              overflow: hidden;

              .actions {
                justify-content: flex-end;
                align-items: center;
                display: flex;
                overflow: hidden;

                > button {
                  margin-left: 15px;

                  .MuiButton-label {
                    > div {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    }
                  }
                }
              }
            }
          }
        }

        .configuration-menu {
          left: 10px;
          bottom: 10px;
        }

        .actions-menu {
          display: none;
        }
      }
    }
  }
}
