@import '../variables.scss';

.switch-enhanced {
  // background-color: $secondary_color;
  padding-left: 12px;
  padding-right: 3px;
  border-radius: 4px;

  .MuiSwitch-thumb {
    width: 18px;
    height: 18px;
  }

  .MuiSwitch-track {
    min-height: 22px;
    margin-top: -5px;
    border-radius: 25px;
    margin-left: -5px;
    min-width: 42px;
  }

  .Mui-checked {
    .MuiSwitch-thumb {
      background-color: $secondary_color !important;
    }
  }
}
