@import '../../responsiveUtils.scss';

.apply-changes-dialog {
  .MuiDialog-container {
    .MuiDialog-paper {
      width: 700px;
      min-height: 284px;
      background-color: #fff;
      color: #000;

      .dialog-content {
        justify-content: space-between;

        .body {
          flex: 1;
          padding: 24px;
          display: flex;
          flex-direction: column;
          gap: 24px;

          .title {
            display: flex;
            justify-content: center;

            .text {
              font-family: 'MediumLLWeb-Bold';
              text-transform: uppercase;
              font-size: 18px;
              line-height: 24px; /* 133.333% */
            }
          }

          .description {
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
          }

          .action-section {
            display: flex;
            justify-content: space-between;

            .checkbox-label {
              margin-left: -10px;
              margin-right: 0;

              .cb-spaces-section {
                padding: 0 8px;
              }

              .MuiFormControlLabel-label {
                font-size: 15px;
                font-weight: 400;
                line-height: 24px; /* 160% */
              }
            }
          }

          .divider {
            border-top: 1px solid #eceeef;
            height: 0;
            background-color: #fff;
          }

          .table-section {
            display: flex;
            flex-direction: column;
            gap: 16px;

            .error-section {
              .text {
                color: #f44a4a;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px; /* 123.077% */
              }
            }
          }

          .override-section {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .switch-control {
              display: flex;
              gap: 16px;
              align-items: center;

              .label {
                color: #000;
                font-size: 15px;
                line-height: 24px; /* 160% */
              }

              .override-switch {
                padding: 0;
                width: 32px;
                height: 16px;
                .MuiSwitch-track {
                  border-radius: 100px;
                  opacity: 0.6;
                }

                .MuiSwitch-switchBase {
                  padding: 1px;
                  .MuiIconButton-label {
                    .MuiSwitch-thumb {
                      width: 14px;
                      height: 14px;
                    }
                  }

                  &.Mui-checked {
                    color: #fff;
                    transform: translateX(16px);
                  }

                  &.Mui-checked + .MuiSwitch-track {
                    background-color: #4783ff;
                    opacity: 1;
                  }
                }
              }
            }

            .info {
              color: rgba(0, 0, 0, 0.6);
              font-size: 13px;
              line-height: 16px; /* 123.077% */
            }
          }
        }

        .footer {
          .action-buttons {
            display: flex;
            flex-direction: row;
          }
        }
      }
    }
  }
}

@include media(phone) {
  .apply-changes-dialog {
    .MuiDialog-container {
      .MuiDialog-paper {
        width: 60%;
        max-height: 80%;
      }
    }
  }
}
