.saving-blocker-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  z-index: 1400;

  .bg {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: #000;
    opacity: 0.4;
  }

  .message {
    background: rgba(65, 65, 65, 0.8);
    padding: 12px 15px;
    border-radius: 4px;
    color: #fff;
    position: absolute;
    bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    p {
      margin: 0;
    }

    i {
      color: #fff;
      margin-right: 8px;
    }
  }
}
