@import '../variables.scss';

.list-item-container {
  display: flex;
  // flex-wrap: nowrap;
  color: $primary-color;
  margin-top: 0;
  margin-bottom: 8px;
  position: relative;
  overflow: hidden;

  &:hover {
    background-color: #f4f7f8;
    .list-item-buttons > button {
      color: $primary-color;
    }
  }

  &.loading {
    &::before {
      cursor: wait;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-color: #fff;
      opacity: 0.7;
      z-index: 99;
      content: '';
    }
  }

  &.dragging {
    opacity: 0;
  }

  &.clickable {
    cursor: pointer;
  }

  &.moveable {
    cursor: move;
  }

  // &.active {
  //   background-color: #eceeef;
  //   .preview {
  //     background-color: #00cd7f;
  //   }
  // }

  .list-item-buttons {
    // position: absolute;
    // top: 10px;
    // right: 10px;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 10px 10px;

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    > button {
      color: #f4f7f8;
      opacity: 0.2;
      &:hover {
        opacity: 1;
      }
    }
  }

  .preview {
    flex: 0 0 90px;
    display: flex;
    overflow: hidden;

    &:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    img {
      position: absolute;
      height: 100%;
      z-index: 0;
    }
    .time {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      flex-direction: column;

      > h2 {
        line-height: 5px;
        color: $primary-color;
        font-size: 28px;
      }

      > label {
        font-size: 8.5px;
        text-transform: uppercase;
        font-weight: 700;
      }
    }
  }

  .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: center;
    padding-left: 0;
    padding-right: 0;
    z-index: 1;
    min-height: 56px;
    min-width: 0; // trick for ellipsis long text
    margin-left: -15px;

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
    }

    .invitedTags {
      display: flex;
      flex-wrap: wrap;
    }

    .title {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .description {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      opacity: 0.4;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
  }

  .buttons {
    svg {
      color: $primary-color-variant;
    }
  }
}

.side-panel {
  .list-item-container {
    background-color: $primary-color-variant;
    color: $secondary-color;

    .preview {
      background-color: $primary-color;

      .time {
        font-size: 28px;
        > h2 {
          line-height: 5px;
          color: $secondary-color;
        }
      }
    }

    .info {
      background-color: $primary-color-variant;
    }
  }

  .list-item-buttons {
    > button {
      color: $secondary-color;
      opacity: 0.2;
    }
  }
}
