@import '../../variables.scss';

.table-data-component {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .global-resources-table {
    font-family: $FMediumRegular;
    letter-spacing: normal;
    position: relative;
    padding: 0 8px;
    overflow-x: hidden;
    overflow-y: auto;

    .MuiTableRow-head {
      white-space: nowrap;
    }

    .MuiToolbar-root {
      padding: 0 24px;
      padding-bottom: 23px;

      .MuiOutlinedInput-input {
        padding: 6px 0;
      }

      .MuiInputBase-root {
        .MuiInputAdornment-positionEnd {
          .Mui-disabled {
            opacity: 0;
          }
        }
      }
    }

    .MuiTableRow-root {
      &.table-body-row.disabled {
        cursor: no-drop !important;
        opacity: 0.5;

        &:hover {
          background-color: #fff !important;
        }

        .MuiIconButton-root.Mui-disabled {
          color: rgba(0, 0, 0, 0.54);
        }
      }

      &.resource-item {
        cursor: pointer;

        &.disabled-row {
          opacity: 0.6;
          cursor: not-allowed;

          &:hover {
            background-color: #fff !important;
          }
        }
      }

      .MuiIconButton-root {
        padding: 2px;
        color: #000;

        &:hover {
          background-color: unset;
        }
      }

      .MuiTableCell-root {
        padding: 8px 16px;
        border-bottom: 0;
        color: #000;
      }

      &.MuiTableRow-hover:hover {
        background-color: #f4f7f8 !important;
      }

      .MuiTableSortLabel-root {
        &.MuiTableSortLabel-active {
          .MuiTableSortLabel-icon {
            opacity: 1;
            color: #000 !important;
          }
        }
      }
    }

    .MuiTableFooter-root {
      padding-top: 0;
      padding-bottom: 0;

      .MuiTablePagination-selectRoot {
        border: 1px solid #dbe1e3;
        border-radius: 4px;
        min-width: 88px;
        min-height: 40px;

        .MuiTablePagination-select {
          text-align: left;
          text-align-last: left;
        }

        .MuiSelect-icon {
          right: 10px;
        }
      }

      .MuiTablePagination-root {
        padding: 0;
      }

      .MuiButtonBase-root {
        background: #f4f7f8;
        border-radius: 4px;
        padding: 1px;
        margin: 0 5px;
        max-width: 24px !important;
        max-height: 24px !important;
        min-width: 24px !important;
        min-height: 24px !important;
        color: rgba(0, 0, 0, 0.4);

        &.MuiButton-contained.Mui-disabled {
          background: #000;
          color: #fff;
        }
      }

      .MuiTablePagination-toolbar {
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 0;
        padding-bottom: 0;
        min-height: 0;

        .MuiTablePagination-spacer {
          display: none;
        }

        .MuiTablePagination-caption+div {
          margin-left: 0;
        }
      }
    }

    .checkbox-column {
      width: 44px;

      .MuiCheckbox-root[aria-disabled='false'] {
        .MuiSvgIcon-root {
          fill: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .radio-column {
      .MuiRadio-root[aria-disabled='false'] {
        .MuiSvgIcon-root {
          fill: rgba(0, 0, 0, 0.6);
        }

        &.Mui-checked {
          .MuiSvgIcon-root {
            fill: #000;
          }
        }
      }
    }

    table {
      thead {
        th {
          font-family: $FMediumBold;
          background-color: #fff;

          .MuiTableSortLabel-root {
            &:hover {
              color: #000;
            }
          }
        }
      }

      tbody {
        tr {
          &.selected {
            background-color: #f4f7f8;

            .MuiTableCell-root:first-child {
              background-color: #f4f7f8 !important;
            }
          }

          &.MuiTableRow-root {
            &.MuiTableRow-hover {
              &.row-disabled {
                * {
                  cursor: not-allowed;
                }

                &:hover {
                  background-color: #fff !important;
                }
              }
            }
          }
        }
      }

      .name-column {
        cursor: pointer;
      }

      .MuiCheckbox-root {
        padding: 0 !important;

        &.hidden {
          display: none;
        }
      }

      .MuiCheckbox-colorSecondary.Mui-checked {
        color: #414141;

        .MuiSvgIcon-root {
          fill: #414141 !important;
        }
      }
    }

    .resource-name {
      display: flex;
      align-items: center;

      .resource-checkbox {
        display: inline-block;
        width: 40px;
        max-width: 40px;

        .MuiCheckbox-root {
          padding: 0;

          &.hidden {
            display: none;
          }
        }

        .MuiCheckbox-colorSecondary.Mui-checked {
          color: #000;
        }
      }

      .resource-item-thumbnail {
        color: $secondary-color;
        font-size: 16px;
        display: flex;
        align-items: center;

        img {
          height: 26px;
        }

        // .icon-zip {
        //   font-size: 24px;
        // }
      }

      .icon-folder {
        color: rgba(65, 65, 65, 0.4);
      }

      // .name {
      //   margin-left: 8px;
      // }

      .text-truncate {
        width: unset;
        // max-width: 645px;
      }

      .root-location {
        margin-left: 8px;
      }

      .processing-tag-wrapper {
        color: $primary-color;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 8.5px;
        line-height: 12px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        font-family: $FMediumBold;
        margin-left: 12px;

        .processing {
          background-color: #ff7f7f;
          padding: 2px 4px;
          border-radius: 2px;
        }
      }
    }
  }
}

.global-resources-table {
  &.global-resources-table-no-data {
    flex-direction: column;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;

    .icon-resources {
      font-size: 18px;
    }
  }
}