.move-dialog-search-field-popover {
  z-index: 1313;

  .results {
    .resource-item {
      .left-section {
        .icon-folder {
          // opacity: 0.4;
          color: #dbe1e3;
        }
      }

      &.disabled {
        opacity: 0.5;
        cursor: no-drop;
      }
    }
  }
}

.marginBottom16 {
  margin-bottom: 16px;
}

.global-resources-dialog.move-resources-dialog {
  .dialog-header {
    padding-right: 60px;
  }

  .content-container .content .main-content .control-wrapper {
    padding: 16px 24px;
  }
}

.global-resources-dialog.move-resources-dialog .dialog-header {
  gap: 8px;

  .left {
    .dialog-title {
      width: 100%;
      max-width: 100%;
      word-break: break-all;
    }
  }

  .right {
    align-items: flex-start;

    .add-button-container {
      margin-top: 8px;
    }
  }
}