.space-action-list {
  .MuiListItemIcon-root {
    min-width: 32px;

    .MuiSvgIcon-root,
    .icon {
      color: #000;
    }
  }

  .icon-loading {
    min-width: 32px;
  }

  .MuiMenuItem-root {
    height: 40px;
    padding: 8px 16px;
    width: 340px;
  }

  .block-divider {
    border-top: 1px solid #eceeef;
    margin: 8px 0;
  }

  .switch-notify-participant {
    &.MuiButtonBase-root.Mui-disabled {
      pointer-events: auto;
    }

    .notify-switch {
      pointer-events: none;
    }
  }

  .notify-switch {
    &.switch-enhanced {
      padding-left: 0;

      .MuiSwitch-root {
        width: 40px;
        height: 20px;
        padding: 7px;
      }

      .MuiSwitch-switchBase {
        padding: 4px;

        &.Mui-checked {
          transform: translateX(12px);

          &+.MuiSwitch-track {
            background-color: #4783ff;
            opacity: 1;
          }
        }
      }

      .MuiSwitch-track {
        min-width: 24px;
        min-height: 14px;
      }

      .MuiSwitch-thumb {
        width: 10px;
        height: 10px;
      }
    }
  }

  .lockicon {
    margin-left: 40px;
    font-size: 16px;
  }
  .icon-video-message::before {
    color: #000;
  }
}

.link-setting {
  color: #76A2FE;
  font-family: 'MediumLLWeb-Bold';
}

.MuiTooltip-tooltip.tooltip-switch-notify-participant {
  font-size: 13px !important;
  line-height: 16px !important;
}