.sort-select-popover {
  .popover-paper {
    .body {
      padding: 8px 0;
      width: 240px;

      .radio-group {
        padding: 0;

        .radio-label {
          display: flex;
          width: 100%;
          margin: 0;
          padding: 8px 16px;
          height: 40px;
          gap: 12px;

          &:hover {
            background-color: #f4f7f8;
          }
        }

        .radio-button {
          width: 22px;
          height: 22px;

          &.Mui-checked {
            color: #000;
          }

          .icon-check-solid {
            font-size: 18px;
          }

          .uncheck {
            width: 16px;
            height: 18px;
          }
        }
      }

      .divider {
        margin: 8px 0;
        border-top: 1px solid #eceeef;
      }
    }
  }
}