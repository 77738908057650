.global-resources-one-drive-table {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .global-resources-table {
    overflow-y: auto;
    // overflow-x: hidden;
    flex: 1 1;
  }

  .connect-info {
    padding: 40px;
    text-align: center;

    .one-drive-logo {
      font-size: 32px;
      margin-bottom: 30px;
    }

    .primary-text {
      margin-bottom: 5px;
    }

    .secondary-text {
      color: rgba(0, 0, 0, 0.6);
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
  .root-location {
    font-size: 12px;
  }
  .resource-search-field {
    width: 100% !important;
  }
  .resource-item-thumbnail {
    display: flex;
    align-items: center;
  }
}

.onedrive-auth-error {
  display: flex;
  flex-direction: row;
  .onedrive-auth-error-content {
    margin-right: 16px;
  }
  .onedrive-auth-error-actions {
    padding: 4px;
  }
  .regular-rounded-button {
    border-radius: 100px;
    padding: 8px 16px;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
