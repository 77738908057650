@import '../responsiveUtils.scss';

.video-message-dialog {
  .MuiDialog-paper {
    width: 75% !important;
    max-width: 1200px;
    background-color: transparent;

    .dialog-content {
      background-color: transparent !important;
      position: relative !important;
      padding: 0px 50px !important;

      .resource-preview-container {
        display: flex;
        flex: 1;
        overflow: hidden;
      }
    }
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .close-button {
    top: 8px;
    right: 57px;

    .close-icon {
      color: #000;
    }
  }

  .dialog-content {
    overflow-y: hidden;
    display: flex;
  }

  .header {
    padding: 24px;
    display: flex;

    .logo {
      height: 23px;
    }
  }

  .content {
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #ffffff;
    height: auto;
    color: #000;
    backdrop-filter: blur(20px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    overflow: hidden;
  }

  .body {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: calc(100% - 50px);
    padding: 0 25px;

    &::-webkit-scrollbar {
      width: 0.4em;
      background-color: #fff;
    }

    &::-webkit-scrollbar-thumb {
      background: #dbe1e3;
    }
  }

  .details {
    text-align: center;

    .title {
      font-family: 'F37Judge-MediumCondensed';
      font-size: 40px;
      letter-spacing: 0.02em;
      line-height: 48px;
    }

    .info {
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 24px;
      opacity: 0.6;
      margin: 6px 0;
    }

    .dot {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;

      span {
        height: 4px;
        width: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 50%;
      }
    }
  }

  .player {
    padding: 32px 0;

    iframe {
      min-height: 556px;
    }

    div[class*='Player'] {
      background-color: #fff;
      padding: 0;
    }

    div[class*='VaamBadge'] {
      display: none;
    }

    div[class*='video'] {
      border-radius: 4px;
    }
  }
}

@include media(phone) {
  .video-message-dialog {
    .MuiDialog-paper {
      height: 100%;
      max-height: 100%;
      padding: 0 !important;
      width: 100% !important;

      .dialog-content {
        padding: 0px !important;

        .resource-preview-container {
          height: 100%;
        }

        .content {
          height: 100%;
        }

        .close-button {
          top: 0px;
          right: 0px;
        }

        .back-button {
          top: unset;
          bottom: 0;
          left: 10px;
        }

        .next-button {
          top: unset;
          bottom: 0;
          right: 10px;
        }
      }
    }

    .header {
      padding-left: 16px;
    }

    .body {
      padding: 0 16px;
      max-height: unset;
      overflow-y: unset;
      height: 100%;
    }
  }
}
