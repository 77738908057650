@import '../../variables.scss';

.resource-grid-item {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;

  .thumbnail-section {
    background-color: #f4f7f8;
    position: relative;
    overflow: hidden;
    z-index: 0;

    .processing-tag-wrapper {
      position: absolute;
      color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 8.5px;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      font-family: $FMediumBold;
      white-space: nowrap;
      left: 8px;
      bottom: 8px;

      .processing {
        background-color: #ff7f7f;
        padding: 2px 4px;
        border-radius: 2px;
      }
    }

    &:hover {
      .actions {
        .more-button-wrapper {
          .more-button {
            background-color: #f4f7f8;
          }
        }

        .button-checkbox {
          color: #000;

          .MuiIconButton-label {
            background-color: #f4f7f8;
            border-radius: 4px;
          }
        }

        .resource-radio {
          color: #000;

          .MuiIconButton-label {
            background-color: #f4f7f8;
            border-radius: 50%;
          }
        }
      }
    }

    .actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 0;
      right: 0;
      padding: 8px;
      z-index: 2;

      .more-button-wrapper {
        .more-button {
          color: #000;
          padding: 0;
          border-radius: 4px;

          &:hover {
            background-color: #f4f7f8;
          }
        }
      }

      .button-checkbox,
      .resource-radio {
        color: rgba(0, 0, 0, 0.6);
        padding: 0;

        &.Mui-checked {
          color: #000;
        }
      }

      .resource-radio,
      .button-checkbox {
        .MuiIconButton-label {
          position: relative;
          z-index: 0;

          &::after {
            content: '';
            left: 4px;
            top: 4px;
            height: 15px;
            width: 15px;
            position: absolute;
            background-color: #fff;
            z-index: -1;
          }
        }

        &:hover {
          color: #000;

          .MuiIconButton-label {
            background-color: #f4f7f8;
          }
        }
      }

      .button-checkbox {
        &:hover {
          .MuiIconButton-label {
            border-radius: 4px;
          }
        }
      }

      .resource-radio {
        .MuiIconButton-label {
          &::after {
            border-radius: 100%;
          }
        }

        &:hover {
          .MuiIconButton-label {
            border-radius: 100%;
          }
        }
      }
    }

    .img-section {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      padding-bottom: 100%;
      height: 0;
      overflow: hidden;

      .item-icon,
      .icon-folder {
        font-size: 54px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .icon-folder {
        color: #dbe1e3;

        &::after {
          font-size: 16px;
          color: #000;
        }
      }

      .item-icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .resource-thumbnail {
      background-size: cover;
      align-items: baseline;
      position: relative;
      padding-bottom: 100%;
      height: 0;
      overflow: hidden;

      .image-viewer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin: 0;
        background-color: unset;
      }
    }
  }

  .info {
    .name {
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .sub {
      display: flex;
      align-items: center;
      gap: 8px;
      color: rgba(0, 0, 0, 0.6);

      .left {
        display: flex;
        align-items: center;

        .resource-indicator {
          &::before {
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }

      .right {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        // color: rgba(0, 0, 0, 0.6);
        display: flex;
        align-items: center;

        .dot {
          margin: 0 4px;
          font-size: 8px;
        }
      }
    }

    .location {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  &.disabled-row {
    .info,
    .info .sub .left .resource-indicator::before,
    .sub {
      color: rgba(0, 0, 0, 0.3);
    }

    .thumbnail-section {
      opacity: 0.6;
    }
  }
}
