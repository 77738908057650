@import '../variables.scss';

// border
.borderLeft2SolidGreen {
  border-left: 2px solid $green-color;
}

// margin
.marginLeft8 {
  margin-left: 8px;
}

.marginLeft16 {
  margin-left: 16px;
}

.marginLeft16i {
  margin-left: 16px !important;
}

.marginTop32 {
  margin-top: 32px;
}

.marginTop70 {
  margin-top: 70px;
}

// color
.primaryColor {
  color: $primary-color;
}

.secondaryColor {
  color: $secondary-color;
}

// font-size
.fontSize165 {
  font-size: 16.5px;
}

// gap
.gap4 {
  gap: 4px;
}

.gap8 {
  gap: 8px;
}

.gap16 {
  gap: 16px;
}

.gap24 {
  gap: 24px;
}

.gap32 {
  gap: 32px;
}

.gap48 {
  gap: 48px;
}

.gap64 {
  gap: 64px;
}

// display
.displayFlex {
  display: flex;
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}

// justify-content
.justifyContentCenter {
  justify-content: center;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

// align-items
.alignItemsCenter {
  align-items: center;
}

.alignItemsStart {
  align-items: flex-start;
}

.alignItemsEnd {
  align-items: flex-end;
}

// flex-direction
.row-direction {
  flex-direction: row;
}

.column-direction {
  flex-direction: column;

  .divider {
    display: block;
    width: calc(100% + 48px);
    height: 1px;
    background-color: #eceeef;
    margin: 24px -24px 0;
  }
}

.column-direction-reverse {
  flex-direction: column-reverse;
}

// opacity
.opacity06 {
  opacity: 0.6;
}

.opacity04 {
  opacity: 0.4;
}

// position
.positionAbsolute {
  position: absolute;
}

.positionRelative {
  position: relative;
}

.position0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// max-width
.maxWidth50p {
  max-width: 50%;
  flex: 1 1 50%;
}

.maxWidth6666p {
  max-width: 66.66%;
}

.maxWidth300px {
  max-width: 300px;
}

.maxWidth700px {
  max-width: 700px;
}

// width
.width24 {
  width: 24px;
}

// padding
.paddingLR32 {
  padding-left: 32px;
  padding-right: 32px;
}

.paddingLR16 {
  padding-left: 16px;
  padding-right: 16px;
}

.paddingLR100 {
  padding-left: 100px;
  padding-right: 100px;
}

.paddingLR50 {
  padding-left: 50px;
  padding-right: 50px;
}

.paddingLR70 {
  padding-left: 70px;
  padding-right: 70px;
}

.paddingB10 {
  padding-bottom: 10px;
}

.paddingT0 {
  padding-top: 0;
}

.paddingTB0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.paddingT-ratio11 {
  padding-top: 100%;
  /* 1:1 Aspect Ratio */
}

.paddingT-ratio169 {
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.paddingB-ratio11 {
  padding-bottom: 100%;
  /* 1:1 Aspect Ratio */
}

.paddingB-ratio169 {
  padding-bottom: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

.paddingB-ratio21 {
  padding-bottom: 50%;
  /* 2:1 Aspect Ratio (divide 1 by 2 = 0.5) */
}

.paddingB-ratio916 {
  padding-bottom: 177.77%;
  /* 9:16 Aspect Ratio (divide 16 by 9 = 1.77) */
}

.paddingB-ratio32 {
  padding-bottom: 66.67%;
  /* 3:2 Aspect Ratio (divide 2 by 3 = 0.6667) */
}

.paddingB-ratio41 {
  padding-bottom: 25%;
  /* 4:1 Aspect Ratio (divide 1 by 4 = 0.25) */
}

.paddingB-ratio222 {
  padding-bottom: 222%;
}

.paddingB-ratio12 {
  padding-bottom: 120%;
}
