@import '../variables.scss';
@import '../responsiveUtils.scss';

.responsive-dialog {
  // opacity: 0.8;
  // position: relative;

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .MuiDialogContent-root:first-child {
    padding-top: 0;
  }

  .MuiDialog-paper {
    display: flex;
    align-items: center;
    // background-color: $primary-color;
    background-color: transparent;
    box-shadow: none;
    color: white;
    position: relative;
  }

  .responsive-dialog-title {
    position: absolute;
    left: 0;
    top: 20px;
    color: #000;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    font-family: 'MediumLLWeb-Bold';
    width: 100%;
  }

  .close-button {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 99;

    .close-icon {
      color: #fff;
    }
  }

  .dialog-content,
  .dialog-actions {
    max-width: 1200px;
  }

  .dialog-content {
    margin-top: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .dialog-actions {
    padding: 20px;
    width: 100%;
    padding-top: 10px;

    .buttons {
      width: 100%;

      .remove-button {
        text-align: center;
        color: orangered;
        margin-top: 10px;
      }
    }
    .action-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 24px;
      margin-top: 8px;
      .btn {
        border-radius: 100px;
        text-transform: initial;
        padding: 10px 15px;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        text-transform: uppercase;
        font-family: 'MediumLLWeb-Bold';
        text-decoration: none;
        max-height: 40px;
        &-primary {
          color: $secondary-color;
          background-color: $primary-color;
          &:hover {
            color: #1fda8b;
          }
        }
        &-secondary {
          background-color: #f4f6f8;
          color: rgba(0, 0, 0, 0.4);
          &:hover {
            color: $primary-color;
            background-color: #eceeef;

            &:focus {
              color: $primary-color;
              background-color: #dbe1e3;
            }
          }
        }
      }
    }
  }

  &.white {
    .close-icon {
      color: #fff;
    }
  }

  &.fullscreen {
    .MuiDialog-paper {
      // height: 100%;
      // width: 100%;
      // max-height: 100%;
      // max-width: 100%;
      // min-width: unset;

      .dialog-content {
        display: flex;
        flex-direction: column;

        .content {
          flex: 1;
        }
      }

      .MuiButton-fullWidth {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .responsive-dialog {
    .MuiButton-fullWidth {
      margin: auto;
      width: 30%;
    }
  }
}

@include media(phone) {
  .responsive-dialog {
    .MuiDialog-paper {
      width: 60%;
      max-height: 50%;
    }

    .MuiButton-fullWidth {
      margin: auto;
      width: 30%;
    }

    .close-button {
      right: 10px;
      top: 10px;
    }
  }
}

@media only screen and (min-height: 600px) {
  .responsive-dialog {
    .MuiDialog-paper {
      // temprary solution to fix max-height: 50% at line 160 above and height: 65%; at line 20 in ResourcePreviewDialog.scss
      // should remove height: 65%; at line 20 in ResourcePreviewDialog.scss and adjust the max-height above
      min-height: 600px;
    }
  }
}
