@import '../../variables.scss';

.interaction-popover {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-family: $FMediumBold;
    color: rgba(0, 0, 0, 0.6);
    font-size: 10px;
    gap: 16px;
  }

  .participant-item {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 24px;
    font-family: 'MediumLLWeb-Regular';
    text-transform: capitalize;

    .user-avatar {
      width: 24px;
      height: 24px;

      h6 {
        font-size: 12px;
      }
    }
  }
}