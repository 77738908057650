.ex-storage-select-root-folders-dialog {
  .MuiDialog-paper {
    width: 900px;
  }

  .MuiDialogTitle-root {
    padding: 25px;
  }

  .dialog-title-text {
    font-size: 17px;
    font-weight: bold;
  }

  .dialog-subtitle-text {
    font-size: 12px;
    font-weight: normal;
    opacity: 0.6;
  }

  .content {
    padding: 0;
    overflow: hidden;
  }

  .list-container {
    display: flex;
    flex: 1;
    min-height: 380px;
    justify-content: center;
    width: 100%;
    padding-left: 16px;
  }

  .MuiTreeView-root {
    overflow: auto;
    max-height: 360px;
    margin-bottom: 20px;
    width: 100%;
  }

  .item-content-wrapper {
    display: flex;
    gap: 16px;
    padding: 4px;
  }

  .item-name {
    flex: 1 1 auto;
    font-weight: 700;
    margin: 9px 0;
  }

  .MuiTreeItem-iconContainer {
    width: 20px;
    max-width: 20px;
    svg {
      font-size: 24px;
    }
  }

  .icon-folder {
    color: #41414166;
  }

  .information-alert {
    margin: 0 16px;

    .link {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .actions {
    padding: 0;
    margin-top: 20px;

    .btn {
      width: 100%;
      height: 88px;
      padding: 8px 16px;

      .MuiButton-label {
        font-family: 'F37Judge-MediumCondensed';
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
        color: #fff;
      }
    }

    .MuiButton-containedPrimary {
      background-color: #000;
    }

    .MuiButton-containedPrimary.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.12);
    }
  }

  .MuiAlert-standardWarning {
    background-color: #faf2fd;
    color: #000;
    .MuiAlert-icon {
      color: #ad2de1;
    }
  }

  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #000;
  }
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  .loading-indicator {
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .close-button {
    position: absolute;
    right: 12px;
    top: 16px;
    z-index: 99;

    .close-icon {
      color: #000;
    }
  }
}
