@import '../variables.scss';
@import '../responsiveUtils.scss';

.right-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;

  .insight-context {
    margin: 24px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    z-index: 0;
    border-radius: 25px;
    overflow: hidden;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 912px;
  }

  .background {
    height: 90%;
    position: relative;
    z-index: 1;
    width: 100%;

    background-size: cover;
    background-repeat: no-repeat;
  }

  .content {
    padding: 0 40px;
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;

    .title {
      text-transform: uppercase;
      font-size: 60px;
      line-height: 1.2;
      position: relative;
      font-family: F37Judge-MediumCondensed;
      padding: 0;
      margin: 0 auto 28px auto;
      max-width: 600px;
    }

    .btn {
      display: inline-block;
    }
  }

  &.partner-portal {
    .insight-context {
      background-color: #F0F5FF;
      background-image: none;
    }

    .background {
      background-image: none;
      height: auto;

      .blog-background__image {
        display: none;
        margin: auto;
        max-width: 100%;
        height: auto;
        max-height: 70vh;
      }
    }

    .content {
      position: relative;
      margin-top: 56px;
    }
  }
}

@include media(desktop) {
  .right-column {
    .content {
      .title {
        font-size: 60px;
      }
    }
  }
}

@include media(tab) {
  .right-column {
    display: none;
  }
}

@include media(phone) {
  .right-column {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .partner-portal .background .blog-background__image.sc-1920 {
    display: block;
  }
}

@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .partner-portal .background .blog-background__image.sc-1680 {
    display: block;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1679px) {
  .partner-portal .background .blog-background__image.sc-1440 {
    display: block;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1439px) {
  .partner-portal .background .blog-background__image.sc-1280 {
    display: block;
  }
}

@media screen and (max-width: 1023px) {
  .partner-portal .background .blog-background__image.sc-1024 {
    display: block;
  }
}