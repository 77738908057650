.connect-button {
  &.connect,
  &.reconnect {
    background-color: #000;
    text-transform: uppercase;
    color: #fff;
    border-radius: 100px;
    &:hover {
      background-color: #000;
      color: #1fda8b;
    }
  }
  &.connected {
    background-color: #e5fff5;
    text-transform: uppercase;
    color: #1fda8b;
    border-radius: 100px;
    &:hover {
      background-color: #e5fff5;
      color: #1fda8b;
    }
  }
}
