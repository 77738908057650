@import '../../variables.scss';

.channel-list-container {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px;

  .participant-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 24px;
    cursor: pointer;

    &.new-message {
      background-color: #F0F5FF;

      .blue-dot {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background-color: #4783ff;
        display: inline-block;
        opacity: 1 !important;
      }

      .content {
        .last-comment {
          color: #000;
        }

        .name {
          font-family: 'MediumLLWeb-Bold';
          color: #000;
        }
      }
    }

    .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
      overflow: hidden;

      .content {

        &-name {
          max-width: 100%;
          display: flex;
          gap: 4px;
          line-height: 24px;
          color: rgba(0, 0, 0, 0.6);
        }

        .name {
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .tags {
          overflow: unset;
        }
      }
    }

    .participant-item-header {
      .online-background {
        height: 14px;
        width: 14px;
        margin-left: 28px;
        margin-top: -12px;
        border-radius: 100%;
        position: sticky;
        background-color: #fff;
        align-items: center;
        text-align: center;
        display: flex;
        flex-wrap: wrap;

        .circle {
          background-color: #DBE1E3;
          height: 10px;
          width: 10px;
          border-radius: 100%;
          margin: auto;
          padding-top: 2px;

          &.online {
            background-color: #1FDA8B;
          }
        }
      }
    }

    .new-comment-container {
      background-color: #f44a4a;
      border-radius: 10px;
      margin-left: 10px;

      .new-comment {
        color: #fff;
        font-size: 10px;
        font-family: 'Roboto';
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .content {
      display: flex;
      flex-basis: 100%;

      .last-comment {
        margin: 0;
        font-size: 13px;
        line-height: 16px;
        width: 100%;
        display: flex;

        >span.text {
          overflow: hidden;
          margin-right: 5px;
          text-overflow: ellipsis;
        }
      }

      .name {
        margin: 0;
        padding: 0;
        font-size: 15px;
        text-transform: capitalize;
        font-family: 'MediumLLWeb-Regular';

        &.description {
          color: #908e8e;
          font-size: 13px;
        }
      }
    }

    .more {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
}