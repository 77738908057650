.add-material-action-menu {
  .MuiMenuItem-root {
    section.displayFlex {
      .MuiSvgIcon-root {
        font-size: 18px;
        width: 24px;
      }
      span.displayFlex {
        font-size: 16px;
        width: 24px;
        &.icon-upload-files {
          font-size: 20px;
        }
      }
      .access-level-string {
        font-size: 14px;
        padding-left: 20px;
        opacity: 0.6;
      }
      span.icon-show {
        font-size: 12px;
      }
      .icon-convert::before  {
        color: #000;
      }
    }
  }
}
