.azure-media-player {
  background: #000;
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  position: relative;
  overflow: hidden;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.video-insights {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .video-wrapper {
    display: flex;
    flex-basis: 100%;
  }

  .video-insights-button {
    position: absolute;
    font-size: 10px;
    background-color: #1fda8b;
    color: #000;
    z-index: 99;
    padding: 4px 4px 4px 8px;
    left: 16px;
    top: 16px;
    cursor: pointer;
      &.disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
      .icon-plus-solid {
        font-size: 9px;
        margin-left: 5px;
        margin-bottom: 2px;
      }
      .icon-minus-solid {
        font-size: 9px;
        margin-left: 5px;
        margin-bottom: 2px;
      }
      &:hover {
        background-color: #1fda8b;
      }
  }

  .azure-media-player, iframe {
    background-color: #fff;
    min-width: 360px;
  }
  .video-insights-left {
    width: 360px;
    height: 600px;
    margin-left: auto;
    flex: 0 0 360px;
    max-width: 360px;
    .video-insights-iframe {
      position: relative;
      height: 100%;
    }
  }
}
