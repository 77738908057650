@import '../responsiveUtils.scss';

.create-space-popup {
  &.responsive-dialog .dialog-content {
    max-width: 1240px;
    height: 700px;
  }
  &.responsive-dialog .MuiDialog-paper {
    margin: 16px 0;
    min-width: 1240px;
  }
  &.responsive-dialog .close-button .close-icon {
    color: #000;
  }

  &.hide-content {
    opacity: 0;
    transition: opacity 0.4s ease;
  }
}
@include media(md-desktop){
  .create-space-dialog.responsive-dialog {
    .MuiDialog-paper {
      max-height: 700px;
      max-width: 1240px;
      min-width: 1240px;
    }
  }
}

@media screen and (max-width: 1279px){
  .create-space-dialog.responsive-dialog {
    .MuiDialog-paper {
      min-width: calc(100vw - 80px);
      max-height: calc(100vh - 80px);
      max-width: 100%;
      height: 100%;
      margin: 40px;
      .dialog-content {
        max-width: 100%;
        .space-theme-content .space-theme-body,
        .create-space-container .create-space-right .space-list-body {
          max-height: calc(100vh - 200px);
        }
      }
    }
  }
}

@include media(phone) {
  .create-space-dialog.responsive-dialog {
    .MuiDialog-paper {
      min-width: unset;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
    }
    .dialog-content {
      height: 100%;
    }
  }
}