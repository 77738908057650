@import '../variables.scss';
@import '../responsiveUtils.scss';

.runtime-client-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.stage-only {
    .runtime-body .player-container {
      margin: 0;
    }
  }

  .runtime-header {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 24px;
    position: relative;
    height: 56px;
    z-index: 99;

    .runtime-header-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      gap: 24px;

      .vertical-divider {
        margin: 0;
      }

      .divider {
        display: inline-block;
        color: #000;
        margin: 0;
        width: 2px;
        height: 16px;
        margin-top: 4px;
      }
    }

    .runtime-header-center {
      position: absolute;
      left: 0;
      right: 0;
      color: #2d2d2d;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .runtime-header-right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 1;

      .MuiBox-root {
        margin-left: 8px;
      }

      .activity-name {
        background-color: #f4f6f8;
        color: $primary-color;
        height: 40px;
        display: flex;
        align-items: center;
        margin-right: -50px;
        padding: 0 40px 0 0;
        border-radius: 40px;
        text-transform: uppercase;
        font-size: 12px;
        font-family: MediumLLSub-BoldSubset;

        &.no-moderator {
          padding: 0 20px;
          margin-right: 0px;
          display: none;
        }
      }

      &-content {
        display: flex;
        justify-content: flex-end;
      }

      .actions {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        // padding-left: 8px;

        .more {
          padding: 0;
          border-radius: 40px;
          box-shadow: none;
          height: 40px;
          width: 40px;
          min-width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          background-color: transparent;
          color: rgba(0, 0, 0, 0.6);
          border: 0;

          &:hover {
            color: $primary-color;
            background-color: $secondary-color-light;
          }
        }

        .btn {
          margin-left: 8px;
        }

        .square-button {
          background-color: $primary-color;
          padding: 0;
          border-radius: 6px;
          margin-left: 10px;
          box-shadow: none;
          height: 40px;
          width: 40px;
          min-width: 35px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          .MuiButton-startIcon {
            margin: 0;
          }

          i {
            color: #fff;
          }

          svg {
            color: $secondary_color;
          }

          &.start {
            background-color: $green-color;

            i {
              font-size: 20px;
            }
          }

          &.end {
            background-color: #f44a4a;
            color: #fff;

            i {
              font-size: 20px;
            }
          }

          .circle {
            width: 16px;
            height: 16px;
            background-color: #fff;
            border-radius: 16px;
          }

          .MuiButton-label {
            line-height: 18px;
            font-size: 15px;
            letter-spacing: 1.6px;
          }
        }
      }

      .end-meeting-button {
        margin-left: 10px;
        cursor: pointer;
        margin-left: 5;

        i {
          font-size: 20px;
        }
      }

      .btn-end {
        margin-left: 8px;
      }

      .login-menu-button {
        margin-left: 8px;

        .user-avatar {
          background-color: #000000 !important;

          h6 {
            color: #ffffff !important;
          }
        }
      }

      .start {
        order: -1;
      }

      .group-action {
        display: flex;
      }
    }
  }

  .runtime-body {
    background-color: #f4f4f4;
    display: flex;
    height: 100%;
    position: relative;

    .player-container .player-container-inner .overlay .video-container {
      right: 4px;
    }

    .stage-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .player-container {
      width: 100%;
      margin: 0px;
      border-radius: 0px;
      overflow: hidden;
      position: relative;
      flex: 1;

      .drop-zone {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        display: flex;
        color: #fff;
        border-radius: 4px;

        &::before {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $primary-color-light;
          opacity: 0.7;
          content: '';
          z-index: 0;
        }

        >* {
          z-index: 1;
        }

        &.active {
          i {
            color: $primary-color-light;
            z-index: 9999;
          }

          .drag-phrase {
            color: $primary-color-light;
            z-index: 9999;
            border: 2px dashed $primary-color-light;
          }
        }
      }
    }

    .recording-indicator-banner {
      background-color: #4783ff;
      min-height: 56px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      padding: 0 26px;

      .banner-left {
        display: flex;
        align-items: center;

        .info-icon {
          color: #fff;
          font-size: 20px;
          margin-right: 10px;
        }

      }

      .banner-right {
        button {
          color: #fff;
        }
      }
    }
  }

  .runtime-footer {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 24px;
    position: relative;
    z-index: 100;
    height: 56px;

    .footer-more-button {
      .MuiIconButton-label {
        color: $primary-color;
      }
    }

    .square-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;

      &:hover {
        background-color: #e5e5e5;
      }

      &.active {
        background-color: $primary-color;

        svg,
        i {
          color: $secondary-color;
        }
      }

      svg,
      i {
        color: $primary-color;
        font-size: 20px;
      }

      div {
        padding: 10px;

        img {
          width: 20px;
        }
      }
    }

    .round-button {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 38px;
      height: 38px;
      cursor: pointer;

      div {
        width: 2.5em;
        height: 2.5em;
        padding: 10px;

        .control-icon {
          font-size: 22px;
          color: $primary-color;
        }
      }

      .Mui-disabled {
        opacity: 0.6;
      }
    }

    .runtime-footer-left {
      display: flex;
      align-items: center;
      z-index: 1;

      .MuiBox-root {
        margin-right: 10px;
      }

      .chat-button {
        position: relative;

        .new-message {
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #f44a4a;
          border-radius: 10px;
          position: absolute;
          top: 1px;
          left: 27px;
          padding: 8px;
          width: 16px;
          height: 16px;
        }

        .new-message span {
          color: #fff;
          font-size: 10px;
          font-family: 'Roboto';
        }
      }
    }

    .runtime-footer-center {
      position: absolute;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .MuiBox-root {
        margin: 0 10px;
        position: relative;
      }

      .hambuger-menu {
        position: absolute;
        left: 0;
        display: none;
      }
    }
  }

  .circle-button {
    padding: 0;
    border-radius: 40px;
    margin-left: 10px;
    margin-right: 8px;
    box-shadow: none;
    height: 40px;
    width: 40px;
    min-width: 35px;
    color: $primary-color;
    border: 1px solid $border-color;
    background-color: $secondary-color;

    &:hover,
    &.active {
      color: $secondary-color;
      background-color: $primary-color;
    }

    svg,
    i {
      font-size: 20px;
    }

    &.btn-layout {
      background-color: $primary-color;
      color: $secondary-color;

      svg,
      i {
        font-size: 16px;
      }
    }
  }
}

.url-box {
  background-color: #f4f4f4;
  border-radius: 40px;
  margin-left: 20px;
  padding: 3px 15px;
  border: none;
  outline: none;
  max-width: 800px;
  cursor: pointer;
  overflow: hidden;

  &.open-sidebar-left {
    margin-left: 425px;
  }

  span {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  input {
    opacity: 0;
    position: absolute;
    z-index: 0;
  }
}

@include media(phone) {
  .runtime-client-container {
    .runtime-body {
      flex: 1;
    }

    .runtime-header {
      padding: 16px;

      .runtime-header-left {
        gap: 0;

        .share-meeting-link .btn {
          padding: 10px;
        }

        .space-info .space-name {
          display: none;
        }

        .space-name-logo {
          margin-right: 8px;
        }

        .info-space-button,
        .vertical-divider {
          display: none;
        }
      }

      .runtime-header-right {
        .login-menu-button {
          display: none;
        }
      }
    }

    .runtime-body {
      .player-container .player-container-inner .overlay .video-container .portrait-video .vjs-tech {
        object-fit: cover;
      }

      .recording-indicator-banner {
        padding: 5px 10px;
      }
    }

    .runtime-footer,
    .salesroom-footer {
      justify-content: center;

      .runtime-footer-left.hasNotStarted {
        display: flex;
      }

      .runtime-footer-left {
        display: none;

        &.active {
          display: flex;
        }
      }

      .round-button div .control-icon {
        font-size: 20px;
      }

      .runtime-footer-center {
        .box-more-buttons {
          position: absolute;
          right: 0;
        }

        .round-button {
          &.share-screen {
            display: none;
          }
        }

        .hambuger-menu {
          display: flex;
        }
      }

      .square-button,
      .round-button {

        svg,
        i {
          font-size: 20px;
        }
      }
    }
  }
}

@include media(phone, landscape) {
  .landscape {

    // .jitsimeet-container {
    //   top: 50px;
    // }
    .runtime-client-container {
      .runtime-header {
        position: absolute;
        right: 0;
        top: 0;
        left: 0;
        min-height: 56px;
        width: 100%;
        display: flex;
        align-items: center;
        z-index: 1;
        padding: 8px 16px;
      }

      flex-direction: row;
      height: 200px;

      .runtime-footer,
      .salesroom-footer {
        width: 70px;
        height: calc(100% - 50px);
        top: 50px;

        .runtime-footer-left {
          &.active {
            flex-direction: column;
          }

          .MuiBox-root {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }

        .runtime-footer-center {
          left: unset;
          right: unset;
          top: 0;
          bottom: 0;
          flex-direction: column-reverse;

          .MuiBox-root {
            margin: 10px 0;
          }

          .hambuger-menu {
            position: absolute;
            left: unset;
            bottom: 10px;
          }

          .box-more-buttons {
            position: absolute;
            top: 0;
            right: unset;
          }
        }
      }
    }
  }

  .runtime-client-container {
    .runtime-body {
      top: 56px;
      height: calc(100% - 56px);
      flex: 1;

      .player-container .player-container-inner .overlay .video-container .portrait-video .vjs-tech {
        object-fit: revert;
      }
    }

    .runtime-footer {
      .runtime-footer-left {
        display: none;

        &.active {
          display: flex;
        }
      }

      .runtime-footer-center {
        .round-button.share-screen {
          display: none;
        }

        .hambuger-menu {
          display: flex;
        }
      }
    }
  }
}

@include media(tab) {

  .runtime-client-container .runtime-footer .runtime-footer-center,
  .runtime-client-container .salesroom-footer .runtime-footer-center {
    justify-content: flex-end;
  }

  .landscape .runtime-client-container .runtime-footer .runtime-footer-center,
  .landscape .runtime-client-container .salesroom-footer .runtime-footer-center {
    justify-content: center;
  }
}