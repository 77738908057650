@import '../variables.scss';

.back-to-home-button {
  background-color: transparent;
  color: $primary-color;

  .icon-home {
    font-size: 18px;
    color: $primary-color;
  }
}
