.todo-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;

  .todo-category {
    padding: 2px 4px;
    background: #ccddff;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;

    &.over-text {
      max-width: 80px;

      .text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .text {
      text-transform: uppercase;
      font-family: 'MediumLLWeb-Bold';
      color: #000;
      font-size: 8.5px;
      line-height: 12px;
      letter-spacing: 0.5px;
      white-space: nowrap;
    }

    .icon {
      color: #000;
      font-size: 5px;
    }

    &.show-more-button {
      cursor: pointer;

      &:hover {
        background-color: #a7c3fb;
      }
    }
  }

}