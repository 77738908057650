.cms-html-preview {
  .preview-message-section {
    font-family: 'MediumLLWeb-Regular';
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    background: #ffffff;
    border: 1px solid #dbe1e3;
    border-radius: 4px;
    padding: 8px 12px;
  }
}
