.apply-changes-table {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .error-text {
    font-size: 13px;
    color: #f44a4a;
    line-height: 16px;
  }

  .search-field-wrapper {
    display: flex;
    justify-content: space-between;

    > div:first-child {
      display: flex;
      align-items: center;
      flex-basis: 100%;

      label {
        font-size: 18px;
        font-weight: 700;
        margin-right: 8px;
        line-height: 24px;
      }
      .selected-count {
        border-radius: 4px;
        background-color: #f4f7f8;
        padding: 2px 8px;

        .text {
          font-size: 15px;
          font-weight: 400;
          line-height: 24px; /* 160% */
        }
      }
    }

    .search-field {
      min-width: 360px;
    }
  }

  .table-container {
    // min-height: 332px; // 344px
    // max-height: 333px;
    height: 332px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background: #dbe1e3;
      border-radius: 4px;
    }

    .table {
      .header {
        background-color: #fff;
        font-family: 'MediumLLWeb-Bold';
        font-size: 13px;
        line-height: 16px !important;
        color: #000;
        text-transform: uppercase;
      }

      .MuiTableCell-body {
        color: #000;
      }

      .MuiTableCell-root {
        border-bottom: none;
        padding: 8px 16px;
        line-height: 24px;

        &:first-child {
          padding-left: 0;
        }
        &:nth-child(2) {
          padding: 8px 16px 8px 4px;
        }
        &:nth-child(3) {
          max-width: 250px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        &:last-child {
          padding-right: 0;
        }

        .title {
          display: flex;
        }

        .MuiCheckbox-root {
          padding: 0;
          color: #000;
        }
      }

      .action-section {
        display: flex;
        align-items: center;
        gap: 16px;

        .btn-three-dots {
          &:hover {
            cursor: pointer;
            background-color: rgba(0, 0, 0, 0.04);
            border-radius: 100px;
          }
        }
      }

      .MuiTableRow-root.MuiTableRow-hover:hover {
        background-color: #f4f7f8;
      }

      .MuiTableRow-root {
        &.space-item {
          cursor: pointer;

          &.disabled {
            opacity: 0.6;
            cursor: not-allowed;

            &:hover {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .table-pagination {
    padding: 0;
    .pagination {
      padding: 8px 0;

      .MuiPagination-ul {
        gap: 12px;
      }

      .MuiPaginationItem-page {
        font-family: 'MediumLLWeb-Bold';
        font-size: 13px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.6);
        min-width: 24px;
        width: 24px;
        height: 24px;
        padding: 4px;
        margin: 0;
        background-color: #f4f7f8;
        border: none;

        &.Mui-selected {
          background-color: #000;
          color: #fff;
        }

        &.Mui-disabled {
          background-color: #eceeef;
          opacity: 1;
          .MuiPaginationItem-icon {
            opacity: 0.6;
          }
        }

        .MuiPaginationItem-icon {
          font-weight: 700;
        }

        &:hover {
          color: #000;
          &.Mui-selected {
            color: #fff;
          }
        }
      }

      .MuiPaginationItem-ellipsis {
        color: rgba(0, 0, 0, 0.6);
        font-family: 'MediumLLWeb-Bold';
      }
    }

    .right-section {
      .select-rows-per-page {
        .MuiSelect-root {
          padding: 8px 44px 8px 12px;
          font-size: 15px;
          line-height: 24px;
        }

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #dbe1e3;
        }

        .MuiSelect-icon {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .result-info {
        font-size: 15px;
        line-height: 24px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }

  .search-field {
    .Mui-disabled {
      background-color: #eceeef;
    }

    .MuiOutlinedInput-root {
      gap: 12px;
      padding-left: 12px;
      padding-right: 12px;

      .MuiOutlinedInput-input {
        padding: 8px 0;
        height: 24px;
      }
    }
  }

  .no-data-section {
    min-height: 384px; // 408 - 24
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .text,
    .sub-text {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px; /* 160% */
    }

    .icon {
      font-size: 24px;
      margin-bottom: 24px;
      width: 40px;
      height: 40px;
      padding: 8px;
      border-radius: 40px;
      background-color: #f4f7f8;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .sub-text {
      color: rgba(0, 0, 0, 0.6);
    }
  }
}

@media screen and (max-height: 720px) {
  .apply-changes-table {
    .table-container {
      min-height: 80px;
      max-height: 162px;
    }
  }
}

@media screen and (min-height: 720px) and (max-height: 768px) {
  .apply-changes-table {
    .table-container {
      max-height: 179px;
    }
  }
}

@media screen and (min-height: 768px) and (max-height: 800px) {
  .apply-changes-table {
    .table-container {
      max-height: 227px;
    }
  }
}

@media screen and (min-height: 800px) and (max-height: 900px) {
  .apply-changes-table {
    .table-container {
      max-height: 260px;
    }
  }
}
