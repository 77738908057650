@import '../responsiveUtils.scss';
@import '../variables.scss';

.global-resources-dialog {
  overflow: hidden;
  padding: 40px;

  .close-button {
    padding: 24px;
  }

  .MuiDivider-root {
    background-color: #eceeef;
  }

  &.move-resources-dialog {
    .MuiDialog-paper {
      width: 100%;
      height: 100%;
      min-height: 750px;
      max-width: 600px;
      margin: 0;
      overflow-y: visible;
    }

    .dialog-header {
      flex-direction: row;
      justify-content: space-between;

      .left {
        .dialog-title {
          font-family: $FMediumBold;
          font-size: 15px;
          line-height: 24px;
        }
      }

      .right {
        display: flex;
        align-items: center;
        gap: 16px;

        .search-button {
          padding: 0px;
        }

        .close-icon-button {
          padding: 0px;

          .MuiSvgIcon-root {
            font-size: 24px;
          }
        }
      }
    }

    .content-container .table-data-component .global-resources-table {
      max-height: 100%;

      .resource-name .text-truncate {
        max-width: 270px;
      }
    }

    .content {
      .add-button-container {
        button {
          border-radius: 100px;
          padding: 8px 16px;
          font-weight: bold;
          font-size: 15px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }
    }

    .content-container {
      .footer-actions {
        padding: 0;
      }
    }
  }

  &.select-resources-dialog {
    .MuiDialog-paper {
      max-width: calc(100vw - 80px);
      max-height: calc(100vh - 80px);
      width: 100%;
      height: 100%;
      min-width: calc(100vw - 80px);
      min-height: 750px;
      margin: 0;
      overflow-y: visible;
    }

    .content-container {
      .dialog-header {
        padding: 24px;
        flex-direction: column;
        justify-content: center;
      }
    }

  }

  .loading-box {
    height: 100%;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }

  .loading-desc {
    margin: -16px 0 0 0;
    color: rgba($color: #000000, $alpha: 0.7);
  }

  .actions-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    padding: 24px;
    width: 100%;

    .control-box {
      flex: 1;
    }

    .search-field {
      .Mui-disabled {
        background-color: #eceeef;
      }

      .MuiOutlinedInput-root {
        gap: 12px;
        padding-left: 12px;
        padding-right: 12px;

        .MuiOutlinedInput-input {
          padding: 8px 0;
          height: 24px;
        }
      }
    }
  }

  .header {
    padding: 24px;
    display: flex;
    flex-wrap: wrap;

    .info-section {
      display: none;
    }

    .actions-section {
      width: 100%;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1;
    overflow: hidden;

    .dialog-header {
      display: flex;
      padding: 16px 24px;
    }

    .content {
      padding: 0 0;
      display: flex;
      // max-height: 608px;
      min-height: 508px;
      flex: 1;

      .main-content {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1;
        flex-grow: 1;
        flex-basis: 100%;

        .global-resources-tabs {
          padding: 16px 24px 0;

          .tabs {
            margin-left: 0;
          }

          &.hidden {
            padding: 0;
          }
        }

        .control-wrapper {
          padding: 24px;
        }

        .global-resources-table {
          padding: 0;
          margin: 0 8px;
          overflow-x: auto;

          .MuiTable-root {
            table-layout: fixed;

            .name-details {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
            }
          }
        }

        .MuiTableCell-root {
          padding: 13px;
          border-bottom: 0;
          white-space: nowrap;

          &.checkbox-column {
            &.header {
              z-index: 1399;
            }
          }

          &:last-child {
            padding-right: 18px;
          }
        }
      }

      .vertical-divider {
        height: auto;
      }

      .preview-pane {
        flex: 1 1 280px;
        max-width: 280px;
        min-width: 280px;
        border-left: 1px solid #f4f6f8;
        padding: 24px;

        .previewer {
          .image-viewer {
            position: static;
            height: 100%;
            width: 100%;
          }
        }
      }

      .no-selected-file {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        text-align: center;
        opacity: 60%;
      }
    }

    .table-data-component {
      max-height: calc(100% - 84px);

      .global-resources-table .resource-name .text-truncate {
        max-width: 300px;
      }
    }

    .footer-actions {
      align-items: center;
      justify-content: flex-end;
      display: flex;
      min-height: 80px;
      padding: 0 24px;
    }
  }

  .responsive-dialog-title {
    text-align: left;
    padding-left: 24px;
    top: 20px;
  }

  .form-search {
    min-width: 300px;
    width: 500px;
  }

  .resource-checkbox {
    // always show checkbox in globalResourcePick
    visibility: visible !important;
  }

  .resource-name {
    display: flex;
    flex-wrap: nowrap;
    font-weight: 500;
    color: black;
    align-items: center;
    width: 100%;

    .resource-item-thumbnail {
      color: $secondary-color;
      font-size: 16px;

      img {
        height: 26px;
      }

      // .icon-zip {
      //   font-size: 24px;
      // }
    }

    .tags {
      min-width: 61px;
    }

    .name {
      max-width: 40vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;
    }

    .icon-folder {
      color: rgba(65, 65, 65, 0.4);
    }
  }

  .last-modified {
    color: rgba($color: #000000, $alpha: 0.7);
    max-width: 120px;
    width: 120px;
  }

  .MuiTableRow-root {
    cursor: pointer;

    &.row-disabled {
      opacity: 0.6 !important;
    }
  }

  .submit-btn {
    border-radius: 100px;
    padding: 8px 16px;
    width: 80px;

    &:disabled {
      color: var(--secondary-color);
    }
  }

  .no-margin {
    margin: unset !important;
  }

  .veeva-player {
    &.preview {
      iframe {
        width: 200px;
      }

      pointer-events: none;
    }
  }

  .dialog-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;

    .global-resources-grid {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      flex: 1;

      .global-resources-table {
        // overflow-y: auto;
        flex: 1;

        .MuiTableRow-root {
          .MuiTableCell-root {
            &:first-child {
              padding-right: 0;
            }
          }
        }

        &.global-resources-table-no-data {
          flex-direction: column;
          display: flex;
          flex: 1;
          justify-content: center;
          align-items: center;

          .no-data {
            flex-direction: column;
          }
        }
      }
    }
  }
}

@include media(md-desktop) {
  .global-resources-dialog.move-resources-dialog .MuiDialog-paper {
    min-width: 600px;
    max-width: 600px;
    width: 100%;
    max-height: 700px;
    overflow-y: visible;
  }

  .global-resources-dialog.select-resources-dialog .MuiDialog-paper {
    min-width: 688px;
    max-width: 1080px;
    width: 100%;
    max-height: 700px;
    overflow-y: visible;

    .resource-name .name {
      max-width: 400px;
    }
  }
}