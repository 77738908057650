@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.cms-widget {
  display: flex;
  flex-direction: column;
  background-color: $secondary-color;
  border: 1px solid #eceeef;
  border-radius: 8px;
  overflow: hidden;
  max-width: 624px;
  max-height: 562px;
  margin: 0 auto;

  .check-resource {
    margin-left: 20px;
    margin-right: -10px;
    visibility: hidden;
  }

  .MuiIconButton-colorSecondary.Mui-checked {
    color: #414141;
  }

  &:hover {
    .check-resource {
      color: #414141;
    }
  }

  .cms-widget-filter {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #eceeef;
    padding-left: 20px;
    padding-right: 15px;
    padding-bottom: 5px;

    .MuiInputAdornment-root {
      padding-right: 5px;
    }
  }

  .cms-widget-header {
    height: 72px;
    padding: 16px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-section {
      display: flex;
      min-width: 0; // trick for text-overflow: ellipsis

      .cms-widget-title {
        font-family: 'MediumLLWeb-Bold';
        font-size: 15px;
        line-height: 24px;
        max-width: 450px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .cms-widget-info {
        font-size: 12px;
        line-height: 16px;
        color: rgba(0, 0, 0, 0.4);
        font-family: 'MediumLLWeb-Regular';
      }

      .back-button {
        padding: 0;
        margin-right: 17px;

        .icon-chevron-left {
          font-size: 12.5px;
        }
      }
    }

    .right-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;

      .icon-pin {
        font-size: 20px;
        color: #000;
      }

      .icon-expand {
        font-size: 16.5px;
        color: #000;
      }

      .search-button {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon-search {
          font-size: 20px;
        }
      }
    }
  }

  .cms-widget-items {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    .chat-body {
      overflow: hidden;
      display: flex;
    }

    &.chat-messages {
      flex-direction: column-reverse;
      align-items: unset;

      .user-avatar {
        width: 24px !important;
        height: 24px !important;

        h6 {
          font-size: 12px;
        }
      }

      .cms-widget-item {
        align-items: unset;
      }

      .image-container img {
        max-width: 100%;
      }
    }

    &.styled-scrollbar {
      &::-webkit-scrollbar {
        width: 0.4em;
      }
    }

    &:hover {
      overflow: auto;
    }

    .cms-widget-item {
      padding: 8px 24px;
      display: flex;

      &:hover,
      &.selected {
        cursor: pointer;
        background-color: #f4f7f8;
      }

      &.disabled-hover {
        background-color: unset;
      }

      .left-section {
        display: flex;
        margin-right: 24px;
      }

      .middle-section {
        flex: 1;
        display: flex;
        min-width: 0; // trick for text-overflow: ellipsis

        .middle-section-header {
          display: flex;
          align-items: center;
          gap: 12px;
        }

        .item-name {
          font-family: 'MediumLLWeb-Regular';
          font-size: 15px;
          line-height: 24px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .item-info {
          font-size: 15px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
          mix-blend-mode: normal;
        }

        .item-time {
          font-size: 15px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
          mix-blend-mode: normal;
          display: flex;
          align-items: center;

          .MuiSvgIcon-root {
            color: rgba(0, 0, 0, 0.3);
            margin-right: 6px;
            font-size: 16px;
          }
        }
      }

      .right-section {
        display: flex;
      }
    }
  }

  .cms-widget-footer {
    padding: 16px 24px;
    display: flex;
    // .alert-info {
    //   flex-basis: 100%;
    // }
  }

  .MuiIconButton-root {
    padding: 0;
    color: #000;

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .cms-widget-control {
    display: flex;
    flex-direction: column;
    gap: 4px;

    &-label {
      font-size: 12px;
      line-height: 16px;
    }

    &-input {
      .MuiOutlinedInput-root {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #dbe1e3;
        }
      }

      input {
        border-radius: 4px;
        font-size: 15px;
        line-height: 20px;
        font-family: 'MediumLLWeb-Regular';
        font-weight: normal;
        padding: 8px 12px !important;
        height: 24px;
      }

      .MuiFormHelperText-root {
        color: #ff7f7f;
        font-size: 10px;
        line-height: 12px;
        margin: 4px 0 0 0;
      }
    }

    &-button {
      background-color: #fff;
      color: #000;
      font-family: 'F37Judge-MediumCondensed';
      font-size: 30px;
      line-height: 30px;
      padding: 25px 0;
      box-shadow: 0px -1px 0px #dbe1e3;
      border-radius: 0;

      &:hover {
        background-color: #f4f7f8;
      }

      &.Mui-disabled {
        box-shadow: 0px -1px 0px #dbe1e3;
        color: #000;
        background-color: #fff;
        opacity: 0.4;
      }
    }
  }

  // height
  &.heightLarge {
    height: 474px;
  }

  &.heightMedium {
    height: 416px;
  }

  &.heightSmall {
    height: 270px;
  }

  .paddingB-ratio32 {
    padding-bottom: 562px;
  }
}

.mobile-mode-container {
  .cms-widget {
    .paddingB-ratio32 {
      padding-bottom: 500px;
    }
  }
}

@include media(phone) {
  .cms-widget {
    height: 500px;

    .paddingB-ratio32 {
      padding-bottom: 500px;
    }

    .meetings-timeline {
      max-height: 500px;
      overflow-y: auto;
    }
  }
}

// @include media(x-desktop) {
//   .cms-widget {
//     max-width: 100%;
//   }
// }