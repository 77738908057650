@import '../../variables.scss';

.chat-main-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .chatlogo {
    font-size: 30px;
    text-transform: uppercase;
    font-family: F37Judge-MediumCondensed;
    font-weight: bold;
    margin-top: -44px;
    letter-spacing: 2px;
  }

  .comments-tabs {
    background-color: $secondary-color;
    color: $primary-color;

    &-list {
      display: flex;
      margin: 0 -8px;
    }

    .right-icon {
      margin-left: -70px;

      svg {
        font-size: 25px;
        color: rgba(0, 0, 0, 0.6);
      }

      &:hover {
        color: rgba(0, 0, 0, 1);
      }
    }

    .comment-tab-item {
      padding: 0 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.6;
      gap: 8px;

      .comment-badge {
        margin-left: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f44a4a;
        border-radius: 10px;
        width: 16px;
        height: 16px;
        padding: 8px;

        span {
          color: #000;
          font-size: 10px;
          font-family: 'Roboto';
          font-weight: normal;
        }
      }

      &.active {
        // background-color: #ddd9f9;
        // color: #3fd58d;
        opacity: 1;
        border-radius: 20px;
        font-family: "MediumLLWeb-Bold";
      }
    }

    .add-participants {
      justify-content: center;
      align-items: center;
      display: flex;
      margin-left: 20px;

      .add-button-updated {
        background-color: rgba(31, 218, 139, 1) !important;
        color: #000;
        font-weight: bold;

        &:hover {
          color: #fff;
        }
      }

      .icon-plus {
        color: #fff;
        font-size: 15px;
      }
    }
  }

  .channel-list-container {
    overflow-y: auto;
  }

  .event-chat {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;
  }

  .participant-list-content {
    .participant-list-filter {
      .filter-box {
        padding: 16px 24px 16px 24px !important;
      }
    }
  }
}