.logged-out-page {
  width: 100%;
  height: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: black;
  color: #fff;
  font-family: 'MediumLLWeb-Regular';

  .text-center {
    text-align: center !important;
  }

  .spce-logout-logo img {
    height: 29px;
    margin-bottom: 20px;
  }

  .icon-banner {
    position: absolute;
    width: 72px;
    height: 29px;
    top: 45px;
    left: 45px;
  }

  .spce-logout-txt {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  .spce-button {
    margin-top: 15px;
    border-radius: 100px;
    height: 40px;
    font-size: 15px;
    width: 84px;
    padding: 8px 16px;
    background: #00cd7f;
    border-radius: 100px;
    span {
      color: #000;
    }
    &:hover {
      background: #00cd7f;
    }


    &.disabled {
      span {
        opacity: 0.5;
        cursor: wait;
      }
    }
  }
}
