
@import '../variables.scss';
@import '../responsiveUtils.scss';

.audio-viewer {
  display: flex;
  width: 100%;
  margin: auto;
  position: absolute;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  .title {
    padding: 24px;
    font-size: 15px;
    color: #fff;
    font-family: 'MediumLLWeb-Bold';
    max-width: 70%;
  }
  .audio {
    margin-top: 25%;
    text-align: center;
    audio {
      width: 500px;
    }
  }
}

@include media(phone) {
  .audio-viewer {
    .audio {
      audio {
        width: 90%;
      }
    }
  }
}