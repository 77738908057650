.convert-to-pdf-control-checkbox {
  .MuiSvgIcon-root {
    font-size: 17px;
  }

  .MuiTypography-body1 {
    color: #fff;
  }
}

.file-input-tooltip {
  max-width: 488px !important;

  .file-input-info {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;

    .file-types-list {
      margin: 0;
      padding-left: 24px;

      li {
        &::marker {
          font-size: 8px;
        }
      }
    }
  }
}

.url-input-tooltip {
  max-width: 488px !important;

  .url-input-info {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
  }
}

.material-container {
  .uploader-area {
    .upload-container {
      .upload-area {
        .dnd-file-uploader-control {
          .dnd-file-uploader-control-label-wrapper {
            color: #000 !important;

            .icon-info {
              &::before {
                color: rgba(0, 0, 0, 0.6);
              }

              &:hover {
                &::before {
                  color: #000;
                }
              }
            }
          }

          .dnd-file-uploader-control-input .MuiOutlinedInput-root {
            background-color: #fff;
            color: #000;
            border: 1px solid #DBE1E3;

            .MuiButton-label {
              color: rgba(0, 0, 0, 0.6);
            }

            .edit-icon {
              color: rgba(0, 0, 0, 0.6);
            }

            .delete-icon {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
    }
  }
}