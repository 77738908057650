@import '../variables.scss';
@import '../responsiveUtils.scss';

.runtime-materials {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;

  .tabs {
    margin-bottom: 16px;
  }

  .material-item-left {
    .material-item-thumbnail {
      padding: 20px 25px 15px 8px;
    }

    .material-item-checkbox {
      .check-material-item {
        margin-left: 14px;
      }
    }
  }

  .control-box {
    padding: 0px 24px 16px 24px;
  }

  .runtime-materials-header {
    padding: 24px 64px 24px 24px;
    height: 72px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    background-color: $secondary-color;
    color: $primary-color;

    &.pr-24 {
      padding-right: 24px !important;
    }

    &.search-form {
      padding-right: 24px !important;

      .material-search-field {
        .input-field {
          background-color: #fff;

          input {
            color: #000;
            padding-top: 6px;
          }

          .MuiInputAdornment-root {
            color: #000;
          }
        }

        .btn-close {
          color: #000;
        }
      }
    }

    .left-section {
      display: flex;
      min-width: 0;

      .side-panel-title-bold {
        color: $primary-color;
        font-family: 'MediumLLWeb-Bold';
        font-size: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 220px;
      }

      .side-panel-subtitle {
        opacity: 0.6;
        font-size: 12px;
        font-family: 'MediumLLWeb-Regular';
      }
    }

    .side-panel-header-right {
      display: flex;
      gap: 16px;

      .add-button-container {
        .MuiButton-root {
          min-width: unset;
        }
      }

      .add-button-updated {
        background-color: rgba(31, 218, 139, 1) !important;
        color: #000;

        svg {
          margin-bottom: 1px;
        }

        &:hover {
          color: #fff;
        }
      }

      .search-button {
        display: flex;
        align-items: center;

        .icon-search {
          cursor: pointer;
          font-size: 19px;
        }
      }
    }

    .close-button-container {
      position: absolute;
      top: 25px;
      right: 25px;

      .close-icon {
        padding: 0;
        color: #000;
      }
    }

    .MuiBox-root {
      min-width: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 15px;
    }
  }

  &.MuiGrid-container {
    width: auto;
  }

  .no-material,
  .page-not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: $primary-color;
  }

  .material-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: auto;
    color: $primary-color;
    // padding: 20px;

    &.disabled {
      justify-content: center;
      padding: 20px;
      text-align: center;
      cursor: no-drop;
    }

    .material-list-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .resources-grid-view-container {
      .grid {
        padding-top: 0;
      }
    }

    .table-container {
      flex: 1;
    }

    .material-list-alert {
      background-color: rgba(65, 65, 65, 0.8) !important;
      margin: 24px 23px;

      &.hide-alert-info {
        display: none;
      }

      .MuiSvgIcon-root {
        color: #000;
      }
    }

    .material-list {
      &.styled-scrollbar {
        &::-webkit-scrollbar {
          width: 0.4em;
          background-color: #000;
        }

        &::-webkit-scrollbar-thumb {
          background: #000;
        }
      }

      &:hover {
        &::-webkit-scrollbar-thumb {
          background-color: #c8c8c8;
        }
      }

      .material-item {
        .MuiIconButton-colorSecondary.Mui-checked {
          color: rgba(0, 0, 0, 0.6);
        }

        &:hover {
          .check-material-item {
            color: rgba(0, 0, 0, 0.6);
          }
        }

        &:hover,
        &.selected {
          background-color: #f4f7f8;
        }

        &.isNewFile {
          background-color: #f0f5ff;
        }
      }
    }

    .directories-item {
      .directories-item-left {
        .check-resources {
          margin-left: 10px;
        }
      }
    }

    >section {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 20px;
      color: #000;
    }

    .cms-widget-control {
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin-bottom: 24px;

      &-label {
        font-size: 12px;
        line-height: 16px;
      }

      &-input {
        .MuiOutlinedInput-root {
          .MuiOutlinedInput-notchedOutline {
            border: none;
          }
        }

        input {
          color: #fff;
          border-radius: 4px;
          font-size: 15px;
          line-height: 20px;
          font-family: 'MediumLLWeb-Regular';
          font-weight: normal;
          padding: 8px 12px !important;
          height: 24px;
          background-color: #3e3f42;
        }

        .MuiFormHelperText-root {
          color: #ff7f7f;
          font-size: 10px;
          line-height: 12px;
          margin: 4px 0 0 0;
        }
      }

      &-button {
        left: 0;
        right: 0;
        height: 40px;
        font-size: 15px;
        margin: auto;
        background-color: rgba(31, 218, 139, 1);
        font-family: 'MediumLLWeb-Bold';
        color: #17171a;
        border-radius: 100px;

        &.Mui-disabled {
          color: rgba(0, 0, 0, 0.4);
          background-color: rgba(236, 238, 239, 1);
        }

        &.Mui-disabled.is-processing {
          background-color: rgba(31, 218, 139, 1);
        }
      }
    }

    &.material-form {
      padding-bottom: 88px;
      overflow: hidden;
      flex: 1;

      >section {
        overflow: auto;
      }

      &.no-padding {
        padding-bottom: 0;
      }

      &.access-level-form-wrapper>section {
        overflow: hidden;
        height: 100%;

        .cms-widget-item {
          overflow: hidden;
          height: 100%;
        }

        .access-level-form {
          overflow: hidden;
          height: 100%;
          display: flex;
          flex-direction: column;
        }

        .accessible-users {
          flex: 1;
        }

        .users-list {
          overflow: auto;

          .item {
            flex: unset;
          }
        }
      }

      .buttons {
        margin: auto;
        left: 24px;
        right: 24px;
        height: 88px;
        bottom: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;

        .large-save-button {
          left: 0;
          right: 0;
          height: 40px;
          font-size: 15px;
          background-color: rgba(31, 218, 139, 1);
          font-family: 'MediumLLWeb-Bold';
          color: #17171a;
          border-radius: 100px;
          width: 100%;

          &.Mui-disabled {
            color: rgba(0, 0, 0, 0.4);
            background-color: rgba(236, 238, 239, 1);
          }

          &.Mui-disabled.is-processing {
            background-color: rgba(31, 218, 139, 1);
          }
        }
      }

      .no-resource-msg {
        color: #ff7f7f;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        /* 123.077% */
      }
    }

    .access-level-form {
      flex: 1;

      hr {
        border-top: 1px solid rgba(236, 238, 239, 1);
      }

      .users-list {
        overflow: unset;
      }

      .form-label {
        font-size: 13px;
      }
    }

    .file-downloadable-checkbox {
      margin-bottom: 10px;

      .MuiFormControlLabel-label,
      .MuiCheckbox-root {
        color: #000;
      }

      .MuiSvgIcon-root {
        opacity: 0.8;
      }
    }

    .accesslevel-select {
      .MuiSelect-outlined.MuiSelect-outlined {
        background-color: #fff;
        color: #000;
      }

      .MuiInputBase-root.Mui-disabled {
        color: rgba(0, 0, 0, 0.3);

        .MuiSelect-outlined {
          background-color: #eceeef;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #eceeef;
        }

        .MuiSelect-icon {
          color: rgba(0, 0, 0, 0.6);
        }

        .MuiTypography-root {
          color: rgba(0, 0, 0, 0.3);
        }
      }
    }

    .information-alert {
      display: flex;
      align-items: center;
      background-color: rgba(219, 225, 227, 0.2) !important;

      .MuiAlert-icon {
        color: #76a2fe;
      }

      .MuiSvgIcon-root {
        font-size: 17px;
      }

      .MuiAlert-message {
        font-size: 12px;
      }
    }

    .uploader-area {
      .upload-container {
        .dnd-file-uploader-control {
          &-label {
            color: #000;
          }

          &-input {
            .MuiOutlinedInput-root {
              background-color: #fff !important;
              color: $primary-color !important;
              border: 1px solid #dbe1e3;

              &:hover {
                border: 1px solid #dbe1e3;
              }
            }

            .MuiInputAdornment-positionEnd {
              .MuiIconButton-root {
                padding: 8px;
              }
            }

            .edit-icon,
            .delete-icon {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .upload-area {
          .or-divider {
            hr {
              border: 1px solid #eceeef;
            }

            &-text {
              color: $primary-color;
              opacity: 0.4;
            }
          }

          .upload-btns {
            .upload-btn {
              border: 1px dashed rgba(0, 0, 0, 0.4);
              // background-color: rgba(219, 225, 227, 0.2);

              p {
                color: $primary-color;
              }
            }
          }
        }

        .upload-progress {
          background-color: rgba(219, 225, 227, 0.2);
          color: $primary-color;
          border: 1px solid #dbe1e3;

          .bottom-section {
            .message {
              color: rgba(0, 0, 0, 0.6);
            }

            .action-button {
              .MuiButton-label {
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }

          .MuiLinearProgress-root {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}

.res-panel-popper {
  .results {
    .resource-item {
      .left-section {
        .icon-folder {
          opacity: 0.4;
        }
      }
    }
  }
}

@include media(phone) {
  .runtime-materials {
    .material-container {
      .cms-widget-control {
        &-button {
          width: 327px;
        }
      }

      &.material-form {
        .buttons {
          .large-save-button {
            width: 327px;
          }
        }
      }
    }

    .material-item-left .material-item-thumbnail {
      padding: unset;
    }
  }
}