@import '../variables.scss';
@import '../responsiveUtils.scss';

.forgot-password-container {
  background-color: $primary_color;
  display: flex;
  flex: 1;

  .left-column {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    color: #fff;

    .logo {
      position: absolute;
      left: 40px;
      top: 40px;
    }

    .forgot-password-box {
      background-color: #222228;
      max-width: 400px;
      min-width: 400px;
      padding: 24px;
      border-radius: 6px;
    }

    .form {
      .title {
        margin-top: 0;
        font-family: MediumLLWeb-Bold;
        color: #fff;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
      }

      .description {
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 24px;
        margin-top: 0;
      }

      .label {
        font-size: 12px;
        line-height: 16px;
        color: #fff;
        margin-bottom: 4px;
        display: block;
      }

      .success-message {
        color: #1fda8b;
        margin-top: 4px;
        margin-bottom: 0;
        font-size: 10px;
      }

      .MuiInputBase-root {
        background-color: rgba(219, 225, 227, 0.2);
        color: #fff;
        border-radius: 6px;
        min-height: 40px;
        // border: 1px solid rgba(219, 225, 227, 0.2);

        input {
          padding: 0px 12px !important;
          height: 40px;
        }

        input:focus {
          border-color: #fff;
        }

        input:-webkit-autofill {
          box-shadow: 0 0 0 50px #fff inset;
          /* Change the color to your own background color */
          -webkit-text-fill-color: #fff;
        }

        input:-webkit-autofill:focus {
          box-shadow: 0 0 0 50px #fff inset;
          /* Change the color to your own background color */
          -webkit-text-fill-color: #fff;
        }

        &.Mui-error {
          border: 1px solid #ff7f7f;
        }
      }

      .MuiInput-underline {
        &:before {
          border: none !important;
        }

        &:hover {
          // border: none !important;
          border: 1px solid rgba(219, 225, 227, 0.2);
        }

        &.Mui-focused {
          border-color: #fff;
        }

        &:after {
          border: none !important;
        }
      }

      .MuiFormHelperText-root.Mui-error {
        margin-left: 0 !important;
        margin-top: 4px;
      }

      &-button-container {
        display: flex;
        margin-top: 24px;
        text-align: right;

        .send-button {
          border-radius: 100px;
          padding: 8px 16px;
          font-size: 15px;
          line-height: 20px;
          background-color: #1fda8b;
          min-width: 88px;
          color: #000;
          height: 40px;

          &.Mui-disabled {
            background-color: #eceeef;
            color: rgba(0, 0, 0, 0.4);
          }

          &:hover {
            color: #ffffff;
            background-color: #1FDA8B;
          }
        }
      }
    }

    .go-back {
      text-align: center;
      font-size: 15px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.6);
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1023px) {
  .forgot-password-container {
    overflow-x: auto;

    .left-column {
      padding: 0 24px;
      flex: 1 1 100%;
      max-width: 100%;
      justify-content: flex-start;

      .logo {
        left: 24px;
        top: 24px;

        img {
          max-width: 100%;
        }
      }

      .forgot-password-box {
        min-width: auto;
        background-color: transparent;
        padding: 0;
      }

      .form {
        margin-top: 88px;
        min-width: auto;
      }

      .go-back {
        width: 100%;
      }
    }

    .right-column {
      display: none;
    }
  }
}