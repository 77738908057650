@import '../responsiveUtils.scss';

.space-access-pin-dialog {
  .MuiDialog-paper {
    min-width: 500px;

    .responsive-dialog-title {
      text-transform: uppercase;
      top: 24px;
    }

    .content {
      margin-top: 30px;
      padding: 24px !important;
      text-align: center;

      .pin-field {
        text-align: center;
        margin: 30px 24px 24px;

        > div {
          width: 300px;
        }
        input[type='text'] {
          text-align: left;
        }
      }

      .error {
        display: block;
        text-align: center;
        color: red;
      }
    }
  }
}
@include media(phone) {
  .space-access-pin-dialog {
    .MuiDialog-paper {
      min-width: unset;
      .content .pin-field > div {
        width: 100%;
      }
    }
  }
}
