@import '../variables.scss';
@import '../responsiveUtils.scss';

.app-runtime {
  &.no-spaceaccesslevel {
    .icon-accesslevel {
      &::before {
        content: unset;
      }
    }
    .icon-folder,
    .icon-accesslevel {
      &.lv1,
      &.lv2,
      &.lv3 {
        &:before {
          color: unset;
        }
        &::after {
          content: unset;
          color: unset;
        }
      }
    }
  }

  .join-meeting,
  .salesroom-container {
    display: flex;
    flex: 1;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;

    .banner {
      top: 0;
    }

    .join-meeting-content {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      padding: 50px;
      padding-top: 40px;
      flex: 1;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: auto;
      align-items: flex-start;

      &.paddingTop60 {
        padding-top: 60px;
      }

      .welcome {
        color: #fff;
        display: flex;
        flex-direction: column;
        flex-basis: 50%;
        align-items: baseline;
        max-width: 375px;
        margin-bottom: 30px;

        > div {
          display: flex;
          flex-direction: column;
          align-items: baseline;

          .welcome-messages {
            width: auto;
            display: block;
            padding: 16px;
            font-size: 15px;
            font-weight: 400;
            margin-top: 10px;
            background: rgba(219, 225, 227, 0.4);
            backdrop-filter: blur(10px);
            border-radius: 4px;

            .whitespace {
              white-space: nowrap;
            }
          }
        }
        .space-template-button {
          background-color: #4783ff;
          border-radius: 6px;
          width: 112px;
          height: 40px;
          color: white;
          font-size: 15px;
          line-height: 24px;
          font-family: 'MediumLLWeb-Bold';
          cursor: none;
        }

        h1 {
          font-size: 85px;
          line-height: 85px;
          margin: 0;
          text-transform: uppercase;
          font-family: 'F37Judge-MediumCondensed', 'MediumLLWeb-Bold', 'Helvetica Neue', Helvetica,
            Arial;
          max-width: 500px;
          margin-top: 16px;
        }
      }

      .right-content {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 1;

        .back-button {
          padding-bottom: 20px;
          display: flex;
          gap: 8px;
        }

        .button-icon {
          background-color: $secondary-color-light;
          padding: 9px;
          margin-right: 8px;
          width: 40px;
          height: 40px;
          font-size: 18px;

          &:hover {
            background-color: $primary-color;

            .icon-video-message::before {
              color: $secondary-color;
            }
          }

          .icon-video-message::before {
            color: $primary-color;
          }
        }
      }

      > .loading {
        min-width: 375px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
      }
    }
    .overlay {
      position: absolute;
      background-color: rgba(34, 34, 40, 0.5);
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .text-guide-center {
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .title {
        color: white;
        font-family: F37Judge-MediumCondensed;
        font-size: 85px;
        line-height: 80px;
        margin-bottom: 32px;
      }
      font-size: 15px;
      line-height: 20px;
      color: white;
      position: absolute;
      bottom: 48px;
      left: 48px;
      .steps,
      .logo {
        display: none;
      }
    }
  }

  @include media(phone) {
    .join-meeting,
    .salesroom-container {
      flex-direction: column;
      flex-wrap: unset;
      padding: 0;
      justify-content: flex-start;
      overflow-y: auto;

      .join-meeting-content {
        padding: 24px 16px;
        overflow: unset;
        position: relative;
        .welcome {
          // display: none;
          max-width: 100%;
          flex-basis: 100%;
          h1 {
            font-size: 44px;
            line-height: 44px;
          }
          .welcome-messages {
            width: 100% !important;
          }
        }

        .right-content {
          // overflow: hidden;
          width: 100%;
          .back-button {
            display: none;
          }
        }
      }

      .text-guide-center {
        position: relative;
        top: 0;
        right: 0;
        left: 0;
        background: #000000;
        padding: 24px 16px;
        .title {
          font-size: 44px;
          line-height: 44px;
          margin-bottom: 32px;
        }
        img.logo {
          display: block;
        }
        .steps {
          display: flex;
          padding-top: 72px;
          & > .step > span {
            color: #fff;
          }
        }
      }
    }
  }
}
