@import '../variables.scss';
@import '../responsiveUtils.scss';

// .runtime {
//   .runtime-toast:nth-child(3) {
//     top: 215px !important;
//   }
//   .runtime-toast:nth-child(4) {
//     top: 315px !important;
//   }
//   .runtime-toast:nth-child(5) {
//     top: 415px !important;
//   }
// }

.runtime-toast {
  // margin-top: 80px;
  // top: 104px !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background: rgba(65, 65, 65, 0.8);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 4px;

  .toast-content {
    background-color: #414141;
    padding: 15px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    min-width: 300px;

    .left {
      margin-right: 15px;
      display: flex;
      gap: 10px;

      .icon {
        font-size: 24px;
        color: $secondary-color;
        &.icon-upload {
          font-size: 16px;
        }
      }
    }

    .middle {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 0;

      .tag-wrapper {
        margin-bottom: 3px;

        .tag {
          // background-color: #87eac1;
          border-radius: 2px;
          font-size: 8.5px;
          text-transform: uppercase;
          padding: 2px 8px;
          color: #000;
          font-family: 'MediumLLWeb-Bold';
        }
      }

      p {
        color: $secondary-color;
        font-size: 15px;
        line-height: 24px;
        margin: 0;
        // white-space: nowrap;

        span {
          font-size: 15px;
        }
      }
    }

    .right {
      margin-left: 15px;
      display: flex;
      gap: 10px;

      .regular-rounded-button {
        &.active {
          background-color: $green-color;
          color: $secondary-color;
          border-color: $green-color;
        }

        &.deny-button {
          background-color: #414141;
          border-color: #414141;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }
        &.btn-action {
          color: rgba(255, 255, 255, 0.6);
          background: transparent;
          border: transparent;
          text-transform: uppercase;
          box-shadow: none;
          font-weight: bold;
          font-size: 15px;
          line-height: 24px;
          padding: 0;
          &:hover {
            color: #fff;
          }
        }
      }

      .icon-close {
        color: $secondary-color;
      }
    }
  }
}

@include media(phone) {
  .runtime-toast .toast-content {
    min-width: 100%;
    padding: 16px;
    &.button-group {
      flex-wrap: wrap;
      .left {
        flex: 1 1 12%;
        margin-right: 0;
      }
      .middle {
        flex: 1 1 88%;
        margin-bottom: 10px;
      }
      .right {
        margin-left: auto;
        flex: 1 1 100%;
        max-width: 100%;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }

    .middle p {
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-height: 20px;
      max-height: 40px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
    }
  }
}