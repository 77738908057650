@import '../variables.scss';
@import '../responsiveUtils.scss';

.cms-theme-preview-container-wrapper {
  width: 100%;
  background-color: $secondary-color;
  overflow-y: auto;
  overflow-x: hidden;
}

.cms-theme-preview-container {
  width: 100%;
  height: 100%;
  background-color: $secondary-color;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &.mobile-mode-container {
    width: 375px;
    height: 100%;
    overflow: visible !important;
    margin: 0 auto;
    box-shadow: 1px 4px 20px 0px rgba(0, 0, 0, 0.1);

    .cms-theme-preview-body {
      box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .banner {
    top: 56px !important;
  }

  .btn-cms {
    text-transform: none;
    padding: 8px 16px;
    border-radius: 100px;
    font-weight: bold;
    font-size: 15px;
    line-height: 1.6;

    .MuiButton-label {
      vertical-align: middle;
    }
  }

  .cms-theme-preview-body {
    flex: 1;

    &.empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .empty-theme-settings {
      display: grid;
      justify-content: center;
      align-items: center;
      text-align: center;

      .icon {
        background-color: #f4f7f8;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        margin: auto;
        margin-bottom: 24px;

        .icon {
          font-size: 24px;
        }
      }

      span {
        height: 24px;
      }

      .add-a-block {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

.space-template-designer {
  .cms-theme-preview-container {
    overflow: auto;
    padding-top: 50px;

    // &::-webkit-scrollbar {
    //   width: 14px;
    // }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border: 3px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
      height: 100px;
    }
  }

  &.preview-mode {
    .cms-theme-preview-container {
      padding-top: 0;
    }
  }

  &.preview-mode-mobile .cms-theme-preview-container-wrapper {
    overflow: hidden;
  }
}

.salesroom-container {
  .cms-theme-preview-body {
    overflow: auto;
    // &::-webkit-scrollbar {
    //   width: 14px;
    // }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border: 3px solid transparent;
      border-radius: 9px;
      background-clip: content-box;
      height: 100px;
    }
  }
}

@include media(phone) {
  .cms-theme-preview-container {
    z-index: 8;
  }
}