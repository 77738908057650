@import '../variables.scss';

.upload-resources-dialog {
  opacity: 1;
  &.hide {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .MuiDialog-paper {
    width: 450px;
    height: 700px;
    background-color: #fff;
    color: #000;
  }

  .close-button {
    top: 12px;
    right: 8px;

    .close-icon {
      color: #000;
    }
  }

  .content {
    padding: 0;

    .header {
      display: flex;
    }

    .title {
      max-width: 95%;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 24px;
      font-size: 15px;
      font-family: 'MediumLLWeb-Bold';
    }
  }

  .uploader-container {
    height: 480px;
    overflow: hidden;

    &.styled-scrollbar {
      &::-webkit-scrollbar {
        width: 0.3em;
      }
    }

    &:hover {
      overflow: auto;
    }
  }

  .uploader-area {
    padding: 0px 24px 24px;

    .message-warning {
      color: #f44a4a;
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 24px;
    }
    .upload-container {
      .dnd-file-uploader-control {
        &-label {
          color: #000;
        }

        .dnd-file-uploader-control-label-wrapper {
          .icon-info {
            &::before {
              color: rgba(0, 0, 0, 0.6) !important;
            }

            &:hover {
              &::before {
                color: #000 !important;
              }
            }
          }
        }

        &-input {
          .MuiOutlinedInput-root {
            background-color: #fff !important;
            color: $primary-color !important;
            border: 1px solid #dbe1e3;

            &:hover {
              border: 1px solid #dbe1e3;
            }

            &.Mui-error {
              border: 1px solid #f44a4a;
            }
          }

          .MuiFormHelperText-root {
            color: #f44a4a;
          }

          &.red-border {
            .MuiOutlinedInput-root {
              border: 1px solid #f44a4a;
            }
          }

          .action-buttons {
            .MuiIconButton-root {
              .icon-refresh,
              .cancel-icon {
                color: #f44a4a;
              }

              .edit-icon,
              .delete-icon {
                color: rgba(0, 0, 0, 0.6);
              }
            }

            .action-button {
              .MuiButton-label {
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }
        }

        .uploaded-files-section {
          .invalid-item {
            background-color: #fff;
            border: 1px solid #dbe1e3;
            border-radius: 4px;

            .action-buttons {
              .MuiIconButton-root {
                .edit-icon,
                .delete-icon {
                  color: rgba(0, 0, 0, 0.6);
                }
              }
            }
          }
        }

        .form-warning {
          color: #f44a4a;
        }
      }

      .upload-area {
        .or-divider {
          hr {
            border: 1px solid #eceeef;
          }

          &-text {
            color: $primary-color;
            opacity: 0.4;
          }
        }

        .upload-btns {
          .upload-btn {
            border: 1px dashed rgba(0, 0, 0, 0.4);
            // background-color: rgba(219, 225, 227, 0.2);

            p {
              color: $primary-color;
            }
          }
        }
      }

      .upload-progress {
        background-color: rgba(219, 225, 227, 0.2);
        color: $primary-color;
        border: 1px solid #dbe1e3;

        .bottom-section {
          .message {
            color: rgba(0, 0, 0, 0.6);

            &.failed {
              color: #f44a4a;
            }
          }

          .action-button {
            .MuiButton-label {
              color: rgba(0, 0, 0, 0.6);
            }
          }
        }

        .MuiLinearProgress-root {
          background-color: rgba(0, 0, 0, 0.2);

          &.failed {
            .MuiLinearProgress-bar {
              background-color: #f44a4a;
            }
          }
        }
      }
    }

    .information-alert {
      margin-top: 20px;
      font-size: 12px;
    }
  }

  .submit-button {
    width: 100%;
    position: absolute;
    bottom: 0;
    .Mui-disabled {
      background-color: #dbe1e3 !important;

      .MuiButton-label {
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}
