.grid-view-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 100px);
  max-width: 100%;
  overflow: hidden;

  .loading-item {
    grid-column: span 5;
  }
}

.resource-grid-view-container {
  overflow-y: auto;

  &.styled-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
}

.grid-view-container {
  container-type: inline-size;

  .resource-grid-view-container {
    padding: 0 8px 0 16px;
    margin-top: -8px;
    height: calc(100% - 8px);

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: flex-start;

    .resource-grid-item {
      padding: 8px;
      box-sizing: border-box;
    }
  }

  @container(width < 500px) {
    .resource-grid-view-container {
      .resource-grid-item {
        max-width: 50%;
        flex-basis: 50%;
      }
    }
  }

  @container(width > 500px) and (width < 700px) {
    .resource-grid-view-container {
      .resource-grid-item {
        max-width: 33.33%;
        flex-basis: 33.33%;
      }
    }
  }

  @container(width >700px) and (width < 900px) {
    .resource-grid-view-container {
      .resource-grid-item {
        max-width: 25%;
        flex-basis: 25%;
      }
    }
  }

  @container(width >900px) {
    .resource-grid-view-container {
      .resource-grid-item {
        max-width: 20%;
        flex-basis: 20%;
      }
    }
  }
}