@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.comments-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;

  .comments-header {
    display: flex;
    align-items: center;
    margin: 20px;

    .alert-info {
      background-color: #F0F5FF;
      color: #000;
    }

    .comment-box {
      flex: 1;
      width: 100%;

      .attach-file {
        margin-bottom: 0 !important;
      }
    }

    .comments-header-right {
      padding: 12px 10px;
      align-self: flex-start;

      svg {
        cursor: pointer;
        margin-top: 7px;
      }
    }
  }

  .comments-list {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 16px 24px;

    >div {
      position: relative;

      .inview-element {
        position: absolute;
        width: 1px;
        height: 150px;
        bottom: 0;
      }
    }

    .comment-item {
      display: flex;
      flex-direction: column;

      &.deleted {
        .comment-item-body {

          .body.myself .text,
          .body .text {
            padding: 8px 12px;
            background-color: #fff;
            border: 1px solid #DBE1E3;
            border-radius: 4px;
            color: rgba(0, 0, 0, 0.6);
            border-radius: 8px;
          }
        }
      }

      .comment-body-date {
        font-family: $FMediumBold;
        font-size: 9px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.5px;
        text-align: center;
        text-transform: uppercase;
      }

      .comment-item-body {
        display: flex;
        align-items: center;
        max-width: 100%;

        .comment-body {
          display: flex;
          flex-direction: column;

          .comment {
            display: flex;
            gap: 12px;

            .comment-content {
              display: flex;
              flex-direction: column;
              gap: 4px;
            }

            .message {
              display: flex;
              gap: 12px;

              &.align-left {
                .parent-comment {
                  .message-action {
                    flex-direction: row-reverse;
                  }
                }
              }
            }

            .action-container {
              display: flex;
              align-items: center;
              opacity: 0;
              width: 76px;

              &.active {
                opacity: 1;
              }
            }

            &:hover {
              .action-container {
                opacity: 1;
              }
            }

            &.align-left {
              flex-direction: row-reverse;
              justify-content: flex-end;


              .comment-item-footer {
                justify-content: unset;
              }
            }

            &.align-right {
              flex-direction: row;

              .parent-comment {
                text-align: right;

                .parent-sender {
                  justify-content: flex-end;
                }
              }
            }
          }

          .sender-name {
            font-size: 13px;
            color: rgba(0, 0, 0, 0.60);
            text-align: left;
            padding-left: 40px;
            margin-top: 24px;

            &.align-right {
              text-align: right;
            }
          }


        }

        &.comment-left {
          align-self: flex-start;
        }

        &.comment-right {
          align-self: flex-end;

          .comment-body {
            align-items: flex-end;
          }
        }

        .avatar-container {
          flex: 1 1 24px;

          width: 24px;
          max-width: 24px;
          margin: auto 0;

          .user-avatar {
            width: 24px !important;
            height: 24px !important;

            h6 {
              font-size: 12px;
            }
          }
        }

        .parent-comment {
          &.parent {
            .body {
              margin-top: -10px;
            }
          }

          .response-comment-info {
            background-color: #F4F7F8;
            padding: 8px 12px 20px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            gap: 4px;

            .parent-sender {
              font-size: 10px;
              display: flex;
              gap: 4px;
              line-height: 16px;
              color: rgba(0, 0, 0, 0.6);
              margin: 0;

              .icon-share {
                display: flex;
                align-items: center;
              }
            }

            .parent-message {
              font-size: 13px;
              line-height: 16px;
              margin: 0;
              word-break: break-word;
              text-align: left;

              &.deleted {
                color: rgba(0, 0, 0, 0.6);
              }
            }
          }
        }

        .message-action {
          display: inline-flex;
          gap: 12px;
          max-height: 95%;
        }

        .body {
          width: 100%;
          flex: 1;
          border-radius: 8px;
          position: relative;
          user-select: none;

          .comment-text {
            display: inline-block;
          }

          .status {
            width: 100%;
            display: flex;
            justify-content: right;
            color: rgba(0, 0, 0, 0.6);
            font-size: 13px;
            flex-wrap: nowrap;
            white-space: nowrap;

            &.failed {
              color: #f44a4a;

              .retry-button {
                margin-left: 5px;
                cursor: pointer;
                color: rgba(0, 0, 0, 0.6);

                &:hover {
                  color: $primary-color;
                }
              }
            }


          }

          a {
            // padding: 10px 12px;
            line-height: 16px;
            border-radius: 8px;
          }

          &.myself {
            .text {
              background-color: #B8F5DB;
            }

            // a {
            //   background-color: #B8F5DB;
            //   padding: 10px 12px;
            // }
          }

          &.resource {
            padding: 0;
          }


          .page-not-found {
            font-size: 0.8em;

            .unauthorized-div {
              margin: initial;
            }
          }

          .username-container {
            display: flex;

            .username {
              margin: 0;
              text-transform: capitalize;
              font-size: 15px;
              line-height: 24px;

              span {
                text-transform: lowercase;
                margin-left: 5px;
              }
            }
          }

          .text {
            font-size: 15px;
            line-height: 20px;
            margin: 0;
            background-color: #ECEEEF;
            border-radius: 8px;
            text-align: left;
            color: rgba(0, 0, 0, 0.8);
            padding: 10px 12px;

            span {
              max-width: 276px;
              word-break: break-word;
              display: block;
              flex-shrink: 1;
            }
          }

          img {
            max-height: 200px;
            max-width: 100%;
            margin-bottom: 5px;
            min-width: 24px;

          }

          .image-container {
            cursor: pointer;
            user-select: none;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
          }

          a {
            word-break: break-all;
          }
        }

        .file-name {
          color: $primary-color;
          text-decoration: underline;
          font-size: 13px;
          padding: 0 0 6px 0;
          display: block;
        }

        .actions {
          opacity: 0;
          margin: -4px 5px;
          align-self: flex-start;

          .MuiIconButton-colorPrimary {
            color: $primary-color;
          }
        }

        &:hover {
          .actions {
            opacity: 1;
          }
        }
      }

      .comment-item-footer {
        display: flex;
        justify-content: flex-start;
        justify-content: right;
        align-items: center;

        .liked-message {
          padding: 4px 6px;
          background-color: #fff;
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
          display: flex;
          align-items: center;
          margin-top: -10px;
          z-index: 2;
          height: 24px;
          gap: 4px;
          border-radius: 15px;

          &.liked {
            border: 2px solid #1FDA8B;
          }

          .number-of-likes {
            font-size: 13px;
          }

          .MuiSvgIcon-root {
            color: #F44A4A;
            font-size: 16px;
          }
        }

        span {
          font-size: 13px;
          margin-right: 15px;
          cursor: pointer;
          opacity: 0.6;

          &.like-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 0;
            margin-bottom: 1px;
            opacity: 1 !important;

            .like-count {
              margin-right: 2px;
              opacity: 1 !important;
            }

            .MuiSvgIcon-root {
              height: 16px;
            }
          }

          &.date-time {
            font-weight: normal;
            font-size: 13px;
            opacity: 0.4;
            margin-top: 1px;
          }

          &.liked {
            color: $primary-color;
            opacity: 1;
          }
        }
      }

      &.level-2 {
        margin-top: 18px;
      }
    }

    .comment-item-reply {
      margin-left: 38px;
      margin-top: 5px;

      .attach-file {
        margin-right: 1px;

        .MuiLinearProgress-barColorSecondary {
          background-color: #1fda8b !important;
        }
      }

      .cancel {
        color: $primary-color !important;
        padding-left: 5px !important;
      }
    }
  }

  .comments-badge {
    position: fixed;
    right: 40px;
    cursor: pointer;
    bottom: 5px;

    svg {
      font-size: 30px;
      color: $primary-color;
    }
  }
}

.file-menu-item {
  display: flex;
  align-items: center;

  .image-icon {
    width: 25px;
  }

  svg {
    color: $primary-color;
    margin: 0 3px;
    font-size: 19px;
  }

  span {
    margin-left: 10px;
  }
}

@include media(phone) {
  .comments-container {
    .comments-list {
      .comment-item {
        .comment-item-body {
          .actions {
            opacity: 1;
            margin: -3px 5px;
            align-self: flex-start;
          }
        }
      }
    }

    // .icon-video-message {
    //   display: none;
    // }
  }

  .comments-left-column {
    padding: 0 10px !important;
  }
}