.spce-popover {
  .MuiPopover-paper {
    width: 600px;
    margin-top: 3px;
    margin-left: -20px;
    height: auto;
    max-height: 600px;

    .content {
      display: flex;
      flex-direction: column;
      min-height: 200px;
      max-height: 600px;
      position: relative;
      padding-bottom: 0;
      padding-top: 25px;

      .close-button {
        position: absolute;
        right: 20px;
        top: 20px;
        z-index: 999;

        .icon-close {
          color: #000;
          font-size: 14px;
        }
      }

      span.title {
        font-family: MediumLLWeb-Bold;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        display: block;
        width: 100%;
      }

    }
  }
}
