@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.participant-form {
  position: relative;
  padding: 0 !important;
  flex: 1;
  display: flex;
  flex-direction: column;

  .participant-form-content {
    flex: 1;
    display: flex;
    flex-direction: column;

    .buttons {
      margin: auto;
      width: 100%;
      padding: 0px 24px;

      .large-save-button {
        height: 40px;
        width: 100%;
        border-radius: 100px;
        background-color: rgba(31, 218, 139, 1);
        color: rgba(0, 0, 0, 0.8);
        margin-bottom: 24px;
        font-size: 15px;
        font-family: 'MediumLLWeb-Bold';

        &.MuiButton-disabled {
          background-color: rgba(236, 238, 239, 1);
        }

        &.Mui-disabled.is-processing {
          background-color: rgba(31, 218, 139, 1);
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .form-body-wrapper {
      flex: 1;

      .form-body {
        margin: 24px;
        margin-top: 0;
        padding: 0;

        .content-header {
          display: flex;
          flex-direction: row;
          align-items: center;

          .card-title {
            display: flex;
            margin-top: 0;
            color: $primary-color;
            font-family: MediumLLWeb-Bold;
            margin-bottom: 0px;

            margin-right: 20px;
          }
        }

        .partner-message {
          font-size: 13px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .close-form-icon {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
      color: $primary-color;
    }

    .MuiTypography-body1 {
      color: $primary-color;
    }

    .item-label {
      color: $primary-color;
      margin-bottom: 5px;
      font-size: 13px;
      line-height: 16px;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0;
    }

    .MuiAutocomplete-endAdornment {
      display: none;
    }

    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0;
    }

    .MuiInputBase-root.MuiOutlinedInput-root {
      background-color: #fff;
      color: #000;
      border-radius: 6px;
      min-height: 40px;

      .MuiOutlinedInput-input {
        padding: 11px 32px 11px 16px;
        display: flex;
        align-items: center;
      }

      .MuiOutlinedInput-notchedOutline {
        padding: 0;
        border: 1px solid #dbe1e3;
        box-shadow: none;
      }

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: #000;
        }
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 1px;
        border-color: #000;
      }

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.3);
        background-color: #eceeef;

        .MuiOutlinedInput-notchedOutline {
          border-color: #dbe1e3 !important;
        }

        .MuiTypography-root,
        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.2);
        }
      }

      .icon-accesslevel::after {
        color: #000;
      }

      .icon-accesslevel::before {
        color: rgba(0, 0, 0, 0.2);
      }
    }

    .MuiFormHelperText-root.Mui-error {
      margin-left: 0 !important;
    }

    .MuiFormHelperText-root.Mui-error {
      color: #f44a4a;
    }

    .MuiSvgIcon-root {
      color: rgba(0, 0, 0, 0.6);
    }

    .MuiSelect-select:focus {
      background-color: transparent !important;
    }

    .participant-role-picker {
      .MuiInputBase-root {
        width: 100%;

        .MuiOutlinedInput-input {
          padding: 0 !important;

          .selected-value {
            display: flex;
            align-items: center;
            padding: 11px 16px;
            width: 100%;
          }
        }
      }
    }

    .participant-level-select {
      .MuiOutlinedInput-input {
        padding: 9px 16px !important;
      }

      .selected-value {
        padding: 0 !important;
      }
    }
  }
}

@include media(phone) {
  .participant-form {
    .participant-form-content {
      .buttons {
        .large-save-button {
          width: 327px;
        }
      }
    }
  }
}

.MuiTooltip-tooltip.participant-form-tooltip {
  max-width: 193px;
}
