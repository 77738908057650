.large-save-button {
    margin-bottom: 24px;
    border-radius: 100px;
    background-color: rgba(31, 218, 139, 1);
    width: 412px;
    color: #000;
    font-size: 15px;
    margin-bottom: 24px;
    height: 40px;
    font-family: 'MediumLLWeb-Bold';
    .is-processing {
        background-color: rgba(31, 218, 139, 1);
    }
    &.Mui-disabled {
        background-color: rgba(236, 238, 239, 1);
        opacity: 1;
        color: rgba(0, 0, 0, 0.4);
    }
    &.Mui-disabled.is-processing {
        background-color: rgba(31, 218, 139, 1);
    }
    &:hover {
        background-color: rgba(31, 218, 139, 1); 
    }
}