@import '../variables.scss';

.filter-box {
  .MuiInputBase-root {
    height: 40px;

  }

  .MuiInputBase-input {
    color: $secondary_color;

  }

  .MuiInput-underline:before {
    border-bottom: none !important;
  }

  .MuiInput-underline:after {
    border-bottom: none !important;
  }

  .filter-box-input-dark {
    input {
      color: #fff;
      margin-top: 5px;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill,
    input:-webkit-autofill:hover,
    textarea:-webkit-autofill:hover,
    select:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill:focus,
    input:-webkit-autofill:active,
    textarea:-webkit-autofill:active,
    select:-webkit-autofill:active,
    .MuiInputBase-input:-webkit-autofill {
      box-shadow: 0 0 0 1000px $primary-color-light inset !important;
      -webkit-text-fill-color: #fff !important;
      // font-family: 'Work Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }

  .filter-box-input-light {
    .MuiInputBase-input {
      color: $primary-color;
    }

    .MuiSvgIcon-root {
      color: $primary-color;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid #DBE1E3;
    }

    .MuiOutlinedInput-root {
      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #000;
        }
      }

      &.Mui-focused {
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #000;
        }
      }

      &.Mui-disabled {
        color: rgba(0, 0, 0, 0.3);
        cursor: default;
        background-color: #eceeef;

        .MuiInputBase-input {
          color: rgba(0, 0, 0, 0.6);
        }

        .MuiSvgIcon-root {
          color: rgba(0, 0, 0, 0.3);
        }

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: 1px solid #DBE1E3;
          }
        }

        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #DBE1E3;
          top: -4px;
        }
      }
    }

    input {
      margin-top: 0;
    }
  }
}