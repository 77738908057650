.message-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  padding: 20px;

  .link-text {
    display: block;
    color: #77a2fe;
    text-decoration: underline;
    cursor: pointer;
  }
}