@import '../../variables.scss';

.resource-name {
  display: flex;
  align-items: center;
  gap: 10px;
  min-height: 24px;
  cursor: pointer;

  .icon-folder {
    color: #dbe1e3;

    &::after {
      color: #000;
    }
  }

  .icon-unsupported-file {
    font-size: 24px;
    max-width: 20px;
    height: 24px;
    margin-left: -4px;
  }

  .name-details-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .name-details {
    overflow: hidden;

    &.flex-direction-row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    div:first-child {
      flex: 1;
      overflow: hidden;
      line-height: 24px;
    }
  }

  .number-file {
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
    line-height: 16px;

    .dot {
      display: none;
      margin: 0 4px;
    }

    .meeting-time+.dot {
      display: inline-block;
    }
  }

  .processing-tag-wrapper {
    color: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8.5px;
    line-height: 12px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    font-family: $FMediumBold;
    margin-left: 8px;
    white-space: nowrap;

    .processing {
      background-color: #ff7f7f;
      padding: 2px 4px;
      border-radius: 2px;
    }
  }

  .resource-indicator {
    width: 20px;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    margin-right: 4px;
    margin-left: 4px;

    &::before {
      color: rgba(0, 0, 0, 0.6);
    }

    &.icon-onedrive {
      font-size: 12px;
    }
  }
}