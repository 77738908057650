.reload-snackbar {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 24px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  width: 360px;
  .icon-error {
    font-size: 24px;
    margin-top: 10px;
  }
  &-content {
    color: rgba(0, 0, 0, 0.6);
    font-size: 14px;
    .title-message {
      color: #000;
      font-size: 15px;
    }
    .spce-btn {
      margin-top: 10px;
    }
  }
}