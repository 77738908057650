@import '../responsiveUtils.scss';

.confirm-dialog {
  .MuiDialog-paper {
    background-color: #ffffff;
    width: 400px;
    min-height: 300px;
    color: #000;
    backdrop-filter: blur(20px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .close-button {
    top: 0px;
    right: 0px;

    .close-icon {
      color: #000;
    }
  }

  .content {
    width: 100%;
    min-height: 80px;
    padding: 24px;
    flex: 1;

    .title {
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      font-family: 'MediumLLWeb-Bold';
      text-align: center;
      text-transform: uppercase;
    }

    span.integration-icon {
      font-size: 50px;
      align-self: center;
    }

    .message {
      margin: 24px 0;
      font-size: 15px;
      line-height: 24px;
      font-family: 'MediumLLWeb-regular';
      display: block;
      text-align: center;
      word-break: break-word;
    }

    .link {
      color: #4783ff;
    }
  }

  .buttons {
    display: flex;
    height: 88px;

    .button {
      margin: auto;
      width: 100%;
      height: 100%;
      text-transform: uppercase;
      font-family: 'F37 Judge';
      font-size: 30px;

      .MuiButton-label {
        font-family: 'F37Judge-MediumCondensed';
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        color: #fff;
      }

      &.cancel {
        background-color: rgba(244, 247, 248, 1);
        color: rgba(0, 0, 0, 0.6);

        .MuiButton-label {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      &.confirm {
        background-color: #000;
        color: #fff;
      }

      &.MuiButton-root {
        border-radius: 0;
      }

      .MuiButton-label {
        font-family: 'F37Judge-MediumCondensed';
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
      }

      &.Mui-disabled {
        > span {
          opacity: 0.6;
        }
      }
    }
  }
}

.confirm-dialog {
  &.z-index-1403 {
    z-index: 1403 !important;
  }
}

@media only screen and (max-width: 991px) {
  .confirm-dialog {
    .MuiDialog-paperFullScreen {
      height: auto;
    }
  }
}

@include media(phone) {
  .confirm-dialog {
    .MuiDialog-paper {
      width: 90%;
      max-height: 70%;
    }

    .buttons {
      .button {
        font-size: 24px;
      }
    }
  }
}
