@import '../../variables';

// Please dont use variables for colors like $primary-color, $secondary-color, because the same style should be used everywhere.
.customized-menu-popover {
  .MuiPopover-paper {
    margin-top: 3px;
    margin-left: -10px;
  }
}

.customized-menu-wrapper {
  display: flex;
  flex-direction: column;
  background-color: white;

  .customized-menu-header {
    padding: 15px 15px 0 15px;

    .customized-menu-title {
      color: #000;
      font-family: 'MediumLLWeb-Bold';
      font-size: 8.5px;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  }

  .customized-menu-body {
    min-height: 20px;
    max-height: 220px;
    overflow: auto;

    .customized-menu-list {
      .MuiListItem-button {
        &:hover {
          background-color: #f4f6f8;
        }
      }
      .MuiListItem-root {
        &.Mui-disabled {
          opacity: 0.3;
        }
        .MuiListItemIcon-root {
          min-width: 0;
          .MuiSvgIcon-root {
            color: #1fda8b;
          }
        }
        .MuiListItemText-root {
          .MuiListItemText-primary {
            color: #000;
            font-family: 'MediumLLWeb-Regular';
            font-size: 14px;
            line-height: 17px;
          }
        }
      }
    }
  }

  .customized-menu-footer {
    .customized-menu-action-button {
      color: #000;
      font-family: 'MediumLLWeb-Bold';
      font-size: 8.5px;
      line-height: 12px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      background-color: #fff;
      padding: 20px;

      &.disabled {
        opacity: 0.4;
      }
    }
  }
}
