@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.participant-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  cursor: pointer;

  .tags {
    flex-wrap: nowrap;
    overflow: hidden;
  }

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }

  .avatar-block {
    border-radius: 6px 0px 0px 6px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 34px;
    margin-right: 6px;

    .online-background {
      height: 14px;
      width: 14px;
      border-radius: 100%;
      margin-top: 28px;
      margin-left: -12px;
      background-color: #fff;
      position: sticky;
      align-items: center;
      text-align: center;

      .circle {
        background-color: #fff;
        height: 10px;
        width: 10px;
        background-color: #dbe1e3;
        border-radius: 100%;
        margin: auto;
        margin-top: 2px;

        &.online {
          background-color: #1fda8b;
        }
      }
    }

    .avatar {
      display: flex;
      flex: 1 1;

      span {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: $secondary-color;
        opacity: 0.4;
        font-family: 'F37Judge-MediumCondensed';
        font-size: 28px;

        &.online {
          color: #00cd7f;
          opacity: 1;
        }
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;

    .message {
      font-size: 13px;
      display: flex;
      color: #000;
      gap: 4px;

      .time-stamp {
        color: rgba(0, 0, 0, 0.6);
      }
    }

    .name-role-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      width: 100%;
      overflow: hidden;

      .role-wrapper {
        margin-top: -2px;

        .role {
          font-family: 'MediumLLWeb-Bold';
          font-size: 8.5px;
          line-height: 12px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          color: #000000;
          padding: 2px 4px;
          border-radius: 2px;
        }
      }

      .name {
        margin: 0;
        text-transform: capitalize;
        padding-left: 2px;
        padding-bottom: 0px;
        font-family: 'MediumLLWeb-Regular';
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;

        span {
          text-transform: lowercase;
          margin-left: 6px;
        }

        &.description {
          color: #908e8e;
          font-size: 13px;
        }
      }

      .level {
        position: relative;
        display: flex-inline;
        justify-content: center;
        align-items: center;
        padding: 0 10px;

        i {
          font-size: 20px;
          position: absolute;
        }

        span {
          z-index: 1;
          position: absolute;
          font-size: 10px;
          font-family: 'MediumLLWeb-Regular';
          font-weight: bold;
          font-weight: 700;
          color: #000;
          top: 4px;
        }

        &.only-me {
          i {
            color: #dbe1e3;
            opacity: 0.2;
          }

          span {
            color: #fff;
          }
        }

        &.share-organization {
          i {
            color: #4783ff;
          }

          span {
            color: #fff;
          }
        }

        &.public {
          i {
            color: #fff;
          }

          span {
            color: #000;
          }
        }
      }
    }

    .bottom-content {
      display: flex;
      align-items: center;
      width: 100%;

      svg {
        font-size: 16px;
        opacity: 0.4;
        margin-right: 5px;
      }

      span {
        font-family: 'MediumLLWeb-Regular';
        font-size: 13px;
        line-height: 16px;
        opacity: 0.6;
        padding-left: 3px;
      }

      .email {
        width: 100%;
        display: inline-block;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span:last-child {
        flex: 1;
        width: 100%;
      }

      &.has-active {
        .email {
          max-width: 45vw;
          width: unset;
        }
      }
    }
  }

  .icon-right {
    display: flex;
    align-items: center;

    .buttonOpacity {
      opacity: 0;
    }

    .whiteButton {
      color: $secondary-color;
      padding: 5px;
      margin-right: -5px;
    }

    .checkbox {
      color: #414141;
    }
  }

  .blue-dot {
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

  &:hover {
    .icon-right {
      .buttonOpacity {
        opacity: 1;
      }
    }
  }

  .action-buttons {
    .btn {
      padding: 4px 8px;
      border-radius: 4px;
      font-style: normal;
      font-size: 10px;
      font-family: 'MediumLLWeb-Bold';
      line-height: 16px;
      min-width: unset;
      margin-left: 8px;

      &.btn-deny {
        background: rgba(219, 225, 227, 0.2);
      }

      &.btn-accept {
        background: #1fda8b;
        color: #000;
      }
    }
  }
}