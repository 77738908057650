@import '../responsiveUtils.scss';

.connect-dialog {
  .MuiDialog-paper {
    background-color: #ffffff;
    width: 500px;
    min-height: 350px;
    color: #000;
    backdrop-filter: blur(20px);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }

  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .content {
    width: 100%;
    // min-height: 80px;
    padding: 30px 50px;
    padding-bottom: 100px;
    text-align: center;

    .title {
      padding-top: 25px;
      font-size: 18px;
      line-height: 24px;
      font-family: 'MediumLLWeb-Bold';
      text-transform: uppercase;
    }

    .logo {
    //   width: 45px;
    //   align-self: center;
      font-size: 48px;
    }

    .message {
      margin: 16px 0;
      font-size: 15px;
      line-height: 20px;
      font-family: 'MediumLLWeb-regular';
      display: block;
      text-align: center;
    }

    .note {
      padding: 20px 0;
      color: #666666;
    }
  }

  .icon-check-circle {
    font-size: 26px;
    margin-right: 15px;
  }

  .MuiListItem-root {
    padding: 0 10px;
  }

  .MuiListItemIcon-root {
    min-width: 36px;
  }

  .buttons {
    width: 100%;
    position: absolute;
    display: flex;
    height: 80px;
    bottom: 0;

    .button {
      margin: auto;
      width: 100%;
      height: 100%;
      color: #fff;
      text-transform: uppercase;
      font-family: 'F37 Judge';
      font-size: 30px;

      &.confirm {
        background-color: #000;
      }

      &.MuiButton-root {
        border-radius: 0;
      }

      .MuiButton-label {
        font-family: 'F37Judge-MediumCondensed';
        font-weight: 500;
        font-size: 30px;
        line-height: 30px;
        color: #fff;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .disconnect-dialog {
    .MuiDialog-paperFullScreen {
      height: auto;
    }
  }
}

@include media(phone) {
  .disconnect-dialog {
    .MuiDialog-paper {
      width: 90%;
      max-height: 60%;
    }

    .buttons {
      .button {
        font-size: 24px;
      }
    }
  }
}
