@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.recoding-button-wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.recoding-button.MuiIconButton-root {
  padding: 0;

  .icon-recording {
    font-size: 20px;

    .path1:before {
      color: #fff;
    }
  }

  &.Mui-disabled {
    opacity: 0.6;
  }
}