.password-dialog {
  &.MuiDialogContent-root {
    &:first-child {
      padding: 20px 30px 30px !important;
    }
  }

  p {
    text-align: center;
    font-size: 15px;
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    input[type='text'] {
      text-align: center;
      font-size: 25px;
      font-weight: bold;
    }

    button {
      margin-top: 30px;
    }
  }
}
