.remove-block-dialog {
  .option {
    text-align: center;
    line-height: 20px;

    .MuiCheckbox-colorSecondary.Mui-checked {
      color: #000;
    }

    .MuiTypography-body1 {
      font-size: 15px;
      font-family: 'MediumLLWeb-regular';
    }
  }
}
