@import '../variables.scss';
@import '../responsiveUtils.scss';

.organization-users-dialog {
  background-color: #000;
  color: #fff;
  max-width: 100%;
  img.logo {
    position: fixed;
    top: 48px;
    left: 48px;
    width: 72px;
    height: 29px;
  }
  .dialog-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .organization-users-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .organization-users-title {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-bottom: 24px;
  }
  .select-organizations {
    max-width: 100%;
    .MuiSelect-outlined.MuiSelect-outlined {
      width: 300px;
      background: var(--background-bg-inv-input, rgba(219, 225, 227, 0.20));
      padding: 8px 12px;
      font-size: 15px;
      font-weight: 400;
    }
  }
  .select-input {
    color: #fff;
    margin: 10px auto 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  .MuiSvgIcon-root {
    color: #fff;
    font-size: 26px;
  }
  .btn-green {
    margin-top: 30px;
    margin-left: -10px;

    &:hover {
      background-color: #0c8255;
    }

    &.Mui-disabled {
      background-color: $green-color !important;
      opacity: 0.8;
    }
  }
  .error-message {
    color: red;
  }
}

.MuiMenuItem-root {
  background-color: #000;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

@include media(phone) {
  .organization-users-dialog {
    img.logo {
      left: 24px;
      top: 24px;
    }
    &.responsive-dialog .MuiDialog-paper {
      width: calc(100% - 32px);
    }
    .select-organizations {
      max-width: 280px;
    }
  }
  .MuiMenuItem-root.select-organizations-item {
    width: 100%;
    word-break: break-all;
    overflow: unset;
    white-space: unset;
  }
}