@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.add-new-participant-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1399;
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #17171a;
        opacity: 0.4;
    }

    &.fullscreen {
        .MuiDialog-paper {
            max-width: unset;
            max-height: unset;
        }
    }

    .content {
        position: relative;
        min-height: 332px;
        display: flex;
        flex-direction: column;
        max-height: 100%;
        z-index: 1;
        color: #000;

        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        overflow: hidden;

        .close-button {
            position: absolute;
            top: 14px;
            right: 5px;
            z-index: 1499;
        }

        .form-content {
            padding: 24px;
            display: flex;
            flex-direction: column;
            gap: 24px;
        }

        .MuiOutlinedInput-root {
            color: #000;
        }

        .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
            border-color: rgba(0, 0, 0, 0.23);
        }

        .MuiFormHelperText-root.Mui-error {
            margin-left: 0;
            color: #F44A4A;
            font-size: 13px;
        }

        .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: #000;
        }

        .message {
            text-align: center;
            z-index: 1;
            font-size: 15px;
            line-height: 20px;

            .title {
                text-transform: uppercase;
                font-family: MediumLLWeb-Bold;
                font-size: 18px;
                line-height: 24px;
                display: block;
                margin-top: 24px;
            }

            .sub-title {
                opacity: 0.6;
            }
        }

        .background {
            display: flex;
            width: 100%;
            min-height: 200px;
            margin-bottom: 20px;
            max-height: 50vh;
        }

        .logo-image {
            width: 100px;
            margin: auto;
            margin-bottom: 10px;
        }

        .description {
            max-width: 400px;
            margin: auto;
            text-align: center;

            >a {
                color: $primary-color;
            }
        }

        >span {
            text-align: center;
            margin-bottom: 20px;
            padding-left: 40px;
            padding-right: 40px;
        }

        .button-wrapper {
            margin-top: auto;

            .spce-btn {
                border: 0;
                cursor: pointer;

                &:hover {
                    color: $green-color;
                }
            }
        }

        .form {
            display: flex;
            gap: 16px;
            justify-content: space-between;
            max-width: 400px;
            margin: auto;
            width: 100%;

            &.fullwidth {
                >.MuiFormControl-fullWidth {
                    width: 100%;
                }
            }

            .item-label {
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                margin: 0;
                margin-bottom: 4px;
            }
        }

        .form-item {
            width: 100%;
        }
    }
}