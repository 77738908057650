@import '../variables.scss';
@import '../responsiveUtils.scss';

.premium {
  background-color: #ff7f7f;
}

.enterprise {
  background-color: #ff7f7f;
}

.spce-portal-header {
  .content {
    padding-left: 16px;
    justify-content: flex-end;
  }
}

.no-access {
  font-size: 20px;
  font-family: 'MediumLLWeb-Bold';
  text-transform: uppercase;
  padding-bottom: 20px;
}

.template-lock {
  color: #000;
  width: 22px;
  height: 22px;
  margin-left: 10px;
  padding: 1.5px;
  opacity: 1;
}

.space-list-container {
  .space-list-content-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1680px;
  }

  section {
    .group-buttons {
      display: flex;
      align-items: center;
      padding-left: 24px;
      padding-top: 24px;

      .btn {
        text-transform: capitalize;
        font-family: MediumLLWeb-Regular;
        background-color: transparent;
        color: rgba(0, 0, 0, 0.6);
        font-weight: 400;
        font-size: 15px;
        padding-left: 16px;
        padding-right: 16px;
        transition: unset;

        &.active {
          border-radius: 8px 8px 0px 0px;
          background-color: $secondary-color;
          color: $primary-color;
          font-family: MediumLLWeb-Bold;
        }

        &:hover {
          color: $primary-color;
        }

        .MuiTouchRipple-root {
          display: none;
        }
      }
    }
  }

  .sections-container {
    overflow: auto;
    padding: 0px 24px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    flex: 1;

    .space-section {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      flex: 1;
      overflow: hidden;

      // flex: 1;
      .space-section-according {
        box-shadow: none;
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      .space-section-heading {
        padding: 0 24px;
        gap: 8px;

        .MuiAccordionSummary-content {
          margin: 0;
          justify-content: space-between;
          align-items: center;
        }
      }

      .MuiAccordionDetails-root {
        padding: 0;
        flex-direction: column;
      }
    }


  }

  .sections-container-loading {
    background-color: #fff;
    padding: 0;
    margin: 0px 24px 24px 24px;
    @extend .sections-container;
  }

  .sections-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 24px;
  }

  .space-actions-wrapper {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 10;

    .filter-button {
      padding: 8px 16px;
      line-height: 20px;
      height: 40px;
    }

    .space-filter .btn.btn-outline {
      padding: 8px 12px;
      line-height: 24px;
    }

    .filters {
      display: flex;
      flex-wrap: nowrap;

      .filters-box {
        margin-left: 4px;
      }

      >div {
        .MuiSwitch-colorPrimary.Mui-checked+.MuiSwitch-track {
          opacity: 1;
        }
      }
    }

    .sort-box {
      .MuiOutlinedInput-notchedOutline {
        border-color: #dbe1e3;
        border-width: 1px;
      }

      .MuiInputBase-root {
        &.MuiFocused {
          border-color: #000;
        }

        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border-color: #000;
          }
        }
      }

      .MuiSelect-root {
        min-width: 165px;
        padding: 10px 44px 10px 12px;
      }
    }

    .filter-box {
      min-width: 200px;

      .MuiInputBase-root {
        background: #ffffff;
        border-radius: 4px;
        padding: 0 8px;
        border: 1px solid #dbe1e3;

        &.Mui-focused {
          border-color: #000;
        }

        &:hover,
        &:focus {
          border-color: #000;
        }
      }

      .MuiSvgIcon-root,
      .MuiInputBase-input {
        color: $primary-color;
      }
    }

    .regular-rounded-button {
      background-color: #1fda8b;
      color: #000;

      &:hover {
        color: #f4f7f8;
      }
    }
  }

  .space-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 16px;

    &-right {
      display: flex;
      align-items: center;
      gap: 8px;

      .btn-primary,
      .btn-secondary {
        padding: 10px;
      }
    }
  }

  .user-container {
    background-color: unset;
    padding: unset;
    height: calc(100% - 64px);
    margin: 0 24px 24px 24px;
  }

  .navbar {
    padding-right: 0px !important;

    .navbar-grid {
      margin-right: 5px;
    }

    .filter-box {
      background-color: $secondary-color-light;
      border-radius: 6px;
      padding: 0 10px;
      min-width: 250px;

      input {
        color: $primary-color;
        font-family: MediumLLSub-RegularSubset;
      }

      svg {
        color: $secondary-color-variant;
      }
    }
  }

  .space-form {
    display: flex;
    align-items: center;

    form {
      flex: 1;

      .inputs {
        padding: 15px 20px;

        input {
          min-width: 200px;
        }
      }

      .Mui-error {
        input {
          border: 1px solid #ff7f7f;
          border-radius: 5px;
        }
      }

      .MuiFormHelperText-root {
        margin: 2px 0;
      }

      .buttons {
        flex: 1;
        display: flex;
        justify-content: flex-end;

        button {
          border-radius: 0;
          min-height: 70px;
          font-size: 20px;
          font-family: MediumLLWeb-Bold;

          &.save-button {
            min-width: 110px;
          }

          &.cancel-button {
            opacity: 0.7;
            width: 50px;

            svg {
              font-size: 30px;
            }
          }
        }
      }
    }

    .MuiInputBase-root {
      background-color: #fff !important;
    }
  }

  .space-list-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 24px;
    overflow: hidden;

    .space-list-header {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      gap: 8px;

      .space-list-header-title {
        font-family: MediumLLWeb-Bold;
        font-size: 24px;
      }

      .MuiGrid-container {
        justify-content: flex-end;
      }
    }

    // &.space-templates {
    //   .space-list-body {
    //     max-height: calc(100vh - 252px);
    //   }
    // }

    .space-closed {
      pointer-events: none;

      .MuiIconButton-sizeSmall {
        pointer-events: all;
      }
    }

    .space-list-body {
      flex: 1;
      padding: 0 24px 40px 24px;
      overflow-y: auto;

      // &.max-height-unset {
      //   max-height: calc(100vh - 252px);
      // }

      &.padding-0 {
        padding: 0;
        flex: unset;
      }

      .space-list-item {
        // min-width: 281px;

        .card-item {
          background-color: #ffffff !important;
          border: 1px solid #dbe1e3;
          box-shadow: none;

          &:hover {
            border-color: $primary-color;
          }
        }

        @media (min-width: 769px) and (max-width: 1023px) {
          max-width: 50%;
          flex-basis: 50%;
        }

        @media (min-width: 1024px) and (max-width: 1279px) {
          max-width: 25%;
          flex-basis: 25%;
        }

        @media (min-width: 1280px) and (max-width: 1679px) {
          max-width: 20%;
          flex-basis: 20%;
        }
      }
    }

    .loading-more {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 24px;
      text-align: center;
      color: rgba(0, 0, 0, 0.6);
      flex-basis: 100%;

      &>div {
        margin: 0 !important;
      }
    }

    .load-more {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px 0;

      button {
        text-transform: initial;
      }

      .Mui-disabled {
        color: $primary-color;
        opacity: 0.5;
      }
    }

    .no-data {
      background-color: #fff;
      height: 100%;
      justify-content: center;

      .icon-new-info {
        width: 24px;
        height: 24px;
        justify-content: center;
        display: flex;
        align-items: center;

        span {
          font-size: 20px;
        }

        .path2:before {
          color: #000;
        }
      }
    }
  }

  .message-empty {
    min-height: 300px;
    align-items: center;
    display: flex;
  }
}

.notification-popup {
  font-weight: 400;
  color: #fff;
}

.notification-space-creator {
  font-weight: 400;
  color: #fff;
  opacity: 0.6;
}

@include media(phone) {
  .space-list-container {
    section {
      .group-buttons {
        padding-left: 0;

        .btn {
          padding: 8px 16px;
        }
      }
    }

    .spce-portal-header .content {
      padding-left: 14px;
    }

    .navbar {
      padding: 0 !important;

      .regular-rounded-button {
        width: 100px;
        padding-left: 12px;
      }

      .search-icon-button {
        background-color: #f4f7f8;
        padding: 8px;

        .MuiSvgIcon-root {
          color: #000;
          font-size: 25px;
        }
      }
    }

    .sections-container {
      padding: 0;
      margin-bottom: 0;
    }

    .space-actions-wrapper {
      padding: 24px 16px;

      .space-actions {
        flex-wrap: wrap;
        gap: 16px;

        .space-list-header .space-list-header-title {
          font-size: 20px;
          line-height: 24px;
        }

        .bottom-sheet {
          .dropdown-button {
            width: 100%;
            padding: 8px 12px;
            gap: 20px;

            .name {
              margin-left: 0;
            }

            .icon {
              width: 24px;
            }
          }
        }

        .space-actions-right {
          .space-filter .btn {
            color: #000;
            background-color: transparent;
            padding: 3px;
          }
        }
      }
    }

    .search-bar-wrapper {
      border: 1px solid #000000;
      border-radius: 4px;
      width: 100%;
      margin-right: 14px;
    }

    .filter-box {
      min-width: 180px;
      flex: 1;

      input {
        color: $primary-color;
        font-family: MediumLLSub-RegularSubset;
      }

      .MuiInputAdornment-positionStart {
        svg {
          color: #000;
          font-size: 28px;
        }
      }

      .MuiInputAdornment-positionEnd {
        svg {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }

    .sections-container .filters {
      right: 15px;
    }

    .space-list-content {
      gap: 0;
      // &.space-templates .space-list-body {
      //   max-height: calc(100vh - 275px);
      // }

      // .space-list-body {
      //   // padding: 0 18px;
      //   // max-height: calc(100vh - 275px);

      //   // &.max-height-unset {
      //   //   max-height: calc(100vh - 275px);
      //   // }
      // }

      .space-list-body {
        padding: 0 16px 16px 16px;

        .space-list-item {
          // min-width: 150px;
          // width: 50%;

          &.MuiGrid-item {
            padding: 8px 5px;
          }

          .card-item {
            &.empty {
              .MuiSvgIcon-root {
                font-size: 24px;
              }
            }

            .meeting-date {
              font-size: 8.5px;
            }
          }
        }
      }
    }

    .user-container {
      margin: 0;
    }
  }
}