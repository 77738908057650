.select-role.confirm-dialog {
  .dialog-content {
    min-height: 400px;
  }

  .content {
    .message {
      margin: 24px 0;
    }

    span.integration-icon {
      .icon-new-info {
        color: #4783FF;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;

        span {
          font-size: 50px;
        }
      }
    }
  }

  .buttons {
    flex-direction: row-reverse;
    .button.confirm, .spce-btn.largePrimary {
      background-color: #f4f7f8;
      color: rgba(0, 0, 0, 0.6);

      .MuiButton-label {
        color: rgba(0, 0, 0, 0.6);
      }
    }
    .button.cancel, .spce-btn.largeSecondary {
      background-color: #000;
      color: #fff;
      .MuiButton-label  {
        color: #fff;
      }
    }
  }
}
.message-request-dialog {
  width: 100%;
}