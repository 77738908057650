.suspended-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: absolute;
  top: 50px;
  right: 0px;
  bottom: 50px;
  left: 0px;

  img {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  p {
    font-size: 21px;
    text-align: center;
    padding: 0;
    margin: 10px;
    font-weight: 500;
    max-width: 700px;
    line-height: 26px;
  }
}
