@import '../../responsiveUtils.scss';

.spaces-affected-dialog {
  .MuiDialog-container {
    .MuiDialog-paper {
      background-color: #fff;
      color: #000;

      .dialog-content {
        min-width: 652px;
        min-height: 284px;
        padding-left: 24px;
        padding-right: 24px;
        .close-icon {
          color: #000;
        }

        .dialog-header {
          display: flex;
          flex-direction: column;
          padding: 16px 0;
          .title {
            font-size: 15px;
            font-weight: 700;
            line-height: 24px;
          }
          .description {
            font-size: 13px;
            font-weight: 400;
            opacity: 0.6;
            line-height: 16px;
          }
        }

        .dialog-body {
          .table-section {
            .apply-changes-table {
              .table-container {
                .table {
                  .MuiTableRow-root.MuiTableRow-hover:hover {
                    background-color: #fff;
                  }
                }
              }
            }
          }
        }
      }

      .dialog-body {
        .apply-changes-table {
          .table-container {
            .table {
              .MuiTableRow-root.MuiTableRow-hover:hover {
                background-color: #fff;
              }
            }
          }
        }
      }
    }
  }
}

@include media(phone) {
  .apply-changes-dialog {
    .MuiDialog-container {
      .MuiDialog-paper {
        width: 60%;
        max-height: 80%;
      }
    }
  }
}
