.onedrive-resource-preview-pane {
  font-size: 12px;
  // min-width: 320px;
  overflow: hidden;

  .preview-pane-title {
    font-size: 15px;
  }

  .icon-folder {
    font-size: 80px;
    color: #76a2fe;
    margin: 46px 0;
  }

  .description {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .previewer {
    position: relative;
    height: 200px;
    margin: 20px 0;

    .image-viewer {
      position: static;
      height: 100%;
      width: 100%;
      object-fit: contain;
      background-color: #f5f7f8;
    }
    .download-preview {
      min-height: 154px;
      background-color: #f5f7f8;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
    }
    .pdf-previewer {
      overflow: hidden;
      height: 100%;
    }
    .audio-viewer {
      background-color: #dbe1e3;
      justify-content: center;

      .audio {
        margin-top: unset;

        audio {
          width: 80%;
        }
      }
    }
  }

  .divider-container {
    margin: 10px 0;
  }
}
