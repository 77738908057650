.copy-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-top: 4px;

  .generic-url-text {
    width: 415px;

    .MuiOutlinedInput-input {
      padding: 11px 14px;
      color: #000;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: #dbe1e3;
    }
  }

  .regular-rounded-button {
    border-radius: 100px;
    height: 40px;
    margin-left: 16px;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #f4f7f8;
    color: rgba(0,0,0,.6);
    &:hover {
      color: #000;
      background-color: #f4f7f8;
    }

    &.show-link-copied {
      background-color: #000;
      color: #ffffff;
      border-radius: 4px;
      text-transform: capitalize;
      font-family: 'MediumLLWeb-Regular';
    }
  }
  .btn-copy-link {
    margin-left: 16px;
    padding-left: 25px;
    padding-right: 25px;
  }
}