.veeva-player {
  text-align: center;
  &.preview {
    pointer-events: none;
  }

  iframe {
    max-width: 870px;
  }
}
