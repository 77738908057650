@import '../variables.scss';
@import '../responsiveUtils.scss';

.salesroom-container {
  .runtime-drawer {
    &.MuiDrawer-root .MuiDrawer-paper {
      left: 72px;
      bottom: 8px;
    }

    .runtime-drawer-widgets {
      .side-panel {
        padding: 0;
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;

        .MuiAlert-standardInfo {
          background-color: $primary-color-light;

          .MuiAlert-message {
            color: $secondary-color;
          }
        }

        .side-panel-header .right-icon {
          top: unset;
        }

        .side-panel-title {
          text-transform: none;
          margin: 0;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .cms-theme-preview-container {
    .cms-theme-preview-header {
      &.is-scroll .cms-salesroom-header {
        background-color: #fff;
      }
    }

    .cms-salesroom-header {
      position: relative;
      top: 0;
      background-color: #fff;
      transition: 0.3s ease-in-out background-color;
      border-bottom: 1px solid #eceeef;
    }

    .cms-theme-preview-body {
      .cms-theme-preview-row {
        position: relative;
        border: 0;
        margin: 0;

        &.selected {
          border: none;
        }

        &:hover {
          @extend .selected;
          cursor: default;
        }
      }
    }
  }
}

.space-content-dialog {
  position: absolute;
  z-index: 9;
  left: 0;
  // right: 0; // to be able to scroll the space content behind.
  top: 0;
  bottom: 0;
  display: flex;
  flex: 1;

  &::after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background-color: #000;
    // opacity: 0.85;
    content: '';
    z-index: 99;
  }

  >* {
    z-index: 100;
  }

  .close-button {
    top: 20px;
    right: 20px;
    padding: 8px;
    position: absolute;
    z-index: 999;
    color: #fff;
    cursor: pointer;

    svg {
      font-size: 25px;
    }
  }

  .salesroom-footer {
    position: fixed;
    bottom: 0;
  }

  .salesroom-container {
    .join-meeting-content {
      padding-top: 80px;
    }
  }
}

@include media(phone, landscape) {
  .app {

    .runtime-client-container .runtime-footer,
    .runtime-client-container .salesroom-footer {
      z-index: 200;
    }

    .runtime-footer,
    .salesroom-footer {
      flex-direction: column;
      z-index: 200;
      padding: 4px 10px;

      .runtime-footer {
        padding: 5px;
      }

      .square-button svg,
      .square-button i {
        font-size: 20px;
      }

      .runtime-footer-right {
        display: none;
      }

      &.runtime-gateway {
        display: none;
      }
    }

    .salesroom-container {
      .runtime-drawer {
        .MuiDrawer-paper {
          padding-top: 0;
        }
      }

      .cms-theme-preview-container {
        .cms-theme-preview-header {
          .cms-salesroom-header {
            padding: 12px 16px;
          }
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .salesroom-container {
    .cms-theme-preview-container {
      .cms-theme-preview-body {
        .cms-theme-preview-row {
          .widget-grid {
            max-width: 1296px;
            margin: 0 auto;

            &-1 {
              .cms-widget {
                max-width: 800px;

                .table-container.resources-table .table .MuiTableCell-root.MuiTableCell-head.resource-name {
                  width: 500px;
                }
              }
            }
          }
        }
      }
    }
  }
}