@import '../../variables.scss';
@import '../../responsiveUtils.scss';

.meetings-timeline {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 0;

  .timeline-date {
    padding: 12px 24px;
    font-family: $FMediumBold;

    &:first-child {
      margin-top: 0;
    }

    .day {
      margin-left: 10px;
      font-family: $FMediumRegular;
      color: rgba(0, 0, 0, 0.6);
    }

    .today {
      color: #1fda8b;
    }
  }

  .text-gray {
    color: rgba(0, 0, 0, 0.6);
  }

  .meeting-item {
    padding-bottom: 25px;

    &:last-child {
      padding-bottom: 0;
    }

    .to-do-item-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 24px;
    }

    .bottom {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-top: 4px;

      .tags {
        margin-bottom: 0;
      }
    }

    .top {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 10px;

      .tags {
        margin-bottom: 0;
      }
    }
  }

  .meeting-timeline-item {
    display: flex;
    border-radius: 6px;
    padding-bottom: 4px;
    margin-top: 4px;
    // height: 104px;
    max-height: 605px;
    gap: 24px;
    padding: 0 24px;

    &:last-child {
      .icon-vertical-block {
        img {
          max-width: 100%;
          width: 18px;
        }
      }
    }

    // toggle todos
    &.todo-show-item {
      display: flex;
      cursor: pointer;

      .text-red {
        color: #f44a4a;
        font-size: 13px;
        line-height: 16px;
      }

      .list-item-buttons {
        display: flex;
        flex-direction: column;
      }
    }

    &.todo-hide-item {
      display: none;
    }

    .icon-vertical-block {
      display: flex;
      flex-direction: column;
      flex: 0 0 40px;
      align-items: center;
      position: relative;

      &.active {
        .MuiIconButton-root {
          color: #1fda8b;
        }

        .vertical-line {
          border: 1px solid #1fda8b;
        }
      }

      &.past-meeting {
        .MuiIconButton-root {
          color: rgba(0, 0, 0, 0.3);
        }
      }

      .MuiIconButton-root {
        background-color: #f4f7f8;
        color: #b8f5db;
        width: 40px;
        height: 40px;
        padding: 8px;
      }

      .icon-calendar {
        font-size: 18px;
      }

      .icon-todolist {
        font-size: 12px;
        color: #76a2fe;
      }

      .vertical-line {
        width: 0;
        position: absolute;
        height: calc(100% - 42px);
        border: 1px solid #eceeef;
        top: 42px;
        left: 50%;
        transform: translateX(-50%);
      }

      &.todo-hide-icon {
        .MuiIconButton-root {
          display: none;
        }

        .vertical-line {
          height: 110px;
          top: 0px;
        }
      }
    }

    > .info {
      position: relative;
      background-color: #f4f7f8;
      border-radius: 4px;
      min-height: 0;
      padding: 8px 8px;
      overflow: hidden;

      .assigned-to {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.6);
      }

      .todo-categories {
        margin-top: 4px;
      }

      .list-item-buttons {
        position: absolute;
        right: 10px;
        top: 4px;
        font-size: 16px;
        display: flex;
        align-items: flex-end;
        gap: 8px;

        .icon-chart-with-bg {
          line-height: 16px;
          cursor: default;

          .path2:before {
            margin-left: -1.08em;
            color: #fff;
          }

          &.has-responses {
            cursor: pointer;

            .path2:before {
              color: #1fda8b;
            }
          }
        }

        .icon-urgent {
          font-size: 9.5px;
          background-color: #414141;
          color: #f44a4a;
          padding: 1px 6px;
          line-height: 14px;
          border-radius: 2px;
          text-align: center;
        }

        .icon-button {
          color: #000;
        }

        .action-menu-button {
          padding: 0;
          color: #000;

          span {
            .icon-button {
              &.MuiIconButton-root {
                color: #000;
              }
            }
          }
        }
      }

      &:hover {
        // background-color: $primary-color-variant;

        .list-item-buttons {
          > button {
            opacity: 1;
          }
        }
      }

      .active {
        background-color: cornflowerblue;
      }

      .item-content {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .left {
          width: 100%;
          min-width: 0; // a trick to ellipsis the meeting title

          .meeting-title {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 24px;
          }
        }

        .bottom {
          .description {
            font-size: 13px;
            max-width: none;
            line-height: 16px;
          }
        }
      }
    }

    &.last-item {
      flex: 1;
      margin-top: 0;
      max-height: 100%;

      &:hover {
        background-color: inherit;
      }
    }

    .last-item-vertical-line {
      width: 0px;
      border: 1px solid #eceeef;
      margin-left: 19px;
      // z-index: 999;
    }
  }

  .todo-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    margin-right: 18px;
    margin-top: 12px;

    .icon-chevron-up,
    .icon-chevron-down {
      margin-left: 8px;
      font-size: 9px;
    }

    &-vertical-block {
      flex: 0 0 88px;
      position: relative;
      height: 25px;
    }

    &-vertical-line {
      width: 0;
      position: absolute;
      height: 24px;
      left: 50%;
      transform: translateX(-50%);
    }

    .description {
      flex: 1;
      text-align: center;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.6);

      .icon-chevron-down {
        color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}

@include media(phone) {
  body {
    .meetings-timeline {
      .meetings-by-date {
        padding-left: 0;
      }

      .meeting-timeline-item .last-item-vertical-line {
        margin-left: 19px;
      }
    }
  }
}
