@import '../../variables.scss';

.auto-complete-users-input {
  .participants-select-control-input {
    .MuiOutlinedInput-root {
      &.MuiAutocomplete-inputRoot {
        padding: 6px 4px;
      }

      .MuiOutlinedInput-notchedOutline {
        border: none;
      }

      .MuiChip-root {
        border-radius: 100px;
        padding: 4px 9.4px 4px 12px;
        margin: 2px 0 2px 4px;
        background-color: #E5EEFF;
        color: #000;
        height: 24px;

        .MuiChip-label {
          font-size: 13px;
          line-height: 20px;
          margin: 0 7px 0 0;
          padding: 0;
        }

        .MuiChip-deleteIcon {
          width: 12px;
          height: 12px;
          margin: 0;
          color: $primary-color;
        }
      }

      span.MuiAutocomplete-tag {
        background-color: $primary-color;
        border-radius: 100px;
        padding: 4px 8px;
        margin: 4px;
        font-size: 15px;
        line-height: 20px;
      }
    }

    input {
      border-radius: 4px;
      font-size: 15px;
      line-height: 20px;
      font-family: 'MediumLLWeb-Regular';
      padding: 2px 0 2px 12px !important;
      height: 24px;
    }

    .MuiFormHelperText-root {
      color: #ff7f7f;
      font-size: 10px;
      line-height: 12px;
      margin: 4px 0 0 0;
    }
  }
}

.MuiAutocomplete-popper.user-select-autocomplete-popper {
  height: 0;
}

#user-select-autocomplete-popup {
  color: $primary-color;
  max-height: 56vh;

  &::-webkit-scrollbar {
    width: 4px;
    background-color: $secondary-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $secondary-color-variant;
    border-radius: 100px;
  }

  .MuiAutocomplete-option {
    padding: 0;

    // &[data-focus="true"] {
    //   background-color: #fff;
    // }

    &[aria-selected="true"] {
      background-color: #f4f7f8;
    }

    &.MuiAutocomplete-option[aria-disabled="true"] {
      opacity: 0.4;
      pointer-events: visible;
    }

    .participant-select-option {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 7px 4px 7px 16px;


      &:hover {
        background-color: #f4f7f8;
      }

      &.disabled {
        user-select: none;

        &:hover {
          background-color: #fff;
        }
      }

      &.selected-all {
        .participant-info {
          min-height: unset;
          margin-left: 0;
        }
      }

      .participant-info {
        flex: 1;
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .participant-fullName {
          font-size: 15px;
          line-height: 20px;
        }

        .participant-email {
          font-size: 12px;
          line-height: 16px;
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .icon-plus-solid {
        font-size: 12px;
        margin-right: 8px;
      }
    }
  }
}