@import '../../responsiveUtils.scss';
@import '../../variables.scss';

.notify-button {
  &.MuiIconButton-root {
    color: #000;
    padding: 11px;
    background-color: transparent;
    &:hover {
      color: #000;
      background-color: transparent;
    }
  }

  .icon-notify {
    font-size: 19px;
  }
  .MuiBadge-badge {
    top: -6px;
    right: -6px;
  }
}

.notify-popup {
  .MuiPopover-paper {
    min-width: 360px;
    min-height: 400px;
    max-height: 720px;
    .notify-popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 24px;
    }
    .tabs {
      display: flex;
      gap: 16px;
      cursor: pointer;
      align-items: flex-end;
      margin-left: 0;

      .active {
        font-family: 'MediumLLWeb-Bold';
      }

      .tab {
        gap: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    .button-setting,
    .button-close {
      font-size: 18px;
      padding: 5px;
    }
    .icon-close {
      font-size: 16px;
    }
  }
  .tab-content {
    max-width: 360px;
    .content {
      .content-top {
        padding: 0 24px 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .text-blue {
        color: #4783ff;
        cursor: pointer;
      }
    }
  }
}

@include media(phone) {
  .notify-popup {
    .MuiPopover-paper {
      min-width: unset;
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      top: 0 !important;
    }
    .tab-content {
      max-width: 100%;
    }
  }
}
