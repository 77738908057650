.accesslevel-select {
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 4px 2px;
    display: flex;
    align-items: center;

    .icon-folder {
      font-size: 18px;
      margin-right: 13px;
    }
  }

  .Mui-disabled .MuiSelect-outlined.MuiSelect-outlined {}

  .disabled-info {
    margin-top: 4px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 13px;
  }
}

.access-level-select-popover {
  .MuiListItemIcon-root {
    min-width: 20px;

    .icon-accesslevel {
      font-size: 18px;
      margin-right: 13px;
    }
  }
}

.access-level-item-wrapper {
  width: 100%;
  display: flex;
  padding: 6px 16px;

  .MuiListItemIcon-root {

    .icon-folder,
    .icon-accesslevel {
      &.grey {
        &::before {
          color: #DBE1E3;
        }
      }

      &.dark-grey {
        &::before {
          color: rgba(0, 0, 0, 0.6);
        }

        &::after {
          color: #fff;
        }
      }

      &::before {
        font-size: 18px;
        color: rgba(65, 65, 65, 0.4);
      }

      &::after {
        color: #000;
      }
    }
  }
}