.space-tags-wrap {
  display: flex;
  width: 100%;
  .show-more-button {
    background-color: #DBE1E3;
    cursor: pointer;
    margin-left: 4px;
    &:hover {
      background-color: #abb2b4;
    }
  }

}


.space-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  position: relative;
  &-nowrap {
    flex-wrap: nowrap;
  }
}

