.tiff-viewer {
  //   .container {
  //     width: 100%;
  //     height: 100%;
  //     position: relative;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;

  //     .viewer {
  //       display: flex;
  //       canvas {
  //         width: 100%;
  //       }
  //     }

  //     .buttons {
  //       position: absolute;
  //       bottom: 0;
  //     }

  //     .icon-spinner {
  //       color: #fff;
  //     }
  //   }

  .icon-spinner {
    color: #fff;
  }
}

.tiff-viewer-loading {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
}
